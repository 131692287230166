<template>
<div class="icon-card shadow-sm overflow-hidden">
  <div class="card-icon"><slot name="icon"></slot></div>
  <div class="card-body">
    <h5 class="card-title"><slot></slot></h5>
    <p class="card-subtitle"><slot name="subtitle"></slot></p>
  </div>
</div>
</template>

<script>

export default {
  props: {
    icon: String
  }

}
</script>

<style scoped lang="scss">
@import './../assets/colors.scss';

.icon-card {
  box-sizing: border-box;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: white;
  border: 1px solid lighten($secondary,25);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 120px;

  &:hover {
    border: 1px solid $primary;
    background: lighten($primary, 35);
  }

  .card-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex:1;
  }
  .card-body {
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-weight: bold;

    .card-subtitle {
      color: $secondary;
    }
  }
}

</style>
