<template>
  <div style='margin-top: 8px'>
  <b-spinner v-if="isLoading" variant="warning" small />
  <toggle-button v-else-if='!isLoading'  color='#38BA6D' :width=40 :height=20 v-model='status' @change='toggle'/>
  </div>
</template>

<script>

export default {
  name: 'SyncSwitch',
  props: {
    syncStatus: { type: Boolean },
    sm: { type: Boolean, default: false },
    onToggle: { type: Function }
  },
  created () {
    this.$data.status = this.$props.syncStatus
  },
  data () {
    return {
      status: true,
      isLoading: false
    }
  },
  methods: {
    async toggle (e) {
      this.isLoading = true
      try {
        if (typeof this.onToggle === 'function') {
          await this.onToggle(e, this.status)
          this.$data.status = this.status
        }
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
.sync-button {
  min-width: 5.8em;
  min-height: 28px;
  padding: 0 0.5em 0 0.5em;
  border-radius: 6px;
  color: white;
}
.sync-small {
  font-size: 11px;
  min-width: 55px;
  min-height: 25px;
}
</style>
