<template>
    <StepContainer>
        <StepForm>
          <template v-if="showManagerPackageSelector">
            <Label>Module Manager Package</Label>
            <ManagerPackageSelector/>
          </template>
          <template v-if="showTrainerPreparationSelector">
            <Label>Trainer Preparation Module Selector</Label>
            <TrainerPreparationSelector />
          </template>
          <p class="form-info" v-if="!$store.state.comm.data.customVariables.length">Currently there are no custom variables set for the communication template</p>
          <div class="variables-container">
                <VariableEntry
                        v-for="(variable, index) in customVariablesList"
                        v-if="!showManagerPackageSelector && !showTrainerPreparationSelector || ![MODULE_NAME, MANAGER_PACKAGE_LINK].includes(variable.path)"
                        :key="index"
                        :variable="variable"
                        :iKey="index"
                        @updateVariable="updateVariable"
                        @removeVariable="removeVariable"
                />
                <Button @click="$store.dispatch(CommActions.AddCustomVariable)">+ Add Custom Variable</Button>
            </div>
        </StepForm>
        <StepControls
                :nextAvailable="$store.getters['comm/lastAvailableStep'] > WizardSteps.CUSTOMIZATION"
                :isDisabled="isNextDisabled"
                @cancel="$store.dispatch(CommActions.Close)" @next="$store.dispatch(CommActions.Next)"
        />
    </StepContainer>
</template>

<script>
import Button from '@/components/Button.vue'
import {
  COMM_TYPE_MANAGER_PACKAGE,
  COMM_TYPE_TRAINER_PREPARATION
} from '@/views/communication/communicationTypeSelection/communicationConfig'
import ManagerPackageSelector from '@/views/communication/forms/ManagerPackageSelector.vue'
import TrainerPreparationSelector from '@/views/communication/forms/TrainerPreparationSelector.vue'
import VariableEntry from '@/views/communication/forms/VariableEntry.vue'
import {
  MANAGER_PACKAGE_LINK,
  MODULE_NAME
} from '@/views/communication/templateVariables'
import { WizardSteps } from '@/vuex/modules/comm/state'
import CommActions from '../../../vuex/modules/comm/actions'
import StepContainer from '../StepContainer'
import StepControls from '../StepControls'
import StepForm from '../StepForm'

export default {
  name: 'Customization',
  components: { ManagerPackageSelector, Button, StepContainer, StepControls, StepForm, VariableEntry, TrainerPreparationSelector },
  data: () => ({
    CommActions,
    WizardSteps,
    moduleSelected: null,
    MODULE_NAME,
    MANAGER_PACKAGE_LINK
  }),
  methods: {
    updateVariable ({ index, variable }) {
      this.$store.dispatch(CommActions.UpdateCustomVariable, { index, variable })
    },
    removeVariable ({ index }) {
      this.$store.dispatch(CommActions.RemoveCustomVariable, { index })
    }
  },
  computed: {
    customVariablesList () {
      return this.$store.state.comm.data.customVariables
    },
    showManagerPackageSelector () {
      return this.$store.state.comm.communicationType === COMM_TYPE_MANAGER_PACKAGE
    },
    showTrainerPreparationSelector () {
      return this.$store.state.comm.communicationType === COMM_TYPE_TRAINER_PREPARATION
    },
    isNextDisabled () {
      if (this.$store.state.comm.communicationType === COMM_TYPE_TRAINER_PREPARATION) {
        return this.$store.state.comm.selectedModuleForCustomization === null
      }
      if (this.$store.state.comm.communicationType === COMM_TYPE_MANAGER_PACKAGE) {
        return this.$store.state.comm.data.customVariables.reduce((acc, variable) => {
          if ((variable.path === MANAGER_PACKAGE_LINK || variable.path === MODULE_NAME) && variable.value === '') {
            acc = true
          }
          return acc
        }, false)
      }
      return false
    }
  }
}
</script>
