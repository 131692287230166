<template>
  <Modal
    :on-escape="closeModal"
    :on-close="closeModal">
    <template v-slot:header>
      <p class="p-3 header">Zoom Start Meeting URL</p>
    </template>

    <div class="m-5">
      <NotificationPanel
        v-if="zoomStartUrlError"
        :message="zoomStartUrlError"
        :classes="{ 'bg-danger': true }" icon="exclamation-circle"
      />
      <div class="row justify-content-center" v-if="loading">
        <b-spinner style="width: 4rem; height: 4rem;" variant="info"></b-spinner>
      </div>
      <div class="row" v-if="!loading">
        <div class="col-12">
          <Label class="required">Use this link to start the Zoom meeting session</Label>
          <Input
            :disabled = "true"
            :value="zoomStartUrl"
            class="form-control"
          />
        </div>
      </div>
    </div>

  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import NotificationPanel from '@/components/NotificationPanel.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ClassroomZoomStartUrlModal',
  components: {
    Modal,
    NotificationPanel
  },
  methods: {
    ...mapActions('classroom', {
      getZoomMeetingStartUrl: 'getZoomMeetingStartUrl'
    }),
    closeModal () {
      this.$store.commit('classroom/setShowZoomStartLinkModal', false)
    },
    getFreshZoomMeetingStartUrl () {
      const { id, companyCode } = this.$route.params
      this.getZoomMeetingStartUrl({ journeyId: id, companyCode })
    }
  },
  created () {
    this.getFreshZoomMeetingStartUrl()
  },
  computed: {
    ...mapGetters('classroom', {
      zoomStartUrl: 'zoomStartUrl',
      loading: 'loading',
      zoomStartUrlError: 'zoomStartUrlError'
    })
  }
}
</script>

<style scoped>
.header {
    align-self: center;
    font-size: 32px;
}

</style>
