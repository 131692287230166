import { mutations } from './mutations'
import { generateActions } from './actions'
import getCleanState, { ScheduleType, WizardSteps } from './state'
import CommunicationsApi from '@/vuex/modules/comm/api'
import lepaya from '@/http/lepaya'
import {
  COMM_TYPE_TRAINER_PREPARATION,
  COMM_TYPE_MANAGER_PACKAGE,
  communicationTypeMap
} from '@/views/communication/communicationTypeSelection/communicationConfig'
import { MANAGER_PACKAGE_LINK, MODULE_NAME } from '../../../views/communication/templateVariables'
const api = new CommunicationsApi(lepaya)

const communicationModule = {
  namespaced: true,
  state: getCleanState(),
  mutations,
  actions: generateActions(api),
  getters: {
    manPackVariablesSelected: (state) => {
      return state.data.customVariables.reduce((acc, variable) => {
        if ((variable.path === MANAGER_PACKAGE_LINK || variable.path === MODULE_NAME) && variable.value === '') {
          acc = false
        }
        return acc
      }, true)
    },
    nextStepDisabled: (state, getters) => {
      if (state.communicationType === COMM_TYPE_TRAINER_PREPARATION) {
        return state.selectedModuleForCustomization === null
      }
      if (state.communicationType === COMM_TYPE_MANAGER_PACKAGE) {
        return getters.manPackVariablesSelected
      }
      return false
    },
    lastAvailableStep: (state, getters) => {
      if (state.data.lastAccessedStep < getters.lastValidStep) {
        return state.data.lastAccessedStep + 1
      }

      return getters.lastValidStep
    },
    lastValidStep: (state, getters) => {
      let lastValidStep = WizardSteps.SETUP
      if (
        state.selectedTemplate &&
        state.data.templateSlug &&
        state.data.subjectLine &&
        state.data.senderEmail &&
        state.data.fromName
      ) {
        lastValidStep = WizardSteps.CUSTOMIZATION
      }
      if (
        state.selectedTemplate &&
        state.data.templateSlug &&
        state.data.subjectLine &&
        state.data.senderEmail &&
        state.data.fromName
      ) {
        if (state.communicationType === COMM_TYPE_TRAINER_PREPARATION) {
          if (state.selectedModuleForCustomization) {
            lastValidStep = WizardSteps.RECIPIENTS
          }
        } else if (state.communicationType === COMM_TYPE_MANAGER_PACKAGE) {
          if (getters.manPackVariablesSelected) {
            lastValidStep = WizardSteps.RECIPIENTS
          }
        } else {
          lastValidStep = WizardSteps.RECIPIENTS
        }
      }

      if (
        state.selectedTemplate &&
        state.data.templateSlug &&
        state.data.subjectLine &&
        state.data.senderEmail &&
        state.data.fromName
      ) {
        if (state.communicationType === COMM_TYPE_TRAINER_PREPARATION) {
          if (state.selectedModuleForCustomization) {
            lastValidStep = WizardSteps.SCHEDULING
          }
        } else if (state.communicationType === COMM_TYPE_MANAGER_PACKAGE) {
          if (getters.manPackVariablesSelected) {
            lastValidStep = WizardSteps.SCHEDULING
          }
        } else {
          lastValidStep = WizardSteps.SCHEDULING
        }
      }

      if (
        state.selectedTemplate &&
        state.data.templateSlug &&
        state.data.subjectLine &&
        state.data.senderEmail &&
        state.data.fromName &&
        state.data.scheduleType === ScheduleType.IMMEDIATE
      ) {
        if (state.communicationType === COMM_TYPE_TRAINER_PREPARATION) {
          if (state.selectedModuleForCustomization) {
            lastValidStep = WizardSteps.CONFIRMATION
          }
        } else if (state.communicationType === COMM_TYPE_MANAGER_PACKAGE) {
          if (getters.manPackVariablesSelected) {
            lastValidStep = WizardSteps.CONFIRMATION
          }
        } else {
          lastValidStep = WizardSteps.CONFIRMATION
        }
      }

      if (
        state.selectedTemplate &&
        state.data.templateSlug &&
        state.data.subjectLine &&
        state.data.senderEmail &&
        state.data.fromName &&
        state.data.scheduleType === ScheduleType.SCHEDULED &&
        state.data.sendDateTime
      ) {
        if (state.communicationType === COMM_TYPE_TRAINER_PREPARATION) {
          if (state.selectedModuleForCustomization) {
            lastValidStep = WizardSteps.CONFIRMATION
          }
        } else if (state.communicationType === COMM_TYPE_MANAGER_PACKAGE) {
          if (getters.manPackVariablesSelected) {
            lastValidStep = WizardSteps.CONFIRMATION
          }
        } else {
          lastValidStep = WizardSteps.CONFIRMATION
        }
      }

      return lastValidStep
    },
    getCommunicationPersistanceRecord: (state, getters) => {
      const data = state.data
      return {
        id: data.id,
        status: data.status,
        step: state.step,
        communicationDetails: {
          ...state.data,
          templateData: state.data.templateData,
          selectedTemplate: state.selectedTemplate,
          customVariables: data.customVariables,
          subjectLine: data.subjectLine,
          previewText: data.previewText,
          senderEmail: data.senderEmail,
          fromName: data.fromName,
          attachments: '', // data.attachments,
          recipients: data.recipients,
          scheduleType: data.scheduleType,
          sendDateTime: data.sendDateTime,
          sendDateTimezone: data.sendDateTimezone,
          lastStepCompleted: data.lastStepCompleted,
          selectedModule: state.selectedModuleForCustomization,
          communicationType: Object.keys(communicationTypeMap).find(key => communicationTypeMap[key] === state.communicationType)
        }
      }
    },
    participants: (state) => state.participants,
    defaultRecipientRoles: (state) => state.data.recipients.roles,
    currentRecipientsList: (state) => state.currentRecipientsList,
    currentStep: (state) => state.step,
    messageSentNotification: (state) => state.messageSentNotification,
    previewEmailData: (state) => state.previewEmailData.data,
    showPreviewEmailModal: (state) => state.showPreviewEmailModal,
    showCommunicationTypeModal: (state) => state.showCommunicationTypeModal,
    filteredTemplates: state => {
      return state.availableTemplates.filter(template => {
        return state.templateFilters.every(filter => template.labels.includes(filter))
      })
    },
    waveLanguage: (state) => state.data.waveLanguage,
    getParticipants: (state) => {
      if (state.communicationType === COMM_TYPE_TRAINER_PREPARATION) {
        return state.participants.filter(participant => [ 'admin' ].concat(state.data.recipients.roles).some((defaultRole) => participant.role === defaultRole))
      }
      return state.participants
    }
  }
}

export default communicationModule
