<template>
  <section class="text-center">
    <h1 class="p-5 font-size-xl">{{errorMessage}}</h1>
    <p class="font-size-lg">Please retry or contact support.</p>
    <Button type="link font-size-lg" v-on:click="retry" v-text="prev">retry</Button>
  </section>
</template>

<script>
import Button from './Button.vue'

export default {

  components: {
    Button
  },

  data: () => ({
    prev: '',
    errorMessage: ''
  }),

  methods: {
    retry () {
      this.$router.push({
        name: 'redirect'
      })
    }
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prev = from.path
      vm.errorMessage = vm.$route.params.errorMessage ? vm.$route.params.errorMessage : 'Error'
    })
  }
}
</script>
