import lepayaApi from './api.js'

const state = {
  modules: [],
  loading: false,
  currentModule: undefined,
  publishModulesResponse: [],
  moduleUpdateSuccessMessage: null,
  moduleUpdateErrorMessage: null,
  moduleSyncEvents: []
}

const getters = {
  loading: (state) => state.loading,
  modules: (state) => state.modules,
  disableImportButton: (state) => !state.currentModule,
  currentModule: (state) => state.currentModule,
  publishModulesResponse: (state) => {
    if (!state.publishModulesResponse.length) return { message: [], status: '' }

    const formattedResponse = []
    let responseStatus = null

    for (const response of state.publishModulesResponse) {
      formattedResponse.push(`Module id: ${response.id} - ${response.message}`)
    }

    if (state.publishModulesResponse.every(response => response.success === false)) responseStatus = 'fail'
    if (state.publishModulesResponse.every(response => response.success === true)) responseStatus = 'success'
    if (!responseStatus) responseStatus = 'partialSuccess'

    return {
      message: formattedResponse,
      status: responseStatus
    }
  },
  programJourneys: (state) => state.programJourneys,
  moduleUpdateSuccessMessage: (state) => state.moduleUpdateSuccessMessage,
  moduleUpdateErrorMessage: (state) => state.moduleUpdateErrorMessage,
  moduleSyncEvents: (state) => state.moduleSyncEvents
}

const actions = {
  setCurrentModule ({ commit }, data) {
    commit('setCurrentModule', data)
  },
  getModuleSyncEvents ({ commit }, id) {
    return lepayaApi.getModuleEvents(id)
      .then(response => {
        commit('setModuleSyncEvents', response.data)
      }).catch(error => {
      // eslint-disable-next-line no-console
        console.log('error', error)
      })
  },
  publishModules ({ commit }, data) {
    commit('setLoading', true)
    lepayaApi.publishModules(data)
      .then(response => {
        commit('setPublishModulesResponseMessage', response.data)
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('error', error)
      })
      .finally(() => {
        setTimeout(() => { commit('setPublishModulesResponseMessage', []) }, 3000)
        commit('setLoading', false)
      })
  },
  getProgramModules ({ commit }, data) {
    return new Promise((resolve) => {
      lepayaApi.getProgramModules(data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
        // eslint-disable-next-line no-console
          console.log('error', error)
        })
    })
  }
}

const mutations = {
  setModules: (state, data) => {
    state.modules = data.map(module => ({
      id: module.uid,
      catalogueCode: module.catalogueCode,
      title: `${module.title.trim()}${module.catalogueCode ? ': ' + module.catalogueCode : ''}`
    })).sort(function (a, b) {
      if (a.title.toLowerCase() < b.title.toLowerCase()) return -1
      else if (a.title.toLowerCase() > b.title.toLowerCase()) return 1
      return 0
    })
  },
  setLoading: (state, value) => { state.loading = value },
  setCurrentModule: (state, data) => { state.currentModule = data },
  setPublishModulesResponseMessage: (state, data) => { state.publishModulesResponse = data },
  setModuleUpdateSuccessMessage: (state, data) => { state.moduleUpdateSuccessMessage = data },
  setModuleUpdateErrorMessage: (state, data) => { state.moduleUpdateErrorMessage = data },
  setModuleSyncEvents: (state, data) => { state.moduleSyncEvents = data }
}

export default {
  state,
  getters,
  actions,
  mutations
}
