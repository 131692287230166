<template>
  <StepContainer>
    <StepForm :fullWidth='true'>
      <ConfirmationContainer/>
      <div v-if="$store.state.comm.messageSentNotification" class="notification">
        <h3>{{$store.state.comm.messageSentNotification}}</h3>
      </div>
    </StepForm>
    <StepControls :isDisabled="$store.state.comm.loading" @cancel="$store.dispatch(CommActions.Close)" @next="finishWizard" :nextLabel="confirmLabel" :next-available="!$store.state.comm.messageSentNotification"/>
  </StepContainer>
</template>

<script>
import StepContainer from '../StepContainer'
import StepControls from '../StepControls'
import StepForm from '../StepForm'
import CommActions from '../../../vuex/modules/comm/actions'
import { CommunicationStatus, ScheduleType, WizardSteps } from '@/vuex/modules/comm/state'
import ConfirmationContainer from '../ConfirmationContainer'
import { mapActions } from 'vuex'

export default {
  name: 'Confirmation',
  components: { StepContainer, StepControls, StepForm, ConfirmationContainer },
  data: () => ({ CommActions, WizardSteps }),
  computed: {
    confirmLabel () {
      if (this.$store.state.comm.data.scheduleType === 'IMMEDIATE') {
        return 'Send Now'
      }
      return 'Confirm'
    }
  },
  methods: {
    ...mapActions({
      GeneratePreviewEmailData: 'comm/GeneratePreviewEmailData'
    }),
    async finishWizard () {
      if (this.$store.state.comm.data.scheduleType === ScheduleType.IMMEDIATE) {
        await this.$store.dispatch(CommActions.SendImmediateEmail)
        await (new Promise((resolve) => setTimeout(() => resolve(), 1000)))
      }

      if (this.$store.state.comm.data.scheduleType === ScheduleType.SCHEDULED) {
        await this.$store.dispatch(CommActions.SetStatus, CommunicationStatus.SCHEDULED)
        await this.$store.dispatch(CommActions.Next)
      }
      await this.$store.dispatch(CommActions.Close)
    }
  },
  mounted () {
    this.GeneratePreviewEmailData()
  }
}
</script>

<style scoped>
  .notification {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2em;
  }
</style>
