<template>

  <div class="position-fixed m-5" style="right: 0px; bottom: 0px">
    <Anchor v-for="action in actions"
       v-bind:key="action.id"
      :href="action.href"
      :to="action.to"
      :class="action.buttonClass"
      class="p-4 bg-primary shadow-lg rounded-circle h2 text-center action-button">
      <Icon :icon="action.icon" class="text-light"></Icon>
    </Anchor>
  </div>

</template>

<script>
import Anchor from './Anchor.vue'
import Icon from './Icon.vue'

export default {
  props: {
    actions: Array
  },

  components: {
    Anchor,
    Icon
  }
}
</script>

<style scoped>
  .action-button {
    width: 5rem;
    height: 5rem;
    display: inline-block;
    line-height: 0rem;
  }
</style>
