import axios from 'axios'
import qs from 'qs'
import { identity } from 'ramda'
import auth0 from '../auth0.js'

const managementInstance = axios.create()
const managementV6Instance = axios.create()
const ownerInstance = axios.create()
const userInstance = axios.create()

const managementAPIBaseUrl = `${process.env.VUE_APP_API_BASE_URL}/mngmnt-api/v7`
const managementAPIBaseUrlV6 = `${process.env.VUE_APP_API_BASE_URL}/mngmnt-api/v7`
const ownerAPIBaseUrl = `${process.env.VUE_APP_API_BASE_URL}/owner/v7`
const userAPIBaseUrl = `${process.env.VUE_APP_API_BASE_URL}/user-api/v7`

managementInstance.interceptors.request.use(config => {
  config.baseURL = managementAPIBaseUrl

  if (config.qs) {
    config.url = `${config.url}?${qs.stringify(config.qs)}`
  }

  config.headers = {
    Authorization: `Bearer ${auth0.accessToken()}`
  }

  return config
})

managementV6Instance.interceptors.request.use(config => {
  config.baseURL = managementAPIBaseUrlV6

  if (config.qs) {
    config.url = `${config.url}?${qs.stringify(config.qs)}`
  }

  config.headers = {
    Authorization: `Bearer ${auth0.accessToken()}`
  }

  return config
})

ownerInstance.interceptors.request.use(config => {
  config.baseURL = ownerAPIBaseUrl

  if (config.qs) {
    config.url = `${config.url}?${qs.stringify(config.qs)}`
  }

  config.headers = {
    Authorization: `Bearer ${auth0.accessToken()}`
  }

  return config
})

userInstance.interceptors.request.use(config => {
  config.baseURL = userAPIBaseUrl

  if (config.qs) {
    config.url = `${config.url}?${qs.stringify(config.qs)}`
  }

  config.headers = {
    Authorization: `Bearer ${auth0.accessToken()}`
  }

  return config
})

managementInstance.owner = ownerInstance
managementInstance.user = userInstance
managementInstance.v6 = managementV6Instance

export default managementInstance

export function registerRouterInterceptor(instance, router) {
  instance.interceptors.response.use(identity, error => {
    const { response } = error
    if (!response) {
      return router.push({
        name: 'oops',
        params: { errorMessage: 'Cannot connect to the server' }
      })
    }

    if (response.status === 400) {
      return Promise.reject(error)
    }

    if (response.status === 403) {
      return router.push({ name: 'login' })
    }

    if (response.status === 401) {
      return router.push({ name: 'login' })
    }

    if (response.status > 400) {
      router.push({
        name: 'oops',
        params: { errorMessage: response.status }
      })
    }

    return Promise.reject(error)
  })
}

function isProductionEnvironment() {
  const apiBaseUrl = process.env.VUE_APP_API_BASE_URL
  return apiBaseUrl === 'https://api.lepaya.com'
}

export const isProdEnv = isProductionEnvironment()
