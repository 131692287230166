<template>
    <StepContainer>
        <StepForm :full-width="true">
            <Editor :content="this.$store.state.comm.data.templateData" @change="onEditorChange" @templateStatus="onTemplateStatusChange"/>
        </StepForm>
        <StepControls
                :nextAvailable="isTemplateValid && $store.getters['comm/lastAvailableStep'] > WizardSteps.EDITING"
                @cancel="$store.dispatch(CommActions.Close)" @next="$store.dispatch(CommActions.Next)"
        />
    </StepContainer>
</template>

<script>
import Editor from '@/views/communication/Editor.vue'
import { ScheduleType, WizardSteps } from '@/vuex/modules/comm/state'
import { validationMixin } from 'vuelidate'
import CommActions from '../../../vuex/modules/comm/actions'
import StepContainer from '../StepContainer'
import StepControls from '../StepControls'
import StepForm from '../StepForm'

export default {
  name: 'Editing',
  mixins: [ validationMixin ],
  components: { StepForm, StepControls, StepContainer, Editor },
  data: function () {
    return {
      CommActions,
      WizardSteps,
      ScheduleType,
      isTemplateValid: true
    }
  },
  methods: {
    onEditorChange (template) {
      this.$store.dispatch(CommActions.SetTemplateData, template)
    },
    onTemplateStatusChange (isValid) {
      this.$data.isTemplateValid = !!isValid
    },
    nextStep () {
      this.$store.dispatch(CommActions.Next)
    }
  }
}
</script>

<style lang="scss">

</style>
