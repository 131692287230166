<template>
    <b-form :class="[(fullWidth) ? 'step-form-full' : 'step-form']"><slot/></b-form>
</template>

<script>
export default {
  name: 'StepForm',
  props: {
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped>
.step-form {
  flex: 1;
  max-width: 1000px;
}
.step-form-full {
  flex: 1;
  max-width: 100%;
}
</style>
