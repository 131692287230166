<template>
    <StepForm :fullWidth='true'>
    <Loading v-if="!this.$store.state.comm.viewData" />
    <SentCommunicationOverview v-else />
    </StepForm>
</template>

<script>
import SentCommunicationOverview from '@/views/communication/SentCommunicationOverview.vue'
import StepForm from '@/views/communication/StepForm.vue'
import Loading from '@/views/communication/steps/Loading.vue'
import { WizardSteps } from '@/vuex/modules/comm/state'
import CommActions from '../../vuex/modules/comm/actions'

export default {
  name: 'SentView',
  components: { Loading, StepForm, SentCommunicationOverview },
  data: () => ({ CommActions, WizardSteps })
}
</script>

<style scoped>
</style>
