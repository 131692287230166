<template>
  <div class="content-wrapper">
    <span v-if="showLocationDataElement" v-html="locationData"/>
    <span :class="classroom.location.address ? 'address' : ''">
      <TableText
        :text="classroom.location.address || 'Address not set'"
        :color="textColorForAddress"
      />
    </span>
    <div v-if="classroom.invitesSent">
      <Badge class="invites">INVITES SENT</Badge>
    </div>
  </div>
</template>

<script>
import Badge from '@/components/Badge.vue'
import TableText from '@/components/common/TableText.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ClassroomLocation',
  components: {
    Badge,
    TableText
  },
  computed: {
    ...mapGetters({
      classroom: 'classroom/classroom'
    }),
    locationData () {
      const data = []
      if (this.classroom.location.name) data.push(this.classroom.location.name)
      if (this.classroom.location.room) data.push(this.classroom.location.room)

      return `${data.join(', ')} <br>`
    },
    showLocationDataElement () {
      return (this.classroom.location.name || this.classroom.location.room)
    },
    textColorForAddress () {
      if (this.classroom.location.address) return 'black'
      return 'gray'
    }
  }

}
</script>

<style scoped>
  .content-wrapper {
      max-width: 600px;
  }
  .address {
      font-size: 14px;
  }
</style>
