export const WizardSteps = Object.freeze({
  LOADING: 0,
  SETUP: 1,
  CUSTOMIZATION: 2,
  RECIPIENTS: 3,
  SCHEDULING: 4,
  EDITING: 5,
  CONFIRMATION: 6,
  LAST_STEP: 6
})

export const ScheduleType = Object.freeze({
  IMMEDIATE: 'IMMEDIATE',
  SCHEDULED: 'SCHEDULED'
})

export const CommunicationStatus = Object.freeze({
  DRAFT: 'DRAFT',
  SCHEDULED: 'SCHEDULED',
  EOP_SCHEDULED: 'EOP_SCHEDULED',
  SENT: 'SENT',
  FAILED: 'FAILED'
})

export const ViewMode = Object.freeze({
  EDIT: 'EDIT',
  VIEW: 'VIEW'
})

const getCleanState = () => ({
  communicationType: null,
  selectedModuleForCustomization: null,
  openModal: false,
  loading: true,
  availableTemplates: [],
  selectedTemplate: null,
  step: WizardSteps.SETUP,
  classroomStart: null,
  companyCode: null,
  programId: null,
  viewMode: ViewMode.EDIT,
  viewData: null,
  showSentEmailModal: false,
  sentEmailData: null,
  data: {
    id: null,
    status: CommunicationStatus.DRAFT,
    waveLanguage: { code: 'en', name: 'English' },
    templateSlug: '',
    templateData: '',
    templateImportTime: null,
    subjectLine: null,
    previewText: null,
    senderEmail: null,
    fromName: null,
    customVariables: [],
    attachments: null,
    recipients: {
      roles: [],
      excludedEmails: [],
      addedEmails: []
    },
    scheduleType: '',
    sendDateTime: null,
    sendDateTimezone: null,
    lastAccessedStep: WizardSteps.SETUP
  },
  removingId: null,
  removeLoading: false,
  removeError: null,
  removeMessage: null,
  communications: [],
  participants: [],
  currentRecipientsList: [],
  messageSentNotification: null,
  showPreviewEmailModal: false,
  previewEmailData: null,
  showCommunicationTypeModal: false,
  defaultEmailTemplate: null,
  templateFilters: [],
  waveModules: [],
  autoCommunications: []
})

export default getCleanState
