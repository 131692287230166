<template>
  <div class="loading-overlay" >
    <b-spinner class="loading-spinner" variant="primary"/>
  </div>
</template>

<script>
export default {
  name: 'OverlayingSpinner'
}
</script>

<style scoped>
.loading-overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #eee;
    z-index: 3
}
.loading-spinner {
    width: 150px;
    height: 150px;
    opacity:1
}
</style>
