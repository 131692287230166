export const BiteTypes = Object.freeze({
  EXTERNAL_DOC: 'externalDocument',
  SURVEY: 'survey',
  VIDEO: 'video',
  ARTICLE: 'article',
  TEXT: 'text',
  SINGLE_CHOICE: 'singleChoiceBite',
  VCOACH: 'vcoach',
  DATED_GOAL: 'dated_goal',
  GOAL: 'goal',
  ACTION_PLAN: 'action_plan',
  INTENTION_SETTING: 'intention_setting'
})

export const BiteTypeDefinitions = [
  {
    biteType: BiteTypes.EXTERNAL_DOC,
    label: 'External Document',
    formName: 'ExternalDocumentForm',
    description: 'A link to any publicly accessible content on the web',
    icon: 'star',
    contentType: 'existing',
    menuOrder: 3,
    creatable: true
  },
  {
    biteType: BiteTypes.SURVEY,
    label: 'Questionnaire',
    formName: 'SurveyForm',
    description: 'A link to a Typeform survey or questionnaire',
    icon: 'question-circle',
    contentType: 'app',
    menuOrder: 2,
    creatable: true
  },
  {
    biteType: BiteTypes.VIDEO,
    label: 'Video',
    formName: 'VideoForm',
    description: 'A Youtube video that has a publicly accessible link',
    icon: 'video',
    contentType: 'existing',
    menuOrder: 1,
    creatable: true
  },
  {
    biteType: BiteTypes.ARTICLE,
    label: 'Article',
    formName: 'ArticleForm',
    description: 'A link to a publicly available article',
    icon: 'star',
    contentType: 'existing',
    menuOrder: 2,
    creatable: true
  },
  {
    biteType: BiteTypes.TEXT,
    label: 'Text',
    formName: 'TextForm',
    description: 'A short text inside the app',
    icon: 'star',
    contentType: 'app',
    menuOrder: 1,
    creatable: true
  },
  {
    biteType: BiteTypes.SINGLE_CHOICE,
    label: 'Single Choice',
    formName: 'SingleChoiceForm',
    description: '',
    icon: 'star',
    creatable: false
  },
  {
    biteType: BiteTypes.VCOACH,
    label: 'vCoach',
    formName: 'VCoachForm',
    description: 'Configure a vCoach session',
    icon: 'question-circle',
    contentType: 'app',
    menuOrder: 3,
    creatable: true
  },
  {
    biteType: BiteTypes.DATED_GOAL,
    label: 'dated_goal',
    formName: 'datedGoalForm',
    description: 'Dated goal bite',
    icon: 'question-circle',
    contentType: 'app',
    menuOrder: 4,
    creatable: false
  },
  {
    biteType: BiteTypes.GOAL,
    label: 'goal',
    formName: 'datedGoalForm',
    description: 'Goal bite',
    icon: 'question-circle',
    contentType: 'app',
    menuOrder: 5,
    creatable: false
  },
  {
    biteType: BiteTypes.ACTION_PLAN,
    label: 'Action Plan',
    formName: 'datedGoalForm',
    description: 'Action Plan bite',
    icon: 'question-circle',
    contentType: 'app',
    menuOrder: 6,
    creatable: false
  },
  {
    biteType: BiteTypes.INTENTION_SETTING,
    label: 'Intention Setting',
    formName: 'datedGoalForm',
    description: 'Intention Setting Bite',
    icon: 'question-circle',
    contentType: 'app',
    menuOrder: 7,
    creatable: false
  }
]

export const getInAppCreatableBiteTypes = () => {
  return BiteTypeDefinitions
    .filter(t => t.creatable)
    .filter(t => t.contentType === 'app')
    .sort((a, b) => a.menuOrder > b.menuOrder ? 1 : -1)
}

export const getExternalCreatableBiteTypes = () => {
  return BiteTypeDefinitions
    .filter(t => t.creatable)
    .filter(t => t.contentType === 'existing')
    .sort((a, b) => a.menuOrder > b.menuOrder ? 1 : -1)
}

export const EmptyBite = {
  title: '',
  durationMins: null,
  isOptional: true,
  relativeDeadlineTime: null,
  deadlineTime: null,
  stage: null,
  orderNumber: null,
  details: {},
  purpose: null,
  type: null,
  subtype: null
}

const BiteDetails = {
  [BiteTypes.ARTICLE]: { articleUrl: '' },
  [BiteTypes.EXTERNAL_DOC]: { externalDocumentUrl: '' },
  [BiteTypes.SINGLE_CHOICE]: {},
  [BiteTypes.SURVEY]: { typeFormUrl: '' },
  [BiteTypes.VIDEO]: { youtubeUrl: '' },
  [BiteTypes.TEXT]: { text: '' },
  [BiteTypes.VCOACH]: {
    config: {
      speed: false,
      pauses: false,
      filler_words: false,
      gestures: false,
      stand_posture: false,
      movement: false,
      facial_expression: false,
      eye_contact: false
    }
  }
}

export const createEmptyBite = (type) => ({
  ...EmptyBite,
  type,
  details: BiteDetails[type] ?? {}
})
