<template src="./NewCompanyProgramForm.html"></template>

<script>
import CompanyProgramForm from './CompanyProgramForm.js'
import lepaya from '../http/lepaya.js'
import { queryResults } from './helpers.js'
import { equals } from 'ramda'

const query = queryResults({ searchable: [ 'firstName', 'lastName', 'email', 'fullName' ] })

export default CompanyProgramForm({
  data () {
    return {
      create: false,
      binding: {
        persist: 'Update Wave'
      }
    }
  },

  methods: {
    getData ({ params: { id, companyCode, programCatalogueItemId } }) {
      return Promise.all([
        lepaya.get(`/companies/${companyCode}/programs-catalogue/${programCatalogueItemId}`),
        lepaya.get(`/companies/${companyCode}/programs/${id}`),
        lepaya.get('/languages'),
        lepaya.get(`/companies/${companyCode}/programs/${id}/participants`),
        lepaya.get(`/companies/${companyCode}/employees`),
        lepaya.get(`/companies/${companyCode}/certificates/${id}`)
      ])
    },
    async persist ({ companyCode, id }, { certificate, ...program }) {
      const programData = await lepaya.put(`/companies/${companyCode}/programs/${id}`, program)
      await lepaya.put(`/companies/${companyCode}/certificates/${id}`, certificate)
      if (programData.data.moduleNotAvailable && programData.data.moduleNotAvailable.length > 0) {
        const h = this.$createElement
        this.setToast(h('p', [ programData.data.moduleNotAvailable.map(m => h('p', m)) ]),
          'Some modules are not changed because they are not available with selected language',
          'warning'
        )
      }
      return programData
    },
    setToast (txt, title, variant) {
      this.$bvToast.toast(txt, {
        title,
        autoHideDelay: 5000,
        appendToast: true,
        variant
      })
    },
    onSearchEmployee (input) {
      query(lepaya.get(`companies/${this.$route.params.companyCode}/employees`), { query: input || undefined })
        .then(employees => {
          this.employees = employees
          return employees
        })
    },
    onCloseChangingWavelanguage () {
      this.$refs['change-wave-language-confirmation'].hide()
    },
    onChangingWaveLanguage () {
      this.$refs['change-wave-language-confirmation'].hide()
      this.onProgramPersist()
    },
    onUpdateWave () {
      const isLanguageChanged = !equals(this.program.languageCode, this.originalProgram.languageCode)
      if (isLanguageChanged) {
        this.$refs['change-wave-language-confirmation'].show()
      } else {
        this.onProgramPersist()
      }
    }
  }

})
</script>

<style>
.breadcrumb-link {
  cursor: pointer;
}

.left-margin {
  margin-left: 3rem;
  margin-top: 1rem;
}
.breadcrumbs {
  font-size: 1.2rem;
}
</style>
