<template>
  <div class="step-controls-container">
    <RecipientsCounter v-if="$store.getters['comm/currentStep'] === WizardSteps.RECIPIENTS"/>
    <Button
      v-if="$store.getters['comm/currentStep'] === WizardSteps.CONFIRMATION"
      @click="$store.commit('comm/setShowPreviewEmailModal', true)"
      type="outline-secondary"
      size="lg"
      class="font-weight-bold p-4 rounded mr-auto"
      >
      <Icon class="mr-2" icon="location-arrow"/> Preview email
    </Button>
    <Button @click="$emit('cancel')" type="secondary" size="lg" class="font-weight-bold p-4 ml-2">Cancel</Button>

    <Button @click="$emit('next')" size="lg" type="primary" class="font-weight-bold p-4 ml-2" :disabled="!$props.nextAvailable || $props.isDisabled"
      >{{nextLabel}}<Icon class="ml-4" icon="arrow-right"
    /></Button>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import { WizardSteps } from '@/vuex/modules/comm/state'
import RecipientsCounter from './RecipientsCounter'

export default {
  name: 'StepControls',
  components: { Button, Icon, RecipientsCounter },
  data: () => ({ WizardSteps }),
  props: {
    nextAvailable: { type: Boolean, default: true },
    nextLabel: { type: String, default: 'Next' },
    isDisabled: { type: Boolean, default: false }
  }
}
</script>

<style scoped>
.step-controls-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 90px;
}
</style>
