import { mutations } from '@/vuex/modules/programs/mutations'
import { generateActions } from '@/vuex/modules/programs/actions'
import getters from '@/vuex/modules/programs/getters'

import lepaya from '@/http/lepaya'

export const PROGRAMS_NAMESPACE = 'programs'

const programsModule = {
  namespaced: true,
  state: {
    error: null,
    companyId: null,
    loading: false,
    programsList: [],
    programTypes: [],
    languages: [ { code: 'en', name: 'English' } ],
    programModules: {},
    programParticipants: {},
    msTeamsSyncStatuses: [],
    expandedProgramIds: [],
    programsScrollTop: 0,
    page: 1,
    perPage: 10
  },
  mutations,
  actions: generateActions(lepaya),
  getters
}

export default programsModule
