<template>
  <div>
    <NotificationPanel v-if="biteNoLongerSupportedMessage" :message=biteNoLongerSupportedMessage></NotificationPanel>
    <Table id="biteListId" :columns="columns" :records="bites" :value="selectedBites" v-model="selectedBites" @input="setSelectedBites"/>
    <BiteEditModal v-if="showEditBiteModal" @biteEdited="selectBite"/>
    <CreateBiteModal v-if="showCreateBiteModal"/>
  </div>
</template>

<script>
import NotificationPanel from '@/components/NotificationPanel.vue'
import Table from '@/components/Table.vue'
import BiteEditModal from '@/components/bites/BiteEditModal.vue'
import CreateBiteModal from '@/components/bites/CreateBiteModal.vue'
import bitesListColumns from '@/components/bites/bitesListColumns'
import { values } from 'ramda'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BitesList',
  props: {
    classroomScheduled: {
      type: Boolean
    }
  },
  components: { Table, BiteEditModal, CreateBiteModal, NotificationPanel },
  methods: {
    ...mapActions('bites', {
      getBites: 'getBites'
    }),
    selectBite (editedBite) {
      const biteFromList = this.bites.find(bite => bite.id === editedBite.id)
      this.$store.commit('bites/setSelectedBites', [ biteFromList ])
      this.setSelectedBites()
    },
    setSelectedBites () {
      this.$store.commit('bites/setSelectedBites', this.selectedBites)
    },
    unselectAll () {
      this.selectedBites = []
      this.$store.commit('bites/setSelectedBites', [])
    }
  },
  computed: {
    ...mapGetters('bites', {
      bites: 'bites',
      showEditBiteModal: 'showEditBiteModal',
      showCreateBiteModal: 'showCreateBiteModal',
      selectedBitesGetter: 'selectedBites',
      biteNoLongerSupportedMessage: 'biteNoLongerSupportedMessage'
    }),
    columns () {
      const { id } = this.$route.params
      const columns = { ...bitesListColumns(id) }
      return values(columns)
    },
    selectedBites: {
      get: function () {
        return this.selectedBitesGetter
      },
      set: function (newValue) {
        this.$store.commit('bites/setSelectedBites', newValue)
      }
    }
  },
  created () {
    const { id, companyCode } = this.$route.params
    if (id && companyCode) {
      this.getBites({ id, companyCode })
    }
  },
  destroyed () {
    this.$store.commit('bites/setBites', [])
  }
}
</script>

<style scoped>

</style>
