<template>
  <div class="wave-id-cell" >
    <router-link v-b-tooltip.hover title="Edit wave" class="edit-link" :to="link">{{title}}</router-link>
    <router-link class="edit-link" :to="link" target="_blank">
      <a v-b-tooltip.hover title="Edit wave in new tab">
      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M4.562 16.938q-.624 0-1.062-.438-.438-.438-.438-1.062V4.562q0-.624.438-1.062.438-.438 1.062-.438h4.521q.25 0 .438.198.187.198.187.428 0 .25-.187.437-.188.187-.438.187h-4.5q-.104 0-.187.084-.084.083-.084.187v10.834q0 .104.084.187.083.084.187.084h10.834q.104 0 .187-.084.084-.083.084-.187v-4.5q0-.25.187-.438.187-.187.437-.187.23 0 .428.187.198.188.198.438v4.521q0 .624-.438 1.062-.438.438-1.062.438Zm3.167-4.667q-.208-.188-.208-.427 0-.24.187-.448l7.104-7.084h-2.479q-.25 0-.437-.187-.188-.187-.188-.437 0-.23.188-.428.187-.198.437-.198h3.855q.312 0 .531.219.219.219.219.531v3.855q0 .25-.198.437-.198.188-.428.188-.25 0-.437-.188-.187-.187-.187-.437V5.188l-7.105 7.104q-.166.187-.416.187-.25 0-.438-.208Z"/></svg>
      </a>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'WaveIdCell',
  props: {
    wave: {
      type: Object,
      required: true
    }
  },
  computed: {
    title () {
      return this.wave.waveName
    },
    link () {
      return `programs/program-catalogue/${this.wave.programId}/programs/${this.wave.id}`
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/colors";

.wave-id-cell {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  border-right: 1px solid $lightGrayBorder;
}

.edit-link {
  color: $actionBlue;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  margin-right: 14px;
}

</style>
