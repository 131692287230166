<template>
  <StepContainer>
    <StepForm :full-width=true>
      <Table :columns="columns" :records="participants" v-model="recipients" @input="handleChange"/>
    </StepForm>
    <StepControls
      :nextAvailable="$store.getters['comm/lastAvailableStep'] > WizardSteps.RECIPIENTS"
      @cancel="$store.dispatch(CommActions.Close)"
      @next="$store.dispatch(CommActions.Next)"
    />
  </StepContainer>
</template>

<script>
import StepContainer from '../StepContainer'
import StepControls from '../StepControls'
import StepForm from '../StepForm'
import { WizardSteps } from '@/vuex/modules/comm/state'
import Table from '@/components/Table.vue'
import { values } from 'ramda'
import { mapGetters } from 'vuex'
import CommActions from '@/vuex/modules/comm/actions'

const columns = {
  firstName: {
    name: 'First Name',
    value: ({ firstName }) => firstName
  },
  lastName: {
    name: 'Last Name',
    value: ({ lastName }) => lastName
  },
  email: {
    name: 'Email',
    value: ({ email }) => email
  },
  id: {
    name: 'Employee Id',
    value: ({ id }) => id
  },
  role: {
    name: 'Role',
    value: ({ role }) => role
  }
}

export default {
  name: 'Recipients',
  data: () => ({
    columns: values(columns),
    recipients: [],
    WizardSteps,
    CommActions
  }),
  components: { StepContainer, StepControls, StepForm, Table },
  async created () {
    await this.$store.dispatch(CommActions.GetModuleParticipants, { companyCode: this.$store.state.comm.companyCode, moduleId: this.$store.state.comm.selectedModuleForCustomization, communicationType: this.$store.state.comm.communicationType })
    this.recipients = this.currentRecipientsList
  },
  computed: {
    ...mapGetters({
      participants: 'comm/getParticipants',
      currentRecipientsList: 'comm/currentRecipientsList',
      defaultRecipientRoles: 'comm/defaultRecipientRoles'
    })
  },
  methods: {
    handleChange (selectedRecipients) {
      const excludedEmails = this.participants.filter(participant => this.defaultRecipientRoles
        .some((defaultRole) => participant.role === defaultRole) && !selectedRecipients.includes(participant))
        .map((recipient) => recipient.email)
      const addedEmails = selectedRecipients.filter(selectedRecipient => !this.defaultRecipientRoles
        .some((defaultRole) => selectedRecipient.role === defaultRole))
        .map((recipient) => recipient.email)
      this.$store.commit('comm/setRecipients', { excludedEmails, addedEmails })
      this.$store.commit('comm/setCurrentRecipientsList')
    }
  }
}
</script>

<style scoped>
</style>
