import lepaya from '@/http/lepaya'

export default {
  publishModules (data) {
    const { moduleIds, companyCode } = data
    return lepaya.post(`/companies/${companyCode}/journeys/publish`, { journeys: moduleIds })
  },
  getProgramModules (data) {
    const { id, companyCode } = data
    return lepaya.get(`/companies/${companyCode}/programs/${id}/journeys`)
  },
  getModuleEvents (moduleId) {
    return lepaya.get(`/domain-events/module/${moduleId}`)
  }
}
