export const ProgramsGetters = Object.freeze({
  isLoading: 'isLoading',
  programsList: 'programsList',
  programTypes: 'programTypes',
  error: 'error',
  languages: 'languages',
  getProgramModules: 'getProgramModules',
  getProgramParticipants: 'getProgramParticipants',
  getProgramById: 'getProgramById',
  expandedProgramIds: 'expandedProgramIds',
  programsScrollTop: 'programsScrollTop',
  getWaveMsTeamsSyncStatus: 'getWaveMsTeamsSyncStatus',
  page: 'page',
  perPage: 'perPage'
})
export default {
  [ProgramsGetters.isLoading]: (state, getters) => state.loading,
  [ProgramsGetters.programsList]: (state, getters) => [ ...state.programsList ].sort((p1, p2) => p2.id - p1.id),
  [ProgramsGetters.programTypes]: (state, getters) => state.programTypes,
  [ProgramsGetters.error]: (state, getters) => state.error,
  [ProgramsGetters.msTeamsSyncStatuses]: (state, getters) => state.msTeamsSyncStatuses,
  [ProgramsGetters.getWaveMsTeamsSyncStatus]: (state, getters) => (waveId) => {
    return state.msTeamsSyncStatuses.find(item => item.ongoing_program_id === waveId)?.sync_status || 'N/A'
  },
  [ProgramsGetters.languages]: (state, getters) => state.languages,
  [ProgramsGetters.getProgramModules]: (state, getters) => (programId) => {
    return state.programModules[programId] ?? []
  },
  [ProgramsGetters.getProgramParticipants]: (state, getters) => (programId) => {
    return state.programParticipants[programId] ?? []
  },
  [ProgramsGetters.getProgramEvents]: (state, getters) => (programId) => {
    return state.programEvents[programId] ?? []
  },
  [ProgramsGetters.getProgramById]: (state, getters) => (id) => state.programsList.find(p => p.id === id),
  [ProgramsGetters.expandedProgramIds]: (state, getters) => state.expandedProgramIds,
  [ProgramsGetters.programsScrollTop]: (state, getters) => state.programsScrollTop,
  [ProgramsGetters.page]: (state, getters) => state.page,
  [ProgramsGetters.perPage]: (state, getters) => state.perPage
}
