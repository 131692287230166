<template>
  <Modal
  v-if="showPreviewEmailModal"
  :on-escape="onCloseEmailPreview"
  :on-close="onCloseEmailPreview"
  >
    <template v-slot:header>
      <p class="p-3 communication-modal-header">Preview</p>
    </template>
    <div class="m-4 border">
      <SubjectAndFromSection />
      <div class="iframe-wrapper">
        <iframe :srcdoc="previewEmailData.htmlBody"></iframe>
      </div>
      <b-alert
        :show="dismissCountDown"
        variant="success"
        class="text-center"
        :fade=true
        @dismissed="dismissCountDown=0"
      >
        <span style="message-sent-alert">{{ $store.state.comm.messageSentNotification }}</span>
      </b-alert>
    </div>
    <ButtonsBar class="w-content m-4">
      <template slot="right">
        <SendNowButton />
        <Button  @click="onCloseEmailPreview" class="mb-4 mt-4 ml-3 p-4 font-weight-bold font-size-lg">Close</Button>
      </template>
    </ButtonsBar>

  </Modal>
</template>

<script>
import Button from '@/components/Button.vue'
import ButtonsBar from '@/components/ButtonsBar.vue'
import Modal from '@/components/Modal.vue'
import SendNowButton from '@/views/communication/SendNowButton.vue'
import SubjectAndFromSection from '@/views/communication/previewEmail/SubjectAndFromSection.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PreviewEmailModal',
  data () {
    return {
      dismissSecs: 3,
      dismissCountDown: 0
    }
  },
  components: {
    Modal,
    ButtonsBar,
    Button,
    SubjectAndFromSection,
    SendNowButton
  },

  computed: {
    ...mapGetters({
      messageSentNotification: 'comm/messageSentNotification',
      previewEmailData: 'comm/previewEmailData',
      showPreviewEmailModal: 'comm/showPreviewEmailModal'
    })
  },
  methods: {
    onCloseEmailPreview () {
      this.$store.commit('comm/setShowPreviewEmailModal', false)
    },
    showAlert () {
      this.dismissCountDown = this.dismissSecs
    }
  },
  watch: {
    messageSentNotification: function () {
      this.showAlert()
    }
  }
}
</script>

<style scoped>
  .message-sent-alert {
    max-width: 150px;
    font-size: 22px;
  }
  .iframe-wrapper {
    margin: 4px;
    position: relative;
    padding-bottom: 50vh;
    height: 0;
  }
  .iframe-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
