<template>
  <span :class="classes">{{text}}<slot></slot></span>
</template>

<script>
export default {
  props: {
    type: {
      type: String
    },
    text: String
  },

  computed: {
    classes () {
      return {
        [`text-${this.type}`]: Boolean(this.type)
      }
    }
  }
}
</script>
