<template>
  <div class="d-inline-block font-size-sm position-relative">
    <span class="text-right d-inline-block">
      <strong>{{user.firstName}} {{user.lastName}}</strong><br>
      {{user.email}}
    </span>
      <AvatarImage
        v-if="user.avatarUrl"
        :title="user.firstName"
        :src="user.avatarUrl"
        class="mx-2"
        style="height: 2.5rem; vertical-align: top;"/>
    <a
      style="vertical-align: top;"
      class="text-secondary cursor-pointer"
      v-on:click="handleLogout"
    >Sign Out</a>
  </div>
</template>

<script>
import AvatarImage from './AvatarImage.vue'
import oauth from '../auth0'

export default {
  components: {
    AvatarImage
  },
  props: {
    user: Object
  },
  methods: {
    handleLogout (event) {
      event.preventDefault()
      oauth.logout({
        returnTo: process.env.VUE_APP_AUTH0_REDIRECT_URI
      })
    }
  }
}
</script>
