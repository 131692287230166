<template>
    <StepContainer>
        <StepForm>
            <p class="form-info">Send {{ isTrainerPreparationSelector ? 'Trainer Preparation' : 'Program Announcement' }} now or schedule it in advance.</p>
          <Alert
                    :show="$store.getters['comm/lastAvailableStep'] <= WizardSteps.SCHEDULING"
                    message='Please select a sending time'
            />
            <br/>
            <b-form-group>
                <b-form-radio-group
                        class="radio-group"
                        v-model="scheduleType"
                        :options="scheduleTypeOptions"
                        stacked
                />
            </b-form-group>
            <div class="scheduling" v-show="scheduleType === ScheduleType.SCHEDULED">
                <div class="scheduling-classroom-start" v-show="$store.state.comm.classroomStart">
                  <FormGroup>
                    <Label class="label">First Classroom Session Date</Label>
                    <Datepicker
                            class="classroom-date"
                            :value="$store.state.comm.classroomStart"
                            :disabled="true"
                    >
                    </Datepicker>
                  </FormGroup>
                  <Pill class="pill-notification-blue" style="color: #1A6BC6;">
                    <Icon style="color: #1A6BC6;" icon="info-circle" />
                    We recommend to schedule a Program Announcement Email 2 weeks before the first Classroom Session Date
                  </Pill>
                </div>
                <div class="scheduling-section">
                    <div class="tz-selector">
                        <FormGroup><Label class="label">Date & time</Label></FormGroup>
                        <vc-date-picker
                                v-model="datetime"
                                mode="datetime"
                                :timezone="pickerTimezone"
                                :min-date="new Date()"
                                color="blue"
                                is24hr
                                :minute-increment="60"
                        />
                    </div>
                    <div class="tz-selector">
                        <FormGroup><Label class="label">Timezone</Label></FormGroup>
                        <Select
                             :options="timezoneOptions"
                             :value="sendDateTimezone"
                             @input="setTimezoneSelection"
                        />
                    </div>
                </div>
            </div>
        </StepForm>
        <StepControls
                :nextAvailable="$store.getters['comm/lastAvailableStep'] > WizardSteps.SCHEDULING"
                @cancel="$store.dispatch(CommActions.Close)" @next="nextStep"
        />
    </StepContainer>
</template>

<script>
import Datepicker from '@/components/Datepicker.vue'
import FormGroup from '@/components/FormGroup.vue'
import Icon from '@/components/Icon.vue'
import Pill from '@/components/Pill.vue'
import Select from '@/components/Select.vue'
import { COMM_TYPE_TRAINER_PREPARATION } from '@/views/communication/communicationTypeSelection/communicationConfig'
import Alert from '@/views/communication/forms/Alert.vue'
import { ScheduleType, WizardSteps } from '@/vuex/modules/comm/state'
import moment from 'moment-timezone'
import { validationMixin } from 'vuelidate'
import CommActions from '../../../vuex/modules/comm/actions'
import StepContainer from '../StepContainer'
import StepControls from '../StepControls'
import StepForm from '../StepForm'
import Label from '../forms/Label'

export default {
  name: 'Scheduling',
  mixins: [ validationMixin ],
  components: { StepForm, StepControls, StepContainer, Select, Label, Pill, FormGroup, Datepicker, Icon, Alert },
  data: () => ({
    moment,
    CommActions,
    WizardSteps,
    ScheduleType,
    scheduleTypeOptions: [
      { text: 'Send it now', value: ScheduleType.IMMEDIATE },
      { text: 'Schedule for a specific time', value: ScheduleType.SCHEDULED }
    ],
    pickerTimezone: moment.tz.guess(),
    selectedTimezone: { code: moment.tz.guess(), name: moment.tz.guess() },
    availableTimezones: moment.tz.names().map(t => ({ code: t, name: t })),
    nothingSelectedError: false,
    dateTimeNotSelectedError: false
  }),
  computed: {
    datetime: {
      get () {
        return this.$store.state.comm.data.sendDateTime
      },
      set (datetime) {
        datetime.setMinutes(0, 0, 0)
        this.$store.dispatch(CommActions.SetSendDateTime, datetime)
      }
    },
    scheduleType: {
      get () {
        return this.$store.state.comm.data.scheduleType
      },
      set (type) {
        this.$store.dispatch(CommActions.SetScheduleType, type)
      }
    },
    timezoneOptions () {
      return this.$data.availableTimezones.map(tz => ({ code: tz.code, name: tz.name }))
    },
    sendDateTimezone () {
      if (this.$store.state.comm.data.sendDateTimezone) {
        return {
          code: this.$store.state.comm.data.sendDateTimezone.code,
          name: this.$store.state.comm.data.sendDateTimezone.name
        }
      }

      return this.$data.selectedTimezone
    },
    showNothingSelectedAlert () {
      return (this.nothingSelectedError && !this.scheduleType)
    },
    showDateTimeNotSelectedAlert () {
      return (this.dateTimeNotSelectedError && !this.datetime)
    },
    isTrainerPreparationSelector () {
      return this.$store.state.comm.communicationType === COMM_TYPE_TRAINER_PREPARATION
    }
  },
  methods: {
    setTimezoneSelection (tz) {
      this.$store.dispatch(CommActions.SetSendDateTimezone, { code: tz.code, name: tz.name })
      this.$data.selectedTimezone = tz
      this.$data.pickerTimezone = tz.code
    },
    nextStep () {
      if (!this.scheduleType) {
        this.nothingSelectedError = true
        return
      }
      if (this.scheduleType === ScheduleType.SCHEDULED && !this.datetime) {
        this.dateTimeNotSelectedError = true
        return
      }
      this.$store.dispatch(CommActions.Next)
    }
  }
}
</script>

<style lang="scss" scoped>
    .radio-group {
        font-size: 16px;
    }

    .scheduling-classroom-start {
        margin-left: 1em;
        padding-bottom: 8px;
        margin-bottom: 8px;
        border-bottom: 1px solid #eee;
    }

    .tz-selector {
        margin-right: 1em;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .scheduling-section {
        display: flex;
        flex-direction: row;
        padding-left: 1em;

        .divider {
            width: 1em;
        }

        .vc-container {
            --blue-100: #56d5ec;
            --blue-200: #56d5ec;
            --blue-300: #56d5ec;
            --blue-400: #56d5ec;
            --blue-500: #56d5ec;
            --blue-600: #56d5ec;
            --blue-700: #56d5ec;
            --blue-800: #56d5ec;
            --blue-900: #56d5ec;
        }

        .vc-container.vc-blue {
            --accent-100: #56d5ec;
            --accent-200: #56d5ec;
            --accent-300: #56d5ec;
            --accent-400: #56d5ec;
            --accent-500: #56d5ec;
            --accent-600: #56d5ec;
            --accent-700: #56d5ec;
            --accent-800: #56d5ec;
            --accent-900: #56d5ec;
        }
    }

    .classroom-date {
        max-width: 400px;
            .generic-input {
             font-size: 26px !important;
        }
    }

    .label {
      font-size: 16px;
    }
</style>
