const PROGRAM_NAME = 'programName'
const WAVE_NAME = 'waveName'
const COMPANY_NAME = 'companyName'
const PROGRAM_OVERVIEW = 'programOverview'
const ENROLLED_MODULES_OVERVIEW = 'enrolledModulesOverview'
const FIRST_NAME = 'firstName'
const LAST_NAME = 'lastName'
const LINKEDIN_URL = 'linkedInUrl'
const LINKEDIN_BUTTON = 'linkedInBtn'
const SUBJECT = 'subject'
const PREVIEW_TEXT = 'previewText'
const MANAGER_REPORTS_LINE = 'managerReportsLine'
const MANAGER_REPORTS = 'managerReports'
const CERTIFICATE_TITLE = 'certificateTitle'
const TRAINERS_INFO_LINE = 'trainersInfoLine'
const LANGUAGE = 'language'
const TRAINER_NAME = 'trainerName'
const TRAINER_EMAIL = 'trainerEmail'
const ACTOR_NAME = 'actorName'
const ACTOR_EMAIL = 'actorEmail'
const CLASSROOM_INFO_LINE = 'classroomInfoLine'
const MS_TEAMS_HOST = 'msTeamsHost'
const CLASSROOM_DATE = 'classroomDate'

export const MODULE_NAME = 'moduleName'
export const MANAGER_PACKAGE_LINK = 'managerPackageLink'

export const backendTemplateVariables = [
  PROGRAM_NAME,
  WAVE_NAME,
  COMPANY_NAME,
  PROGRAM_OVERVIEW,
  ENROLLED_MODULES_OVERVIEW,
  FIRST_NAME,
  LAST_NAME,
  LINKEDIN_URL,
  LINKEDIN_BUTTON,
  SUBJECT,
  PREVIEW_TEXT,
  MANAGER_REPORTS,
  MANAGER_REPORTS_LINE,
  CERTIFICATE_TITLE,
  TRAINERS_INFO_LINE,
  LANGUAGE,
  TRAINER_NAME,
  TRAINER_EMAIL,
  ACTOR_NAME,
  ACTOR_EMAIL,
  CLASSROOM_INFO_LINE,
  MS_TEAMS_HOST,
  CLASSROOM_DATE
]

export const htmlTemplateVariables = [ PROGRAM_OVERVIEW, LINKEDIN_BUTTON ]
