<template>
    <div class="wizard-container" v-show="!showPreviewEmailModal">
        <Stepper />
        <transition name="fade" mode="out-in">
            <Loading v-show="inLoadingState" />
        </transition>
        <transition name="fade" mode="out-in">
            <component :is="activeStepComponent" :availableLanguages="$props.availableLanguages"/>
        </transition>
    </div>
</template>

<script>
import Stepper from './Stepper'
import Loading from './steps/Loading'
import LoadingStep from './steps/LoadingStep'
import Setup from './steps/Setup'
import Customization from './steps/Customization'
import Recipients from './steps/Recipients'
import Scheduling from './steps/Scheduling'
import Editing from './steps/Editing'
import Confirmation from './steps/Confirmation'
import { WizardSteps } from '../../vuex/modules/comm/state'
import CommActions from '@/vuex/modules/comm/actions'
import { mapGetters } from 'vuex'

const getComponentForStep = (step) => {
  switch (step) {
    case WizardSteps.LOADING:
      return LoadingStep
    case WizardSteps.SETUP:
      return Setup
    case WizardSteps.RECIPIENTS:
      return Recipients
    case WizardSteps.CUSTOMIZATION:
      return Customization
    case WizardSteps.CONFIRMATION:
      return Confirmation
    case WizardSteps.EDITING:
      return Editing
    case WizardSteps.SCHEDULING:
      return Scheduling
    default:
      return Setup
  }
}

export default {
  name: 'Wizard',
  components: { Stepper, Loading },
  props: {
    availableLanguages: { type: Array },
    waveLanguage: { type: Object }
  },
  computed: {
    ...mapGetters({
      showPreviewEmailModal: 'comm/showPreviewEmailModal'
    }),
    activeStepComponent: function () { return getComponentForStep(this.$store.state.comm.step) },
    inLoadingState () { return this.$store.state.comm.loading && this.$store.state.comm.step > WizardSteps.LOADING }
  },
  created () {
    this.$store.dispatch(CommActions.SetWaveLanguage, this.$props.waveLanguage)
    if (this.$route.params.step) {
      this.$data.step = parseInt(this.$route.params.step)
    }
  }
}
</script>

<style scoped>
    .wizard-container {
        min-height: 70vh;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s ease;
    }

    .fade-enter-from,
    .fade-leave-active {
        opacity: 0;
    }
</style>
