<template>
  <div class="d-flex align-items-center justify-content-between">
    <Table v-bind="table"></Table>
    <EventItem v-model="currentItem"/>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import Pill from '@/components/Pill.vue'
import Table from '@/components/Table.vue'
import moment from 'moment/moment'
import { prop } from 'ramda'
import lepaya from '../http/lepaya.js'
import EventItem from './events/EventItem'

const columns = [
  {
    name: 'Id',
    prop: 'id',
    value: prop('id')
  }, {
    name: 'Type',
    prop: 'type',
    value: prop('type')
  }, {
    name: 'Name',
    prop: 'name',
    value: prop('name')
  }, {
    name: 'Source',
    prop: 'source',
    component: () => Pill,
    props: ({ source }) => ({
      text: source.toUpperCase(),
      large: true,
      color: 'primary'
    })
  }, {
    name: 'Agent',
    prop: 'agent_id',
    value: prop('agent_id')
  }, {
    name: 'Status',
    prop: 'status',
    component: () => Pill,
    props: ({ status }) => ({
      text: status.toUpperCase(),
      large: true,
      color: status === 'error' ? 'red' : (status === 'done' ? 'green' : '')
    })
  }, {
    name: 'Created At',
    prop: 'created_at',
    value: ({ created_at }) => moment(created_at).format('DD/MM/YYYY - hh:mm:ss')
  }
]

export default {
  components: {
    EventItem,
    Table
  },
  data () {
    return {
      currentItem: null,
      table: {
        showCheckboxes: false,
        records: [],
        columns,
        onClick: (event) => {
          this.currentItem = event
        }
      }
    }
  },
  beforeRouteUpdate (to, from, next) {
    next()
  },
  beforeRouteEnter (to, from, next) {
    lepaya.get('/domain-events').then(response => next(vm => {
      vm.table.records = response.data
    }))
  }
}
</script>
