<template>
  <div
    class="form-group is-invalid">
    <slot
       v-bind:class="{
       'is-invalid': hasErrors,
       }"></slot>
    <div v-for="error in errors"
         v-bind:key="error.id"
         v-show="hasErrors" class="invalid-feedback">
      {{error}}
    </div>
  </div>
</template>

<script>
import { isEmpty, complement } from 'ramda'
const isNotEmpty = complement(isEmpty)

export default {
  props: {
    errors: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    inputVm: null
  }),

  mounted () {
    // Will be garbage collected when the element is destroyed.
    this.$el.addEventListener('register-input', event => {
      this.inputVm = event.detail
    })
  },

  watch: {
    errors (errors) {
      if (this.inputVm) {
        this.inputVm.isInvalid = isNotEmpty(errors || [])
      }
    }
  },

  computed: {
    hasErrors () {
      return isNotEmpty(this.errors)
    }
  }
}
</script>
