import lepayaApi from './api.js'

const state = {
  waveLanguage: undefined,
  firstSkillScanTitle: 'Skill Scan',
  secondSkillScanTitle: 'Skill Scan',
  modulesCsUids: [],
  successSkillScanCreationMessage: null,
  failedSkillScanCreationMessage: null,
  showSkillScanTitleModal: false,
  syncEvents: []
}

const getters = {
  waveLanguage: (state) => state.waveLanguage,
  modulesCsUids: (state) => state.modulesCsUids,
  firstSkillScanTitle: (state) => state.firstSkillScanTitle,
  secondSkillScanTitle: (state) => state.secondSkillScanTitle,
  loading: (state) => state.loading,
  successSkillScanCreationMessage: (state) => state.successSkillScanCreationMessage,
  failedSkillScanCreationMessage: (state) => state.failedSkillScanCreationMessage,
  showSkillScanTitleModal: (state) => state.showSkillScanTitleModal,
  skillScanTitle: (state) => state.skillScanTitle,
  syncEvents: (state) => state.syncEvents
}

const actions = {
  setWaveLanguage ({ commit }, data) { commit('setWaveLanguage', data) },
  setInviteSchedulingDays ({ commit }, data) { commit('setInviteSchedulingDays', data) },
  setModulesCsUids ({ commit }, data) { commit('setModulesCsUids', data) },
  setFirstSkillScanTitle ({ commit }, data) { commit('setFirstSkillScanTitle', data) },
  setSecondSkillScanTitle ({ commit }, data) { commit('setSecondSkillScanTitle', data) },
  createSkillScan ({ commit }, data) {
    commit('setLoading', true)

    return new Promise((resolve) => {
      lepayaApi.createSkillScan(data)
        .then(response => {
          commit('setSuccessSkillScanCreationMessage', 'Skill scan successfully created.')
          resolve(response.data)
        })
        .catch(({ response: { data: error } }) => {
          commit('setFailedSkillScanCreationMessage', error.error_message || error.errorMessage || error)
          // eslint-disable-next-line no-console
          console.log('error', error)
        })
        .finally(() => {
          commit('setLoading', false)
          setTimeout(() => { commit('setSuccessSkillScanCreationMessage', null) }, 2000)
          setTimeout(() => { commit('setFailedSkillScanCreationMessage', null) }, 5000)
        })
    })
  },
  syncEvents ({ commit }, id) {
    return new Promise((resolve) => {
      lepayaApi.fetchWaveSyncEvents(id)
        .then(response => {
          commit('setSyncEvents', response.data)
          resolve(response.data)
        }).catch(error => {
          // eslint-disable-next-line no-console
          console.log('error', error)
        })
    })
  }
}

const mutations = {
  setWaveLanguage: (state, data) => { state.waveLanguage = data },
  setInviteSchedulingDays: (state, data) => { state.inviteSchedulingDays = data },
  setModulesCsUids: (state, data) => { state.modulesCsUids = data },
  setFirstSkillScanTitle: (state, data) => { state.firstSkillScanTitle = data },
  setSecondSkillScanTitle: (state, data) => { state.secondSkillScanTitle = data },
  setLoading: (state, value) => { state.loading = value },
  setSuccessSkillScanCreationMessage: (state, value) => { state.successSkillScanCreationMessage = value },
  setFailedSkillScanCreationMessage: (state, value) => { state.failedSkillScanCreationMessage = value },
  setShowSkillScanTitleModal: (state, value) => { state.showSkillScanTitleModal = value },
  setSyncEvents: (state, value) => { state.syncEvents = value }
}

export default {
  state,
  getters,
  actions,
  mutations
}
