import './assets/lepaya.scss'
import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './components/App.vue'
import router from './router.js'
import { BootstrapVue } from 'bootstrap-vue'
import store from './vuex'
import Vuelidate from 'vuelidate'
import VCalendar from 'v-calendar'
import VuePellEditor from 'vue-pell-editor'
import VueEasytable from 'vue-easytable'
import vSelect from 'vue-select'
import ToggleButton from 'vue-js-toggle-button'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-easytable/libs/theme-default/index.css'
import 'vue-select/dist/vue-select.css'

Vue.component('v-select', vSelect)

Vue.use(VuePellEditor)
Vue.use(Vuelidate)
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(VCalendar, { componentPrefix: 'vc' })
Vue.use(VueEasytable)
Vue.use(ToggleButton)
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
