<template>
  <span
  @click="$emit('click')"
  :class="classes"
  :style="{...colorStyle, ...linkStyle, ...sizeStyle}"
  class="badge badge-pill">{{text}}<slot></slot></span>

</template>

<script>
export default {
  props: {
    text: String,
    type: {
      type: String,
      default: 'primary'
    },
    color: {
      type: String,
      required: false,
      default: 'gray'
    },
    isClickable: {
      type: Boolean,
      required: false,
      default: false
    },
    large: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    classes () {
      return {
        [`badge-${this.type}`]: true
      }
    },
    colorStyle () {
      const colors = {
        gray: { background: '#ddd', text: '#000' },
        green: { background: '#55c968', text: '#000' },
        blurple: { background: '#5539cc', text: '#eee' },
        red: { background: '#FF6666', text: '#fff' }
      }
      if (this.color && colors[this.color]) {
        return { backgroundColor: colors[this.color].background, color: colors[this.color].text }
      }
      return {}
    },
    sizeStyle () {
      if (this.large) {
        return { fontSize: '1em' }
      }
      return {}
    },
    linkStyle () {
      return this.isClickable ? { cursor: 'pointer' } : {}
    }
  }

}
</script>
