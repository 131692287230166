import { WebAuth } from 'auth0-js'
import sum from 'hash-sum'
import { isNil } from 'ramda'

const auth0Config = {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientID: process.env.VUE_APP_AUTH0_CLIENT_ID,
  redirectUri: process.env.VUE_APP_AUTH0_REDIRECT_URI || window.location.origin,
  responseType: process.env.VUE_APP_AUTH0_RESPONSE_TYPE,
  scope: process.env.VUE_APP_AUTH0_SCOPE,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE
}

const auth0AccessTokenStorageKey = `${sum(auth0Config)}-access-token`
const auth0 = new WebAuth(auth0Config)

auth0.accessToken = value => value
  ? localStorage.setItem(auth0AccessTokenStorageKey, value)
  : localStorage.getItem(auth0AccessTokenStorageKey)

const authorize = auth0.authorize
const logout = auth0.logout

auth0.authorize = function (...args) {
  localStorage.removeItem(auth0AccessTokenStorageKey)
  authorize.call(this, ...args)
}

auth0.logout = function (...args) {
  localStorage.removeItem(auth0AccessTokenStorageKey)
  logout.call(this, ...args)
}

const parseHash = auth0.parseHash.bind(auth0)
auth0.parseHash = function (...args) {
  let expiresIn

  const promise = new Promise((resolve, reject) => {
    parseHash(...args, (error, result) => {
      if (isNil(result)) {
        return auth0.checkSession({}, (error, result) => {
          if (error) { return reject(error) }

          expiresIn = result.expiresIn
          auth0.accessToken(result.accessToken)
          resolve(auth0.accessToken())
        })
      }

      if (error) { return reject(error) }

      if (result) {
        expiresIn = result.expiresIn
        auth0.accessToken(result.accessToken)

        return resolve(auth0.accessToken())
      }

      auth0.authorize()
    })
  })

  promise.then(() => {
    setInterval(() => {
      auth0.checkSession({}, (_error, result) => {
        if (result) { auth0.accessToken(result.accessToken) }
      })
    }, expiresIn * 500)
  })

  return promise
}

export default auth0
