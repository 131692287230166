<template>
  <router-link :class="classes" :to="toValue">{{name}}<slot></slot></router-link>
</template>

<script>
export default {
  props: {
    to: [ Object, String ],
    middle: Boolean,
    href: String,
    name: {
      default: '',
      type: String
    }
  },

  computed: {
    toValue () {
      return this.to || this.href
    },
    classes () {
      return this.middle ? 'vertical-align' : ''
    }
  }
}
</script>
