<template>
  <Datepicker
    @selected="setDate"
    :value="value"
    input-class="d-block w-100 generic-input"
    placeholder="Select a date"
    :disabled-dates="disabledDates"
    :disabled="disabled"
    ref="iconOpen"
  >
    <span slot="afterDateInput"
        class="date-icon"
        @click="openPicker">
    <Icon icon="calendar-alt" />
    </span>
  </Datepicker>
</template>
<script>
import Datepicker from 'vuejs-datepicker'
import Icon from '../components/Icon.vue'
export default {
  components: {
    Datepicker,
    Icon
  },
  props: {
    value: {
      type: [ String, Number, Date ]
    },
    disabledDates: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openPicker () {
      this.$refs.iconOpen.showCalendar()
    },
    setDate (value) {
      this.$emit('setDate', value)
    }
  }
}
</script>
<style scoped>
.date-icon {
  position: absolute;
  color: #8c8ca0;
  right: 9px;
  top: 9px;
}
</style>
