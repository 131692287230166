<template>
  <div class="h-100" id="app">
    <header class="shadow-sm bg-white position-relative">
      <User class="float-right m-3" :user="profile"></User>

      <Anchor href="/">
        <img style="height: 4rem" class="p-3" src="logo.png" alt="Lepaya" />
      </Anchor>

      <Select
        placeholder="Select a company"
        v-if="isEditor"
        :value="currentCompany"
        @focus.native="updateCompanies"
        @input="changeCompany"
        :options="profile.companies"
      />

      <Badge v-if="currentCompany && currentCompany.isDemo" type="primary" class="ml-3">Demo</Badge>
      <Anchor class="ml-4" @click.native="resetCompany" v-if="profile.isOwner" :to="listDomainEvents">Owner</Anchor>
    </header>
    <router-view></router-view>
  </div>
</template>

<script>
import Anchor from './Anchor.vue'
import Badge from './Badge.vue'
import User from './User.vue'
import Select from './Select.vue'
import lepaya from '../http/lepaya.js'

export default {
  components: {
    Select,
    User,
    Badge,
    Anchor
  },

  data: () => ({
    profile: { companies: [] },
    currentCompany: {},
    listDomainEvents: {
      name: 'listDomainEvents'
    }
  }),

  computed: {
    isEditor() {
      return ['content_manager', 'admin'].includes(this.profile.role)
    },
    header() {
      return (this.$route.meta || {}).header || {}
    }
  },

  methods: {
    resetCompany() {
      this.currentCompany = undefined
    },
    changeCompany(company) {
      this.currentCompany = company

      company &&
        !this.pushed &&
        this.$router.push({
          name: 'company',
          params: { companyCode: (company || {}).code }
        })

      if (this.pushed) this.pushed = false
    },
    updateCompanies() {
      updateCompanies(this.$route, cb => cb(this))
    }
  },

  beforeRouteEnter(to, from, next) {
    updateCompanies(to, next)
  },

  beforeRouteUpdate(to, from, next) {
    if (this.currentCompany && !to.path.startsWith('/owner/', 0) && to.params.companyCode !== this.currentCompany.code) {
      this.currentCompany = this.profile.companies.find(({ code }) => code === to.params.companyCode)
      this.pushed = true
    }

    next()
  }
}

function updateCompanies(to, next) {
  lepaya
    .get('/profile')
    .then(({ data: profile }) =>
      next(vm => {
        vm.currentCompany = profile.companies.find(({ code }) => code === to.params.companyCode)
        localStorage.setItem('companyCode', to.params.companyCode)
        vm.profile = profile
      })
    )
    .catch(next)
}
</script>

<style scoped>
select {
  border-bottom: 0px;
  width: 11rem;
  display: inline-block;
}
</style>
