<template>
    <div class="step-container"><slot/></div>
</template>

<script>
export default {
  name: 'StepContainer'
}
</script>

<style scoped>
    .step-container {
        width: 100%;
        min-height: 70vh;
        display: flex;
        flex-direction: column;
        padding: 2em;
    }
</style>
