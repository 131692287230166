<template>
  <label class="label">{{value}} <span v-if="important" class="important">*</span><slot></slot></label>
</template>

<style scoped>
  .label {
    font-size: 18px;
    font-weight: 900;
    color: #8c8ca0;
  }

  .important {
    color: #ee0264
  }

  .required::after {
    content: "*";
    color: #ff0063;
    margin-left: .3rem;
  }
</style>

<script>
export default {
  props: {
    value: String,
    important: Boolean
  }
}
</script>
