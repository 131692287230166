<template>
  <div class="w-100 h-100 position-fixed">
    <div class="content w-content position-relative">
      <router-view></router-view>
    </div>

    <ul :class="classes" class="nav border-top shadow-sm bg-white flex-column">
      <li class="nav-item" v-bind:key="route.title" v-for="route in routes">
        <a v-if="route.href" class="nav-link py-4" :href="route.href" target="_blank">
          <IconText :icon="route.icon" :parentStyle="true">{{ collapsed ? '' : route.title }}</IconText>
        </a>
        <router-link v-if="!route.href" class="nav-link py-4" :to="route">
          <IconText :icon="route.icon" :parentStyle="true">{{ collapsed ? '' : route.title }}</IconText>
        </router-link>
      </li>
    </ul>

    <router-view name="modal"></router-view>
  </div>
</template>

<script>
import IconText from './IconText.vue'
import { isNil } from 'ramda'
import { routerComponent } from './helpers.js'

export default routerComponent({
  data: () => ({
    routes: [],
    collapsed: false
  }),

  components: {
    IconText
  },

  computed: {
    // should use css hover instead
    classes() {
      return {
        'shadow-lg': !this.collapsed
      }
    }
  },

  methods: {
    collapse(collapsed) {
      this.collapsed = isNil(collapsed) ? !this.collapsed : collapsed
    }
  },

  beforeRouteEnter(to, from, next) {
    const matched = to.matched.find(m => m.meta && m.meta.navbar)

    next(vm => {
      vm.routes = matched
        ? matched.meta.navbar.map(route => ({
            ...route,
            params: to.params
          }))
        : []
    })
  }
})
</script>

<style scoped>
.nav {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 15rem;
  z-index: 10;
}

.nav-link {
  color: rgb(86, 213, 236) !important;
}
.nav:hover {
  width: 15rem;
}

.nav .router-link-active {
  background-color: rgba(62, 60, 89, 1);
  color: rgb(255, 255, 255) !important;
  font-weight: bold;
}

.content {
  overflow-y: auto;
  height: calc(100% - 80px);
  top: 0px;
  overflow-x: hidden;
  margin-left: 15rem;
}

.nav li {
  overflow: hidden;
  white-space: nowrap;
}
</style>
