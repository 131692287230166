<template>
<div class="wave-id-cell">
  <a class="edit-link" @click="goToModule">{{module.title}}</a>
</div>
</template>

<script>
export default {
  name: 'ModuleTitleCell',
  props: {
    module: {
      type: Object,
      required: true
    }
  },
  methods: {
    goToModule () {
      const { companyCode } = this.$route.params
      this.$router.push(`/companies/${companyCode}/journeys/${this.module.id}/?programId=${this.module.waveId}&programCatalogueItemId=${this.module.programId}`)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/colors";

.wave-id-cell {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  border-right: 1px solid $lightGrayBorder;
}

.edit-link {
  color: $actionBlue;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  margin-right: 14px;
}

</style>
