import FormLabel from '@/components/FormLabel.vue'
import OverlayingSpinner from '@/components/common/OverlayingSpinner.vue'
import NoCommunicationsMessage from '@/views/communication/NoCommunicationsMessage.vue'
import CommunicationTypeModal from '@/views/communication/communicationTypeSelection/CommunicationTypeModal.vue'
import PreviewEmailModal from '@/views/communication/previewEmail/PreviewEmailModal.vue'
import ViewSentEmailModal from '@/views/communication/previewEmail/ViewSentEmailModal.vue'
import { ViewMode } from '@/vuex/modules/comm/state'
import moment from 'moment-timezone'
import { clone, equals, isNil, map, prop, props, values } from 'ramda'
import { mapActions, mapGetters } from 'vuex'
import Label from '../components/Label.vue'
import Radio from '../components/Radio.vue'
import lepaya from '../http/lepaya.js'
import SentView from '../views/communication/SentView'
import Wizard from '../views/communication/Wizard'
import CommActions from '../vuex/modules/comm/actions'
import Anchor from './Anchor.vue'
import Badge from './Badge.vue'
import Button from './Button.vue'
import ButtonsBar from './ButtonsBar.vue'
import Checkbox from './Checkbox.vue'
import CommunicationActions from './CommunicationActions.vue'
import Emphasis from './Emphasis.vue'
import Form from './Form.vue'
import FormGroup from './FormGroup.vue'
import FormText from './FormText.vue'
import Icon from './Icon.vue'
import IconText from './IconText.vue'
import ImageUpload from './ImageUpload.vue'
import Input from './Input.vue'
import Modal from './Modal.vue'
import NotificationPanel from './NotificationPanel.vue'
import Pill from './Pill.vue'
import Search from './Search.vue'
import Select from './Select.vue'
import Span from './Span.vue'
import Table from './Table.vue'
import TextArea from './TextArea.vue'
import { uploadImage, windowFocusComponent } from './helpers.js'
const daysOptions = Array(15)
  .fill(null)
  .map((_, i) => ({ code: i + 1, name: `Send invites ${i + 1} day${i === 0 ? '' : 's'} before classroom` }))
daysOptions.unshift({ code: -1, name: 'Send Invites with Program Announcement' })
daysOptions.unshift({ code: 0, name: 'No Scheduled Invites' })

const primaryKey = 'id'
const columns = {
  title: {
    name: 'Title',
    value: ({ title }) => title
  },
  program: {
    name: 'Program',
    value: ({ programName }) => programName
  },
  wave: {
    name: 'Wave',
    value: ({ wave }) => wave
  },
  status: {
    name: 'Status',
    component: () => Badge,
    props: ({ status }) => ({
      text: status,
      class: {
        'text-uppercase': true
      },
      type: status === 'draft' ? 'secondary' : status === 'archived' ? 'grey' : 'success'
    })
  },
  classroomDate: {
    name: 'Classroom Date',
    component: () => Span,
    props: ({ classroomDateString, classroomDate }) => ({
      text: classroomDateString || 'Classroom Date Not Set',
      type: isNil(classroomDate) ? 'grey' : 'black'
    })
  },
  edit: {
    component: () => Anchor,
    props: ({ id }) => ({
      to: {
        name: 'editJourney',
        params: { id }
      },
      name: 'Edit'
    })
  }
}

const participantColumns = {
  firstName: {
    name: 'First Name',
    value: ({ firstName }) => firstName
  },
  lastName: {
    name: 'Last Name',
    value: ({ lastName }) => lastName
  },
  email: {
    name: 'Email',
    value: ({ email }) => email
  },
  id: {
    name: 'Employee Id',
    value: ({ id }) => id
  },
  role: {
    name: 'Role',
    value: ({ role }) => role
  }
}

const communicationColumns = {
  name: {
    name: 'Name',
    value: ({ name }) => name
  },
  subject: {
    name: 'Subject',
    value: ({ subject }) => subject
  },
  status: {
    name: 'Status',
    component: () => Badge,
    props: ({ status }) => ({
      text: status,
      class: {
        'text-uppercase': true
      },
      type: status === 'DRAFT' ? 'secondary' : status === 'SENT' ? 'success' : 'info'
    })
  },
  step: {
    name: 'Step',
    component: () => Badge,
    props: ({ step }) => ({
      text: step.toString(),
      type: 'info'
    })
  },
  lastModifiedBy: {
    name: 'Last modified by',
    value: ({ lastModifiedBy }) => lastModifiedBy
  },
  updatedAt: {
    name: 'Last edited',
    value: ({ updatedAt }) => moment(updatedAt).format('DD.MM.YYYY HH:mm')
  },
  edit: {
    name: 'Actions',
    component: () => CommunicationActions,
    props: communication => ({
      communication
    })
  }
}

const syncEventColumns = {
  id: {
    name: 'id',
    value: ({ id }) => id
  },
  name: {
    name: 'Name',
    value: ({ name }) => name
  },
  source: {
    name: 'Source',
    component: () => Badge,
    props: ({ source }) => ({
      text: source.toUpperCase(),
      type: 'info'
    })
  },
  created_at: {
    name: 'Time',
    value: row => (row.created_at ? moment(row.created_at).format('DD.MM.YYYY hh:mm:ss') : 'Not Set')
  },
  status: {
    name: 'Status',
    component: () => Pill,
    props: row => ({
      large: true,
      color: row.status === 'done' ? 'green' : row.status === 'error' ? 'red' : '',
      text: row.status.toUpperCase()
    })
  }
}

const autoCommunicationColumns = {
  title: {
    name: 'Name',
    value: ({ name }) => name
  },
  scheduledDate: {
    name: 'Scheduled Date',
    value: ({ triggerTime }) => moment(triggerTime).format('DD.MM.YYYY HH:mm')
  },
  status: {
    name: 'Status',
    component: () => Pill,
    props: ({ hasTriggered }) => ({
      large: true,
      color: hasTriggered ? 'green' : '',
      text: hasTriggered ? 'SENT' : 'PENDING'
    })
  }
}

export default ({ data, methods }) => {
  return windowFocusComponent({
    components: {
      Table,
      Modal,
      TextArea,
      Form,
      FormText,
      ImageUpload,
      ButtonsBar,
      Button,
      Icon,
      IconText,
      FormGroup,
      Input,
      Anchor,
      Pill,
      NotificationPanel,
      Search,
      Checkbox,
      Select,
      Radio,
      Label,
      Wizard,
      NoCommunicationsMessage,
      CommunicationTypeModal,
      PreviewEmailModal,
      ViewSentEmailModal,
      SentView,
      OverlayingSpinner,
      FormLabel
    },

    data () {
      return {
        ...data.call(this),
        selectingJourneys: false,
        selectingParticipants: false,
        journeys: [],
        employees: [],
        participants: [],
        errors: {},
        program: {
          journeys: [],
          programCatalogueItemId: this.$route.params.programCatalogueItemId,
          inviteSchedulingDays: null
        },
        selectedJourneys: [],
        selectedEmployees: [],
        selectedCommunications: [],
        journeysSelection: [],
        journeysToUnlink: [],
        assignedJourneyIds: [],
        currentParticipants: [],
        programCatalogueItem: {},
        listJourneysColumns: props([ 'title', 'program', 'wave', 'status', 'classroomDate'], columns),
        selectJourneysColumns: values(columns),
        participantColumns: values(participantColumns),
        communicationColumns: values(communicationColumns),
        inputQuery: [],
        originalProgram: {
          journeys: [],
          programCatalogueItemId: this.$route.params.programCatalogueItemId,
          languageCode: '',
          inviteSchedulingDays: null
        },
        invitesNotificationText: '',
        currentLanguage: {},
        currentInviteSchedulingDays: {},
        daysOptions,
        languages: [],
        journeysToSendMessage: [],
        currentJourneyFirstBite: {},
        currentCustomMessage: '',
        sendingMessage: false,
        sendMessageNotificationText: false,
        sendMessageButtonDisabled: true,
        sendMessageLoading: false,
        loading: false,
        showAddedParticipantsDialog: false,
        newRecipientsIds: [],
        newParticipantsIds: [],
        certificate: {
          title: null,
          url: null,
          sharedPlatform: 'linkedin'
        },
        originalCertificate: {},
        ViewMode,
        programMessage: null,
        wavelessJourneys: [],
        waveJourneysAndWavelessJourneys: [],
        modulesCsUids: [],
        syncEventColumns: values(syncEventColumns),
        waveSyncEvents: [],
        autoCommunicationColumns: values(autoCommunicationColumns)
      }
    },
    mounted () {
      const { companyCode, id, participantsAdded } = this.$route.params
      if (id) {
        this.$store.dispatch(CommActions.GetCommunicationsList, { companyCode, id })
        this.$store.dispatch(CommActions.GetAutoCommunications, { companyCode, id })
        this.$store.dispatch('waves/syncEvents', id)
      }
      if (participantsAdded) {
        const el = this.$el.getElementsByClassName('col-sm-6 text-right')[0]
        if (el) el.scrollIntoView()
        this.currentParticipants = this.$route.query.newParticipants
        this.newRecipientsIds = this.currentParticipants
          .filter(item => item.id && [ 'employee', 'content_manager' ].includes(item.role))
          .map(item => item.id)
        this.showNewAddedParticipantsModal()
      }
      if (this.$route.meta.addCommunicationDialog && id) {
        this.$store.dispatch(CommActions.Open, { companyCode, id })
      }
    },
    computed: {
      ...mapGetters('modules', {
        modules: 'modules',
        disableImportButton: 'disableImportButton',
        currentModule: 'currentModule',
        loading: 'loading',
        successImportMessage: 'successImportMessage',
        failedImportMessage: 'failedImportMessage',
        publishModulesResponse: 'publishModulesResponse'
      }),
      ...mapGetters('waves', {
        successSkillScanCreationMessage: 'successSkillScanCreationMessage',
        failedSkillScanCreationMessage: 'failedSkillScanCreationMessage'
      }),
      notificationText () {
        return this.$route.query[primaryKey] || this.$route.query.id || this.$route.query.selectedIds
          ? this.$route.query.biteDuplication
            ? 'Bites were duplicated to the module successfully'
            : this.$route.query.id
              ? 'Module was duplicated successfully'
              : `${this.$route.query[primaryKey] ? '' : this.$route.query.selectedIds.length} Modules were duplicated successfully`
          : this.$route.query.delete
            ? `${this.$route.query.selectedParticipants.length} participant${
              this.$route.query.selectedParticipants.length > 1 ? 's' : ''
            } removed from the wave`
            : this.$route.query.add
              ? `${this.$route.query.newParticipants.length} participant${this.$route.query.newParticipants.length > 1 ? 's' : ''} added to the wave`
              : this.$route.params.participantsAdded
                ? `${this.$route.params.participantsAdded} participant${this.$route.params.participantsAdded > 1 ? 's' : ''} added to the wave`
                : this.$route.query.journeysToUnlink
                  ? `${this.$route.query.journeysToUnlink.length} module${this.$route.query.journeysToUnlink.length > 1 ? 's' : ''} removed from the wave`
                  : this.$route.query.newJourneys
                    ? `${this.$route.query.newJourneys.length} module${this.$route.query.newJourneys.length > 1 ? 's' : ''} added to the wave`
                    : this.sendMessageNotificationText
                      ? this.fullSendMessageNotificationText
                      : null
      },
      fullSendMessageNotificationText () {
        const successfullMessage = `${this.messagesSent ? `${this.messagesSent} message${this.messagesSent > 1 ? 's' : ''} sent successfully` : ''}`
        const errorMessage = `${
          this.messagesFailed
            ? `${this.messagesFailed} message${this.messagesFailed > 1 ? 's' : ''} failed to send. Get in touch with tech team for more assistance.`
            : ''
        }`
        return `${successfullMessage}${successfullMessage && errorMessage ? ', ' : ''}${errorMessage}`
      },
      notificationClass () {
        return this.sendMessageNotificationText ? { 'bg-success': !this.messagesFailed, 'bg-danger': this.messagesFailed } : { 'bg-success': true }
      },
      selectedJourneyCount () {
        return this.program.journeys.length
      },
      changeProgramHref () {
        return `/companies/${this.$route.params.companyCode}/programs/start`
      },
      cancelHref () {
        return `/companies/${this.$route.params.companyCode}/programs`
      },
      deleteProgramDisabled () {
        return (
          this.create ||
          this.selectedJourneys.length !== this.assignedJourneyIds.length ||
          !this.selectedJourneys.every(({ status, id }) => status === 'archived' && this.assignedJourneyIds.find(aId => aId === id))
        )
      },
      updateLanguageEnabled () {
        if (this.isSalesforceWave) {
          return false
        }
        if (this.selectedJourneys.length <= 0) {
          return true
        }
        const earliestClassroomDate = this.selectedJourneys
          .filter(j => j.classroomDateString !== null)
          .map(j => moment(j.classroomDateString, 'DD/MM/YYYY').startOf('day'))
          .sort((a, b) => (a.isBefore(b) ? -1 : 1))[0]
        if (!earliestClassroomDate) {
          return true
        }
        const thirtyDayFromNow = moment().add(30, 'day').endOf('day')
        return earliestClassroomDate.isAfter(thirtyDayFromNow)
      },
      buttonDisabled () {
        return !this.create && !this.programPropertiesChanged
      },
      programPropertiesChanged () {
        return (
          !equals(this.program.name, this.originalProgram.name) ||
          !equals(this.program.languageCode, this.originalProgram.languageCode) ||
          !equals(this.program.isCalendarEnabled, this.originalProgram.isCalendarEnabled) ||
          !equals(this.program.endOfProgramEmail, this.originalProgram.endOfProgramEmail) ||
          !equals(this.program.inviteSchedulingDays, this.originalProgram.inviteSchedulingDays) ||
          !equals(this.certificate, this.originalCertificate) ||
          this.program.imageUrl instanceof File
        )
      },
      handlingInvites () {
        return this.originalProgram.eventCount > 0 && this.program.isCalendarEnabled !== this.originalProgram.isCalendarEnabled
      },
      eventsNumberMessage () {
        return this.originalProgram.isCalendarEnabled
          ? `By clicking Cancel Invites, all calendar invites will be cancelled  for ${this.originalProgram.eventCount} existing classroom events`
          : `By clicking Send Invites, all calendar invites will be sent out for ${this.originalProgram.eventCount} existing classroom events`
      },
      displaySendMessage () {
        const params = this.$route.params
        const { companyCode } = params
        return companyCode === 'FRIESLANCAMPINA' || companyCode === 'FRIESLANDCAMPINA'
      },
      editingCommunication () {
        return this.$store.state.comm.openModal
      },
      removingCommunication () {
        return this.$store.state.comm.removingId !== null
      },
      removeCommunicationError () {
        return this.$store.state.comm.removeError
      },
      removeCommunicationMessage () {
        return this.$store.state.comm.removeMessage
      },
      removeLoading () {
        return this.$store.state.comm.removeLoading
      },
      cancelingInvitesMessage () {
        const milliseconds = this.originalProgram.eventCount * 1000
        const minutes = Math.floor(milliseconds / 60000)
        const seconds = ((milliseconds % 60000) / 1000).toFixed(0)

        const time = (minutes > 1 ? minutes + ' minutes and ' : '') + seconds + ' seconds'
        return `${this.originalProgram.isCalendarEnabled ? 'Canceling' : 'Sending'} Google Calendar invites .... This will take approximately ${time}, please don't close your browser and wait for it to finish.`
      },
      publishModulesResponseClass () {
        if (!this.publishModulesResponse) return null
        if (this.publishModulesResponse.status === 'success') return { 'bg-success': true }
        if (this.publishModulesResponse.status === 'partialSuccess') return { 'bg-warning': true }
        if (this.publishModulesResponse.status === 'fail') return { 'bg-danger': true }
      },
      enableCreateSkillScan () {
        return this.program.journeys.length && this.program.journeys.filter(journey => journey.catalogueCode).length >= 1
      },
      removingParticipant () {
        return this.$store.state.comm.removeParticipantModal
      },
      isSalesforceWave () {
        return !!this.program.sfid
      }
    },

    methods: {
      ...mapActions('modules', {
        setCurrentModule: 'setCurrentModule',
        publishModules: 'publishModules',
        getProgramModules: 'getProgramModules'
      }),
      ...mapActions('waves', {
        createSkillScan: 'createSkillScan'
      }),
      ...methods,
      setLanguage (value) {
        this.program.languageCode = value.code
        this.currentLanguage = value
        this.$store.commit('waves/setWaveLanguage', value.code)
      },
      setInviteSchedulingDays (value) {
        this.program.inviteSchedulingDays = value.code
        this.currentInviteSchedulingDays = value
        this.$store.commit('waves/setInviteSchedulingDays', value.code)
      },
      toJourneysList (journey) {
        const { id, programCatalogueItemId } = this.$route.params
        this.$router.push({
          name: 'editJourney',
          params: journey,
          query: { programId: id, programCatalogueItemId }
        })
      },
      toProgramsList () {
        const { companyCode } = this.$route.params
        this.$router.push({
          name: 'listPrograms',
          params: { companyCode }
        })
      },
      onClose () {
        this.selectingJourneys = false
        this.selectedJourneys = this.initialJourneys
      },
      onCloseParticipants () {
        this.selectingParticipants = false
        this.currentParticipants = this.initialParticipants
      },
      onCloseCalendarInvites () {
        if (this.sendMessageLoading) return
        this.program.isCalendarEnabled = this.originalProgram.isCalendarEnabled
        return this.program.isCalendarEnabled
      },
      toAddParticipants () {
        this.selectingParticipants = true
        this.inputQuery = []
      },
      rowType (row) {
        if (
          row[primaryKey] === this.$route.query[primaryKey] ||
          row[primaryKey] === this.$route.query.selectedIds ||
          (Array.isArray(this.$route.query.selectedIds) && this.$route.query.selectedIds.find(id => row[primaryKey] === id)) ||
          (Array.isArray(this.$route.query.newParticipants) && this.$route.query.newParticipants.find(id => row[primaryKey] === id)) ||
          (Array.isArray(this.$route.query.newJourneys) && this.$route.query.newJourneys.find(id => row[primaryKey] === id))
        ) {
          return 'success'
        }
      },
      onEscape () {
        if (this.selectedJourneys.length === 0) {
          return this.onClose()
        }

        this.selectedJourneys = []
      },
      onEscapeParticipants () {
        if (this.currentParticipants.length === 0) {
          return this.onCloseParticipants()
        }

        this.currentParticipants = []
      },
      onCreateNewJourney () {
        this.$router.push({
          name: 'createJourney'
        })
      },
      onSelectJourneys () {
        this.program.journeys = this.selectedJourneys
        this.onClose()
      },

      addParticipants () {
        const params = this.$route.params
        const { companyCode, id, programCatalogueItemId } = params
        const current = this.currentParticipants.map(item => item.id)
        const initial = this.initialParticipants.map(item => item.id)
        const newParticipants = current.filter(newParticipant => !initial.includes(newParticipant))
        this.newParticipantsIds = newParticipants
        this.newRecipientsIds = this.currentParticipants
          .filter(item => this.newParticipantsIds.includes(item.id) && [ 'employee', 'content_manager' ].includes(item.role))
          .map(item => item.id)
        this.showNewAddedParticipantsModal()

        if (newParticipants.length <= 0) {
          this.onCloseParticipants()
        } else {
          lepaya
            .post(`/companies/${companyCode}/programs/${id}/participants`, { employeesIds: newParticipants })
            .then(() => {
              lepaya.get(`/companies/${companyCode}/programs/${id}/participants`).then(({ data: participants }) => {
                this.$router.push({
                  name: 'editProgram',
                  query: { add: true, newParticipants },
                  params: { programCatalogueItemId, id, companyCode }
                })
                this.selectedEmployees = []
                this.onCloseParticipants()
                this.currentParticipants = participants
                return participants
              })
            })
            .catch(({ response: { data: errors } }) => {
              this.errors = errors
            })
            .finally(() => {
              editProgramRouter.call(this, programCatalogueItemId, id, companyCode)
            })
        }
      },
      deleteParticipants () {
        this.$refs['modal-remove-participant'].hide()
        if (!this.selectedEmployees.length) return
        const params = this.$route.params
        const { companyCode, id, programCatalogueItemId } = params
        const selectedParticipants = this.selectedEmployees.map(item => item.id)
        const payload = { employeesIds: selectedParticipants }
        return lepaya
          .delete(`/companies/${companyCode}/programs/${id}/participants`, { data: payload })
          .then(() => {
            lepaya.get(`/companies/${companyCode}/programs/${id}/participants`).then(({ data: participants }) => {
              this.$router.push({
                name: 'editProgram',
                query: { delete: true, selectedParticipants },
                params: { programCatalogueItemId, id, companyCode }
              })
              this.selectedEmployees = []
              this.currentParticipants = participants
              return participants
            })
          })
          .catch(({ response: { data: errors } }) => {
            this.errors = errors
          })
          .finally(() => {
            editProgramRouter.call(this, programCatalogueItemId, id, companyCode)
          })
      },
      unlinkJourneys () {
        if (!this.journeysSelection.length) return
        const params = this.$route.params
        const { companyCode, id, programCatalogueItemId } = params
        const journeysToUnlink = this.journeysSelection.map(item => item.id)
        const payload = { journeyIds: journeysToUnlink }
        return lepaya
          .delete(`/companies/${companyCode}/programs/${id}/journeys`, { data: payload })
          .then(() => {
            lepaya.get(`/companies/${companyCode}/programs/${id}/journeys`).then(({ data: journeys }) => {
              this.$router.push({
                name: 'editProgram',
                query: { journeysToUnlink },
                params: { programCatalogueItemId, id, companyCode }
              })
              this.journeysSelection = []
              this.journeys = journeys
              this.selectedJourneys = journeys
              return journeys
            })
          })
          .catch(({ response: { data: errors } }) => {
            this.errors = errors
          })
          .finally(() => {
            editProgramRouter.call(this, programCatalogueItemId, id, companyCode)
          })
      },
      unlinkCommunications () {
        alert('Selected: ' + this.selectedCommunications.length + ' communications. Delete not implemented yet.')
      },
      onProgramPersist () {
        const params = this.$route.params
        const { companyCode } = params
        const shouldUpload = this.program.imageUrl instanceof File
        return (shouldUpload ? uploadImage(this.program.imageUrl, companyCode, 'programs') : Promise.resolve(undefined))
          .then(key => ({
            name: this.program.name || null,
            programCatalogueItemId: this.programCatalogueItem.id,
            journeys: this.program.journeys.map(({ id }) => id),
            ...(key ? { imageKey: key } : {}),
            newJourneys: !this.create ? this.getNewJourneys() : [],
            isCalendarEnabled: this.program.isCalendarEnabled ? this.program.isCalendarEnabled : false,
            endOfProgramEmail: this.program.endOfProgramEmail ? this.program.endOfProgramEmail : false,
            languageCode: this.currentLanguage ? this.currentLanguage.code : null,
            inviteSchedulingDays: this.program.inviteSchedulingDays || null,
            certificate: { title: this.certificate.title || null, url: this.certificate.url || null }
          }))
          .then(body => {
            this.loading = true
            return this.persist(params, body)
          })
          .then(({ data: { id: created } }) => {
            const element = document.getElementById('companyProgram')
            if (element) element.scrollIntoView()
            this.programMessage = params.id ? 'Wave was successfully updated' : 'Wave was successfully created'
            setTimeout(() => {
              this.programMessage = null
              if (params.id) {
                location.reload()
              } else {
                this.$router.push({
                  name: 'editProgram',
                  params: { programCatalogueItemId: this.programCatalogueItem.id, id: created, companyCode }
                })
              }
            }, 3000)
          })
          .catch(({ response: { data: errors } }) => {
            this.errors = errors
          })
          .finally(() => {
            this.loading = false
          })
      },
      getNewJourneys () {
        const current = this.program.journeys.map(item => item.id)
        const initial = this.initialJourneys.map(item => item.id)
        return current.filter(newJourney => !initial.includes(newJourney))
      },
      deleteCompanyProgram () {
        const { companyCode, id } = this.$route.params
        lepaya.delete(`/companies/${companyCode}/programs/${id}`).then(() =>
          this.$router.push({
            name: 'listPrograms',
            query: { archive: true }
          })
        )
      },
      handleCalendarInvites () {
        this.sendMessageLoading = true
        const { companyCode, id } = this.$route.params
        this.invitesNotificationText = ''
        if (this.originalProgram.isCalendarEnabled) {
          lepaya
            .delete(`/companies/${companyCode}/programs/${id}/calendar/events`)
            .then(data => {
              this.invitesNotificationText = `${data.data} invites were cancelled`
              this.originalProgram.isCalendarEnabled = false
              return false
            })
            .catch(({ response: { data: errors } }) => {
              this.errors = errors
            })
            .finally(() => {
              this.sendMessageLoading = false
            })
        } else {
          lepaya
            .post(`/companies/${companyCode}/programs/${id}/calendar/events`)
            .then(data => {
              this.invitesNotificationText = `${data.data} invites were sent`
              this.originalProgram.isCalendarEnabled = true
              return true
            })
            .catch(({ response: { data: errors } }) => {
              this.errors = errors
            })
            .finally(() => {
              this.sendMessageLoading = false
            })
        }
      },
      setJourneyFirstBite (value) {
        this.currentJourneyFirstBite = value
        this.refreshSendMessageButtonDisabled()
      },
      setCustomMessage (value) {
        this.currentCustomMessage = value
        this.refreshSendMessageButtonDisabled()
      },
      openSendMessage () {
        this.sendingMessage = true
      },
      onCloseSendMessage () {
        this.sendingMessage = false
        this.currentCustomMessage = ''
        this.currentJourneyFirstBite = this.journeysToSendMessage[-1]
        this.refreshSendMessageButtonDisabled()
      },
      onEscapeSendMessage () {
        return this.onCloseSendMessage()
      },
      async handleSendMessage () {
        if (!this.$refs.firstBite.checked && !this.$refs.customMessage.checked) {
          throw new Error('No message type selected')
        }
        const { companyCode } = this.$route.params

        const sendMessageEndpoint = this.$refs.firstBite.checked
          ? `/friesland-campina/${companyCode}/triggers/send-first-bite-content/${this.currentJourneyFirstBite.id}`
          : `/friesland-campina/${companyCode}/triggers/send-custom-message/${this.$route.params.id}`
        const sendMessagePayload = this.$refs.firstBite.checked ? {} : { content: this.currentCustomMessage }

        this.sendMessageLoading = true
        try {
          const { data: messages } = await lepaya.post(sendMessageEndpoint, sendMessagePayload)
          this.messagesSent = messages.filter(message => message.status === 'success').length
          this.messagesFailed = messages.filter(message => message.status === 'failure').length

          this.sendMessageNotificationText = true
          this.onCloseSendMessage()
        } catch ({ response: { data: errors } }) {
          this.errors = errors
        }
        this.sendMessageLoading = false
      },
      refreshSendMessageButtonDisabled () {
        this.sendMessageButtonDisabled =
          (this.$refs.firstBite.checked && !this.currentJourneyFirstBite) || (this.$refs.customMessage.checked && !this.currentCustomMessage)
      },
      onChangeFirstBite () {
        if (this.$refs.customMessage.checked) {
          this.$refs.customMessage.checked = false
        }
        this.refreshSendMessageButtonDisabled()
      },
      onChangeCustomMessage () {
        if (this.$refs.firstBite.checked) {
          this.$refs.firstBite.checked = false
        }
        this.refreshSendMessageButtonDisabled()
      },
      isCustomMessageRequired () {
        return this.$refs.customMessage.checked
      },
      async onClickCreateSkillScan () {
        try {
          this.loading = true
          const { companyCode, id } = this.$route.params
          await this.createSkillScan({ companyCode, programId: id })
        } finally {
          this.loading = false
        }
      },
      openCommunicationTypeModal () {
        this.$store.dispatch(CommActions.OpenCommunicationTypeModal)
      },
      addNewCommunication (comm) {
        const { companyCode, id } = this.$route.params
        if (comm && comm.id) {
          this.$store.dispatch(CommActions.Edit, { companyCode, id, communicationId: comm.id })
        } else {
          this.$store.dispatch(CommActions.Open, { companyCode, id })
        }
      },
      onCloseEditCommunication () {
        this.$store.dispatch(CommActions.Close)
      },
      onCloseRemoveCommunication () {
        this.$store.dispatch(CommActions.CloseRemove)
      },
      confirmRemoveCommunication () {
        const { companyCode, id } = this.$route.params
        this.$store.dispatch(CommActions.ConfirmRemove, { companyCode, waveId: id, emailId: this.$store.state.comm.removingId })
      },
      showNewAddedParticipantsModal () {
        if (this.newRecipientsIds.length && this.sentLearnerCommunications().length) {
          this.$store.commit('comm/setLoading', false)
          this.showAddedParticipantsDialog = true
        }
      },
      sendEmailToNewParticipants () {
        const { companyCode, id } = this.$route.params
        if (this.sentLearnerCommunications()?.length) this.sendPAEmailToLearners(companyCode, id)
        if (this.sentManagerCommunications()?.length) this.sendPAEmailToManagers(companyCode, id)
        setTimeout(() => this.onCloseSendEmailToNewParticipants(), 5000)
      },
      sendPAEmailToManagers (companyCode, id) {
        const initialParticipantsManagerIds = this.currentParticipants
          .filter(participant => !this.newParticipantsIds.includes(participant.id) && participant.managerDetails)
          .map(item => item.managerDetails?.id)
        const newParticipantsManagerIds = this.currentParticipants
          .filter(
            item =>
              this.newParticipantsIds.includes(item.id) &&
              [ 'employee', 'content_manager' ].includes(item.role) &&
              item.managerDetails &&
              !initialParticipantsManagerIds.includes(item.managerDetails?.id)
          )
          .map(item => item.managerDetails?.id)
        const mostRecentSentCommunicationToManagers = this.sentManagerCommunications().reduce((newest, communication) => {
          return new Date(newest.updatedAt) > new Date(communication.updatedAt) ? newest : communication
        })
        const managersCommunicationId = mostRecentSentCommunicationToManagers.id
        if (newParticipantsManagerIds.length > 0) {
          this.$store.dispatch(CommActions.SendEmailToNewParticipants, {
            companyCode,
            id,
            communicationId: managersCommunicationId,
            newRecipientsIds: newParticipantsManagerIds
          })
        }
      },
      sendPAEmailToLearners (companyCode, id) {
        const mostRecentSentCommunicationToLearners = this.sentLearnerCommunications().reduce((newest, communication) => {
          return new Date(newest.updatedAt) > new Date(communication.updatedAt) ? newest : communication
        })
        const learnersCommunicationId = mostRecentSentCommunicationToLearners.id
        if (this.newRecipientsIds.length > 0) {
          this.$store.dispatch(CommActions.SendEmailToNewParticipants, {
            companyCode,
            id,
            communicationId: learnersCommunicationId,
            newRecipientsIds: this.newRecipientsIds
          })
        }
      },
      onCloseSendEmailToNewParticipants () {
        this.showAddedParticipantsDialog = false
      },
      sentLearnerCommunications () {
        const communications = this.$store.state.comm.communications
        return communications.filter(comm => {
          const sentStatus = comm.status === 'SENT'
          const learnersComm = comm.recipientsRoles.includes('employee')
          const learnerPAComm = comm.slug.toLowerCase().includes('-pa-')
          return sentStatus && learnersComm && learnerPAComm
        })
      },
      sentManagerCommunications () {
        const communications = this.$store.state.comm.communications
        return communications.filter(comm => {
          const sentStatus = comm.status === 'SENT'
          const managerComm = comm.recipientsRoles.includes('manager')
          const managerPAComm = comm.slug.toLowerCase().includes('-pa-manager') || comm.slug.toLowerCase().includes('-program-announcement-managers')
          return sentStatus && managerComm && managerPAComm
        })
      },
      async persistModule () {
        const { companyCode } = this.$route.params
        const languageCode = this.currentLanguage ? this.currentLanguage.code : 'en'
        const persistedJourneyId = await this.importContentStackModule({ uid: this.currentModule.id, companyCode, language: languageCode })
        lepaya
          .get(`/companies/${companyCode}/journeys`)
          .then(({ data: journeys }) => {
            this.journeys = journeys
            const persistedJourney = this.journeys.find(journey => journey.id === persistedJourneyId)
            this.waveJourneysAndWavelessJourneys.push(persistedJourney)
            this.selectedJourneys.push(persistedJourney)
          })
      },
      publishSelectedModules () {
        if (!this.journeysSelection.length) return
        const { companyCode, id, programCatalogueItemId } = this.$route.params
        const journeysToPublish = this.journeysSelection.map(item => ({ id: item.id }))
        this.publishModules({ moduleIds: journeysToPublish, companyCode }).then(() => {
          this.getProgramModules({ id, companyCode }).then(modules => {
            this.$router.push({
              name: 'editProgram',
              query: { journeysToPublish },
              params: { programCatalogueItemId, id, companyCode }
            })
            this.journeysSelection = []
            this.journeys = modules
            this.selectedJourneys = modules
          })
        })
      },
      onCloseRemoveParticipants () {
        this.$refs['modal-remove-participant'].hide()
      },
      handleDeleteParticipants () {
        if (this.program.isCalendarEnabled) {
          return this.$refs['modal-remove-participant'].show()
        }
        this.deleteParticipants()
      }
    },

    beforeRouteEnter: beforeRouteEnter,
    beforeRouteUpdate (to, from, next) {
      beforeRouteEnter(to, from, fn => Promise.resolve(fn(this)).then(() => next(), next))
    }
  })

  function beforeRouteEnter (to, from, next) {
    return methods.getData(to, from, next).then(result => {
      const [
        { data: programCatalogueItem },
        { data: program },
        { data: languages },
        { data: participants },
        { data: employees },
        certificate
      ] = result

      next(vm => {
        const { journeys } = program
        vm.programCatalogueItem = programCatalogueItem
        vm.program = program
        vm.originalProgram = clone(program)
        vm.journeys = journeys
        vm.selectedJourneys = journeys.filter(journey => program.journeys.find(programJourney => programJourney.id === journey.id))
        const selectedJourneysIds = vm.selectedJourneys.map(journey => journey.id)
        vm.journeyCsUids = getCsUidFromSelectedJourneys(selectedJourneysIds, program.journeys)
        vm.$store.commit('waves/setModulesCsUids', vm.journeyCsUids)
        vm.waveJourneysAndWavelessJourneys = getWaveJourneysAndOrphan(journeys, program)
        vm.assignedJourneyIds = map(prop('id'), vm.selectedJourneys)
        vm.employees = employees
        vm.languages = languages.map(lg => ({ code: lg.code, name: lg.englishName }))
        vm.certificate = (certificate && certificate.data) || {}
        vm.originalCertificate = clone((certificate && certificate.data) || {})
        vm.currentInviteSchedulingDays = daysOptions.find(option => option.code === program.inviteSchedulingDays) || daysOptions[0]
        vm.originalProgram.inviteSchedulingDays = program.inviteSchedulingDays || 0
        if (program.programAnnouncementStatus === 'SENT') daysOptions.splice(1, 1)

        if (vm.create) {
          Object.assign(vm.program, {
            name: programCatalogueItem.name
          })
          vm.currentLanguage = languages
        } else {
          vm.currentLanguage = vm.languages.find(language => language.code === program.languageCode)
          vm.participants = participants
          vm.currentParticipants = participants.filter(participant => employees.find(employee => employee.id === participant.id))
          vm.initialParticipants = clone(vm.currentParticipants)
          vm.initialJourneys = clone(vm.selectedJourneys)
          vm.journeysToSendMessage = vm.selectedJourneys.map(({ id, title: name }) => ({ id, name }))
          vm.currentJourneyFirstBite = vm.journeysToSendMessage[-1]
        }
      })
    })
  }
}

function getWaveJourneysAndOrphan (journeys, program) {
  const waveJourneys = journeys.filter(journey =>
    program.journeys.find(programJourney => programJourney.id === journey.id && program.name === journey.wave)
  )
  const wavelessJourneys = journeys.filter(journey => !journey.wave)

  return [ ...waveJourneys, ...wavelessJourneys ]
}

function getCsUidFromSelectedJourneys (journeyIds, journeys) {
  const sorter = (a, b) => journeyIds.indexOf(a.id) - journeyIds.indexOf(b.id)
  const sortedJourneyCsUids = journeys
    .sort(sorter)
    .filter(journey => journey.id)
    .map(journey => journey.contentStackUid)
  return sortedJourneyCsUids
}

function editProgramRouter (programCatalogueItemId, id, companyCode) {
  setTimeout(() => {
    this.$router.push({
      name: 'editProgram',
      params: { programCatalogueItemId, id, companyCode }
    })
  }, 1000)
}
