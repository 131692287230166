<template>
    <div class="no-comms-container">
        <Icon class="mail-icon" icon="envelope" />
        <p class="pill-info large-font">{{ text }}</p>
        <p v-show='showAdd' class="pill-info large-font">
            <Button class="large-font link-button" @click="openCommunicationTypeModal" type="link">Click here</Button> to add new communication</p>
    </div>
</template>

<script>
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import CommActions from '@/vuex/modules/comm/actions'

export default {
  name: 'NoCommunicationsMessage',
  components: { Icon, Button },
  methods: {
    openCommunicationTypeModal () {
      this.$store.dispatch(CommActions.OpenCommunicationTypeModal)
    }
  },
  props: {
    'show-add': {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      default: 'No scheduled communications for learners.'
    }
  }
}
</script>

<style scoped>
    .no-comms-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .link-button {
        vertical-align: baseline;
    }

    .large-font {
        font-size: 24px;
    }
    .mail-icon {
        font-size: 100px;
        color: #d0d4d9;
    }
</style>
