<template>
<div class="dropdown">
    <input v-if="!selectedItem" ref="dropdowninput" v-model.trim="inputValue" class="dropdown-input" type="text" :placeholder="placeholder" />
    <div v-else @click="resetSelection" class="dropdown-selected">
      {{ selectedItem[displayedProperty] }}
    </div>
    <div v-show="displayDropdown && !selectedItem" class="dropdown-list">
      <div @click="selectItem(option)" v-show="itemVisible(option)" v-for="(option, index) in options" :key="index" class="dropdown-item">
        {{ option[displayedProperty] }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    displayedProperty: {
      type: String,
      required: true
    },
    defaultValue: Object
  },
  data () {
    return {
      selectedItem: null,
      inputValue: ''
    }
  },
  computed: {
    displayDropdown () {
      return (this.inputValue && !this.defaultValue) || (this.defaultValue && this.inputValue.toLowerCase() !== this.defaultValue[this.displayedProperty].toLowerCase())
    }
  },
  created () {
    this.selectedItem = this.defaultValue
    this.setInitialValue()
  },
  methods: {
    resetSelection () {
      this.inputValue = ''
      this.selectedItem = null
      this.$nextTick(() => this.$refs.dropdowninput.focus())
      this.$emit('on-item-reset')
    },
    selectItem (theItem) {
      this.selectedItem = theItem
      this.inputValue = ''
      this.$emit('on-item-selected', theItem)
    },
    itemVisible (item) {
      if (this.inputValue.length >= 2) {
        const currentItem = item[this.displayedProperty].toLowerCase()
        const currentInput = this.inputValue.toLowerCase()
        return currentItem.includes(currentInput)
      }
      return false
    },
    setInitialValue () {
      if (this.defaultValue) {
        this.inputValue = this.defaultValue[this.displayedProperty]
      }
    }
  }
}
</script>

<style>
.dropdown{
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.dropdown-input, .dropdown-selected{
  width: 100%;
  padding: 10px 16px;
  border: 1px solid #c6c6d3;
  color: #a1a3a6;
  background: #fff;
  outline: none;
  border-radius: 4px;
  height: 40px;
  font-size: 12px;
}
.dropdown-input:focus, .dropdown-selected:hover{
  background: #fff;
  border-color: #c6c6d3;
}
.dropdown-input::placeholder{
  color: #a0a3a6;
  opacity: 1;
  text-indent: 2px;
}
.dropdown-selected{
  font-weight: bold;
  cursor: pointer;
}
.dropdown-list{
  position: relative;
  width: 100%;
  max-height: 500px;
  margin-top: 4px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.dropdown-item{
  display: flex;
  width: 100%;
  padding: 11px 16px;
  cursor: pointer;
  height: 40px;
  font-size: 12px;
}
.dropdown-item:hover{
  background: #edf2f7;
}

</style>
