<template>
  <input
  :value="inputValue"
  :disabled="disabled"
  @input="onInput"
  :class="classes"
  class="bg-transparent form-control rounded-0"
  :type="type"
  :placeholder="placeholder"/>
</template>

<script>
import { formGroupInput } from './helpers.js'

export default formGroupInput({
  props: {
    type: {
      default: 'text',
      type: String
    },
    value: [ String, Number ],
    placeholder: String,
    size: String,
    disabled: {
      default: false,
      type: Boolean
    }
  },

  methods: {
    onInput (event) {
      let value = event.target.value

      if (this.type === 'number') { value = Number(value) }

      this.value = value

      this.$emit('input', this.value)
    }
  },

  computed: {
    classes () {
      return {
        [`form-control-${this.size}`]: Boolean(this.size),
        'is-invalid': this.isInvalid
      }
    },
    inputValue () {
      return this.value
    }

  }
})
</script>
