<template>
  <span :style="{ 'color': color }"> {{ text }} </span>
</template>

<script>
export default {
  name: 'TableText',
  props: {
    color: {
      type: String,
      default: 'black'
    },
    text: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>
