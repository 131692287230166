const mixin = {
  methods: {
    sortChange (params) {
      this.tableData = this.tableData.sort((a, b) => {
        const sortEntry = Object.entries(params).filter(entry => !!entry[1]).pop()
        if (sortEntry || sortEntry?.length) {
          const [ sortingKey, sortDir ] = sortEntry
          const valueA = a[sortingKey] === null ? '' : a[sortingKey]?.toString().trim()
          const valueB = b[sortingKey] === null ? '' : b[sortingKey]?.toString().trim()

          if (sortDir === 'asc') {
            return valueA <= valueB ? -1 : 1
          } else {
            return valueA <= valueB ? 1 : -1
          }
        }
        return 0
      })
    }
  }
}

export default mixin
