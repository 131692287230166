export const ProgramsMutations = Object.freeze({
  setLanguages: 'setLanguages',
  setLoading: 'setLoading',
  setProgramsList: 'setProgramsList',
  setProgramTypes: 'setProgramTypes',
  setCompanyId: 'setCompanyId',
  setError: 'setError',
  setSelectedProgram: 'setSelectedProgram',
  setMsTeamsSyncStatus: 'setMsTeamsSyncStatus',
  updateProgram: 'updateProgram',
  setProgramModules: 'setProgramModules',
  setProgramParticipants: 'setProgramParticipants',
  setProgramEvents: 'setProgramEvents',
  addExpandedProgramId: 'addExpandedProgramId',
  removeExpandedProgramId: 'removeExpandedProgramId',
  setProgramsScrollTop: 'setProgramsScrollTop',
  addProgramsToList: 'addProgramsToList',
  setPage: 'setPage'
})

export const mutations = {
  [ProgramsMutations.setCompanyId]: (state, companyId) => {
    state.companyId = companyId
  },
  [ProgramsMutations.setLoading]: (state, isLoading) => {
    state.loading = !!isLoading
  },
  [ProgramsMutations.setProgramsList]: (state, programsList) => {
    state.programsList = programsList
  },
  [ProgramsMutations.setProgramTypes]: (state, programTypes) => {
    state.programTypes = programTypes
  },
  [ProgramsMutations.setError]: (state, errTxt) => {
    state.error = errTxt
  },
  [ProgramsMutations.setSelectedProgram]: (state, { id, val }) => {
    state.selectedPrograms = { ...state.selectedPrograms, [id]: val }
  },
  [ProgramsMutations.setMsTeamsSyncStatus]: (state, syncStatuses) => {
    state.msTeamsSyncStatuses = syncStatuses
  },
  [ProgramsMutations.setLanguages]: (state, languages) => {
    state.languages = languages
  },
  [ProgramsMutations.updateProgram]: (state, program) => {
    state.programsList = state.programsList.map(i => (i.id === program.id ? program : i))
  },
  [ProgramsMutations.setProgramModules]: (state, { programId, modules }) => {
    state.programModules = {
      ...state.programModules,
      [programId]: modules
    }
  },
  [ProgramsMutations.setProgramParticipants]: (state, { programId, participants }) => {
    state.programParticipants = {
      ...state.programParticipants,
      [programId]: participants
    }
  },
  [ProgramsMutations.setProgramEvents]: (state, { programId, events }) => {
    state.programEvents = {
      ...state.programEvents,
      [programId]: events
    }
  },
  [ProgramsMutations.addExpandedProgramId]: (state, id) => {
    state.expandedProgramIds = [ ...state.expandedProgramIds, id ]
  },
  [ProgramsMutations.removeExpandedProgramId]: (state, id) => {
    state.expandedProgramIds = state.expandedProgramIds.filter(i => i !== id)
  },
  [ProgramsMutations.setProgramsScrollTop]: (state, top) => {
    state.programsScrollTop = top
  },
  [ProgramsMutations.setPage]: (state, page) => {
    state.page = page
  },
  [ProgramsMutations.addProgramsToList]: (state, programs) => {
    state.programsList = [ ...state.programsList, ...programs ]
    if (programs.length < state.perPage) {
      state.page = -1
    }
  }
}
