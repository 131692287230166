<template>
  <Form>
    <div class="m-5 container bite-edit-form">
      <BiteFormCommonFields ref="commonFieldsForm" @change="setCommonValues"/>
      <div class="row">

        <div class="col-12">
          <FormGroup>
            <label>Bite Text</label>
            <TextArea
                v-model="bite.details.text"
                placeholder="Type the text here"
                :value="bite.details.text"
                :rows=3
            />
          </FormGroup>
        </div>

      </div>

    </div>

    <Button class="float-right btn-modal mx-auto" @click="submit" :disabled="!formValid">{{ isNewBite ? 'Create' : 'Save' }}</Button>
    <Button class="float-right btn-modal mx-auto" @click="closeModal" type="light">Cancel</Button>

  </Form>
</template>

<script>
import Button from '@/components/Button.vue'
import Form from '@/components/Form.vue'
import FormGroup from '@/components/FormGroup.vue'
import TextArea from '@/components/TextArea.vue'
import BiteFormCommonFields from '@/components/bites/forms/components/BiteFormCommonFields.vue'
import { cloneDeep, isEqual } from 'lodash'
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'TextForm',
  components: {
    FormGroup,
    Form,
    Button,
    BiteFormCommonFields,
    TextArea
  },
  data: () => ({
    bite: null,
    formValid: true
  }),
  created () {
    this.bite = cloneDeep(this.editingBite)
    this.$v.$touch()
  },
  computed: {
    ...mapGetters('bites', {
      editingBite: 'editingBite',
      isNewBite: 'isNewBite'
    }),
    biteBodyError () {
      if (this.$v.bite.details.text.$dirty && !this.$v.bite.details.text.required) return [ 'Please enter text...' ]
      return []
    }
  },
  methods: {
    setCommonValues (data) {
      this.bite.purpose = data.purpose
      this.bite.title = data.title
      this.bite.durationMins = data.durationMins
      this.bite.isOptional = data.isOptional
      this.bite.relativeDeadlineTime = data.relativeDeadlineTime
      this.bite.deadlineTime = moment(data.deadlineTime).valueOf()
      this.bite.stage = data.stage
      this.bite.orderNumber = data.orderNumber
    },
    checkFormValid () {
      if (this.isNewBite) {
        this.formValid = !this.$refs.commonFieldsForm.$v.$invalid
      } else {
        this.formValid = !this.$refs.commonFieldsForm.$v.$invalid && !this.$v.$invalid && !isEqual(this.editingBite, this.bite)
      }
    },
    closeModal () {
      this.$emit('onClose')
    },
    submit () {
      this.$refs.commonFieldsForm.$v.$touch()
      this.checkFormValid()

      if (this.formValid) {
        if (this.$refs.commonFieldsForm.biteDeadlineType === 'fixed') this.bite.relativeDeadlineTime = null
        if (this.$refs.commonFieldsForm.biteDeadlineType === 'relative') this.bite.deadlineTime = null

        this.$emit('onSave', this.bite)
      }
    }
  },
  validations: {
    bite: {
      details: {
        text: {
          required
        }
      }
    }
  },
  watch: {
    bite: {
      deep: true,
      immediate: false,
      handler () {
        this.checkFormValid()
      }
    }
  }
}
</script>

<style scoped>

</style>
