<template>
    <label class="label">
        <slot/><span v-if="$props.required" class="required-star">*</span>
    </label>
</template>

<script>
export default {
  name: 'Label',
  props: {
    required: { type: Boolean, default: false }
  }
}
</script>

<style scoped>
.label {
    font-weight: bold;
}

.required-star {
    font-weight: bold;
    color: #d92f48;
    margin-left: 5px;
}
</style>
