export default [
  {
    name: 'Zoom',
    code: 'zoom'
  },
  {
    name: 'MS Teams',
    code: 'ms_teams'
  },
  {
    name: 'Face to Face',
    code: 'face_to_face'
  },
  {
    name: 'Virtual',
    code: 'virtual'
  }
]
