<template src="./ListView.html"></template>

<script>
import Emphasis from './Emphasis.vue'
import IconText from './IconText.vue'

import { prop } from 'ramda'
import lepaya from '../http/lepaya.js'
import { queryResults } from './helpers.js'
import programSyncOperationStatus from './programSyncOperationStatus'

import ListView from './ListView.js'

const actions = [ {
  icon: 'rocket',
  href: 'programs/start'
} ]

const columns = [ {
  name: 'Company Program',
  prop: 'programName',
  sortable: true,
  component: () => Emphasis,
  props: ({ programName }) => ({ text: programName })
},
{
  name: 'Lepaya Program',
  prop: 'originalProgramName',
  sortable: true,
  component: () => Emphasis,
  props: ({ originalProgramName }) => ({ text: originalProgramName })
}, {
  name: 'Wave',
  prop: 'name',
  sortable: true,
  component: () => Emphasis,
  props: ({ name }) => ({ text: name })
}, {
  name: 'Language',
  prop: 'languageName',
  sortable: true,
  value: prop('languageName')
}, {
  name: 'Description',
  prop: 'description',
  sortable: true,
  value: prop('description')
}, {
  name: 'MS Teams Sync Status',
  prop: 'syncStatus',
  sortable: true,
  value: ({ syncStatus }) => {
    return programSyncOperationStatus[syncStatus] || syncStatus
  }
}, {
  name: 'Modules',
  sortable: true,
  prop: 'journeyCount',
  component: () => IconText,
  props: ({ journeyCount }) => ({
    icon: 'th-large',
    text: journeyCount
  })
}, {
  name: 'Participants',
  prop: 'learnerCount',
  sortable: true,
  component: () => IconText,
  props: ({ learnerCount }) => ({
    icon: 'users',
    text: learnerCount
  })
}, {
  name: 'Editors',
  prop: 'editorCount',
  sortable: true,
  component: () => IconText,
  props: ({ editorCount }) => ({
    icon: 'user-edit',
    text: editorCount
  })
} ]

export default ListView({
  data: () => ({
    showSyncModal: false,
    actions,
    table: {
      actions,
      columns,
      onClick (program) {
        this.$router.push({
          name: 'editProgram',
          params: program
        })
      }
    }
  }),

  methods: {
    closeModal () {
      this.showSyncModal = false
    },
    syncToMsTeams () {
      this.error = null
      if (!this.selected.length) return

      const { companyCode } = this.$route.params

      lepaya.post(`/msTeams/sync-ongoing-programs/${companyCode}`, { ongoingProgramIds: this.selected.map(item => String(item.id)) })
        .then(() => {
          this.selected = []
          this.showSyncModal = true
        })
        .catch(e => {
          this.error = e?.response?.data?.detail ? e.response.data.detail : e
        })
    },
    async getRecords (to) {
      const companyPrograms = await query(lepaya.get(`/companies/${to.params.companyCode}/programs`), to.query)
      const programsSyncStatuses = null

      await lepaya.get(`/msTeams/sync-status/${to.params.companyCode}`)
        .then(response => {
          return response.data.forEach(item => {
            programsSyncStatuses[item.ongoingProgramId] = item
          })
        }).catch(e => {
          this.error = e?.response?.data?.detail ? e.response.data.detail : e
          return null
        })
      const response = companyPrograms.map(companyProgram => {
        const syncStatusOngoingProgramChannel = programsSyncStatuses[companyProgram.id]
        const syncStatus = syncStatusOngoingProgramChannel ? syncStatusOngoingProgramChannel.sync_status : null
        const lastSyncTime = syncStatusOngoingProgramChannel ? syncStatusOngoingProgramChannel.last_sync_time : null

        return {
          ...companyProgram,
          syncStatus,
          lastSyncTime
        }
      })

      return response
    }
  }
})

const query = queryResults({
  searchable: [ 'name' ],
  orderByColumn: 'createdAt',
  options: {
    threshold: 0.3,
    minMatchCharLength: 1,
    distance: 250
  }
})
</script>
