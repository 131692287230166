import { render, staticRenderFns } from "./ExternalDocumentForm.vue?vue&type=template&id=4613506c&scoped=true&"
import script from "./ExternalDocumentForm.vue?vue&type=script&lang=js&"
export * from "./ExternalDocumentForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4613506c",
  null
  
)

export default component.exports