<template>
  <button
    type="button"
    v-on:click="onButtonClick"
    class="btn rounded-0"
    v-bind:class="classes"
    :disabled="disabled"
    :hidden="hidden"
  >
    <b-spinner v-if="loading" class="mr-2" style="width: 20px; height: 20px;opacity:1"/>
    <template v-else>
      <slot></slot>{{text}}
    </template>
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'primary'
    },
    middle: Boolean,
    onClick: {
      type: Function,
      default: () => {}
    },
    href: String,
    size: String,
    text: String,
    disabled: {
      type: Boolean,
      default: () => false
    },
    hidden: {
      type: Boolean,
      default: () => false
    }
  },

  computed: {
    classes () {
      return {
        [`btn-${this.type || 'secondary'}`]: true,
        [`btn-${this.size}`]: Boolean(this.size),
        'vertical-align': this.middle
      }
    }
  },

  methods: {
    onButtonClick (event) {
      this.href && this.$router.push(this.href)
      this.$emit('click')
      this.onClick(event)
    }
  }

}
</script>

<style>
.btn-modal {
  padding: 1.5rem 5rem;
}

.btn-lepaya-primary {
  background-color: #56d5ec;
  color: white;
}

.btn-outline-primary:hover {
  color: white;
}

.btn-lepaya-danger {
  background-color: #ed7e85;
  color: white;
}

.btn-lepaya-light {
  color: #0dc2e3;
  background-color: white;
  border-color: white;
}
</style>
