<template>
  <img :alt="title" class="rounded-circle border" :title="title" :src="src"/>
</template>

<script>
export default {
  props: {
    src: String,
    title: {
      type: String,
      default: 'Avatar Image'
    }
  }
}
</script>
