<template>
  <span class="name-edit" v-if="isEdit">
   <b-form-input v-model="value" placeholder="Program name"
     @keydown.esc="cancelEdit"
     @keydown.enter="submitEdit"
   />
    <span v-if="isLoading" class="icon-controls">
      <b-spinner small />
    </span>
    <span v-else class="icon-controls">
      <IconCheck class="icon icon-check"  :class="{ 'icon-check-valid': isValid }" @click="submitEdit"/>
      <IconCross class="icon icon-cross" @click="cancelEdit"/>
    </span>
  </span>
  <span class="name-display" @mouseover="showPencil = true" @mouseleave="showPencil = false" v-else>
    <span>{{ value }}</span>
    <IconPencil v-if='enabled' class="icon icon-pencil" :class="{ 'icon-visible': showPencil }" @click="isEdit = true"/>
  </span>
</template>

<script>
import IconPencil from '@/assets/svg/pencil.svg'
import IconCheck from '@/assets/svg/check.svg'
import IconCross from '@/assets/svg/cross.svg'
import { mapActions } from 'vuex'
import { PROGRAMS_NAMESPACE } from '@/vuex/modules/programs/programs.state'
import { ProgramsActions } from '@/vuex/modules/programs/actions'

export default {
  name: 'ProgramName',
  components: { IconCheck, IconCross, IconPencil },
  props: {
    name: { type: String },
    program: { type: Object },
    enabled: { type: Boolean, default: true }
  },
  data: () => ({
    isEdit: false,
    isEmpty: false,
    isLoading: false,
    showPencil: false,
    value: ''
  }),
  created () {
    this.value = this.$props.name
    if (this.value === null) {
      this.isEmpty = true
    }
  },
  methods: {
    ...mapActions(PROGRAMS_NAMESPACE, {
      updateProgramName: ProgramsActions.updateProgramName
    }),
    cancelEdit () {
      this.value = this.$props.name
      this.isEdit = false
    },
    async submitEdit () {
      if (!this.isValid) { return }

      if (this.value !== this.$props.name) {
        const { companyCode } = this.$route.params
        this.isLoading = true
        try {
          await this.updateProgramName({ companyCode, program: this.$props.program, newName: this.value })
          this.isEdit = false
        } catch (e) {
          this.value = this.$props.name
        } finally {
          this.isLoading = false
        }
      } else {
        this.cancelEdit()
      }
    }
  },
  computed: {
    isValid () {
      return this.value.toString().length >= 5
    }
  }
}
</script>

<style scoped>
.name-display {
  display: flex;
  align-items: baseline;
  justify-content: center;
}
.name-edit {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.icon-controls {
  display: flex;
  align-items: flex-end;
}

.icon {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin-left:8px;
  cursor: pointer;
}

svg.icon-pencil > path {
  fill: none !important;
}
svg.icon-visible > path {
  fill: #555 !important;
}

svg.icon-check > path {
  fill: #555 !important;
}

svg.icon-check-valid > path {
  fill: seagreen !important;
}

svg.icon-cross > path {
  fill: #b61212 !important;
}
</style>
