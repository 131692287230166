import Badge from '@/components/Badge.vue'
import Button from '@/components/Button.vue'
import BiteActions from '@/components/bites/BiteActions.vue'
import TableText from '@/components/common/TableText.vue'
import lepaya from '@/http/lepaya'
import moment from 'moment'

export default journeyId => ({
  rowNumber: {
    name: '#',
    value: ({ orderNumber }) => (Number.isInteger(orderNumber) ? orderNumber : '')
  },
  biteTitle: {
    name: 'Bite Title',
    value: ({ title }) => title
  },
  stage: {
    name: 'Stage',
    component: () => TableText,
    props: ({ stage, isOptional }) => ({
      text: stage || (isOptional ? 'optional' : 'unknown'),
      color: stage ? 'black' : isOptional ? 'black' : 'gray'
    })
  },
  learningTime: {
    name: 'Learning Time',
    component: () => TableText,
    props: ({ durationMins }) => ({
      text: durationMins === 1 ? `${durationMins} ${'minute'}` : durationMins > 1 ? `${durationMins} ${'minutes'}` : 'Not set',
      color: durationMins ? 'black' : 'gray'
    })
  },
  deadline: {
    name: 'Deadline',
    component: () => TableText,
    props: ({ deadlineTime, relativeDeadlineTime }) => ({
      text: deadlineTime
        ? moment(deadlineTime).format('dddd DD-MM-YYYY')
        : relativeDeadlineTime
          ? relativeDeadlineTime + `${relativeDeadlineTime > 1 ? ' days' : ' day'}`
          : 'Date not set',
      color: deadlineTime || relativeDeadlineTime ? 'black' : 'gray'
    })
  },
  status: {
    name: 'Status',
    component: () => Badge,
    props: ({ status }) => ({
      text: status,
      class: { 'text-uppercase': true },
      type: status === 'draft' ? 'secondary' : 'success'
    })
  },
  contentStackUid: {
    name: 'CS UID',
    component: () => Button,
    props: ({ contentStackUid }) => ({
      hidden: !contentStackUid,
      text: 'Open on CS',
      onClick: () => {
        if (contentStackUid) {
          lepaya.get(`/contentStack/get-cs-url/${journeyId}/${contentStackUid}`).then(({ data }) => {
            window.open(data.url)
          })
        }
      }
    })
  },
  actions: {
    name: 'Actions',
    component: () => BiteActions,
    props: bite => ({
      bite
    })
  }
})
