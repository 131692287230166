<template>
  <div>
    <a :href="msTeamsSyncStatusesConfluencePageUrl" target='_blank' class="info-link" v-b-tooltip.hover title="More info">{{ syncStatus.status }}
      <IconInfo />
    </a>
    <p v-if="syncStatus.reason">
      {{ syncStatus.reason }}
    </p>
  </div>
</template>

<script>
import IconInfo from '@/assets/svg/info.svg'
export default {
  name: 'WaveMSTeamsSyncStatusCell',
  components: {
    IconInfo
  },
  props: {
    syncStatus: {
      type: Object,
      required: true
    }
  },
  computed: {
    msTeamsSyncStatusesConfluencePageUrl () {
      return 'https://lepaya-amsterdam.atlassian.net/l/cp/0Ft2gj6D'
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/colors";

.info-link {
  color: $actionBlue;
  cursor: pointer;
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
}
.info-link>svg {
  color: #000000d9;
}
</style>
