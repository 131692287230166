<template>
   <div class="confirmation-info_Field">
     <span class="confirmation-info_Field-left">{{label}}:</span>
     <span class="confirmation-info_Field-right">{{value}}</span>
   </div>
</template>

<script>
export default {
  name: 'ConfirmationFields',
  props: [ 'label', 'value' ]
}
</script>

<style scoped>
.confirmation-info_Field-left {
  display: inline-block;
  width: 50%;
  max-width: 200px;
  min-width: 120px;
  font-weight: bold;
  line-height: 2.2rem;
}
</style>
