<template>
    <div class="stepper-wrapper">
        <div class="stepper-container">
            <StepLink :step="WizardSteps.SETUP" >1. Setup</StepLink>
            <Icon class="stepper-icon" icon="chevron-right"/>
            <StepLink :step="WizardSteps.CUSTOMIZATION">2. Customization</StepLink>
            <Icon class="stepper-icon" icon="chevron-right"/>
            <StepLink :step="WizardSteps.RECIPIENTS">3. Recipients</StepLink>
            <Icon class="stepper-icon" icon="chevron-right"/>
            <StepLink :step="WizardSteps.SCHEDULING">4. Scheduling</StepLink>
            <Icon class="stepper-icon" icon="chevron-right"/>
            <StepLink :step="WizardSteps.EDITING">5. Editing</StepLink>
            <Icon class="stepper-icon" icon="chevron-right"/>
            <StepLink :step="WizardSteps.CONFIRMATION">6. Confirmation</StepLink>
        </div>
    </div>
</template>

<script>
import CommActions from '../../vuex/modules/comm/actions'
import { WizardSteps } from '@/vuex/modules/comm/state'
import StepLink from './StepLink'
import Icon from '@/components/Icon.vue'

export default {
  name: 'Stepper',
  components: { StepLink, Icon },
  data: () => ({
    CommActions,
    WizardSteps
  })
}

</script>

<style scoped>

    .stepper-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #f0f0f5;
    }

    .stepper-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        height: 60px;
        width: 60%;
    }

    .stepper-icon {
        color: #a7b0bb;
    }
</style>
