<template>
    <StepContainer>
        <Loading />
    </StepContainer>
</template>

<script>
import StepContainer from '../StepContainer'
import { validationMixin } from 'vuelidate'
import Loading from './Loading'

export default {
  name: 'LoadingStep',
  mixins: [ validationMixin ],
  components: { StepContainer, Loading }
}
</script>

<style scoped>
</style>
