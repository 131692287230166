<template>
  <Modal>
    <template v-slot:header>
      <div class="classroom-title">{{editing ? 'Update Classroom Session' : 'Add Classroom Session'}}</div>
    </template>
    <NotificationPanel
      v-if="notificationPanelErrors"
      :open="notificationPanelErrors"
      v-for="(response, index) in notificationPanelErrors"
      :key="index"
      icon="exclamation-circle"
      :classes="{ 'bg-danger': true }"
      :expire-time="20000"
      :message="response">
    </NotificationPanel>

    <div class="row my-3 mx-2 fixed-modal-content">
      <div class="col-sm-6 row">
        <Form class="form col-md-12">
          <div class="row">
            <div class="col-md-12">
              <div class="form_group">
                <Label value="Type" />
                <div class="row">
                  <div class="col-sm-6">
                    <select @input="setClassroomType($event.target.value)" :value="classroomTypeName" :disabled="disableClassroomType"
                            class="after generic-input select d-block w-100 bg-transparent">
                      <option v-for="option in filteredTypeOptions" :key="option.code" :value="option.name" selected>{{option.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form_group">
                <Label value="Trainer" />
                <div class="row">
                  <div class="col-sm-6">
                <SearchDropdown @on-item-selected="setClassroomTrainerValue($event)"
                                @on-item-reset="resetClassroomTrainerValue()" placeholder="Find a trainer"
                :options="formatSupportEmployees(supportEmployees)" displayedProperty="name" :defaultValue="initialTrainer" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class='form_group'>
                  <Label important value="Date" />
                  <FormGroup :errors="errors.startDate" >
                    <Datepicker
                    @setDate="setDate"
                    :value="classroom.startDate"
                    :disabledDates="disabledDates">
                    </Datepicker>
                  </FormGroup>
              </div>

              <div class="form_group">
                <Label important value="Time" />
                <div class="row">
                  <div class="col-sm-6">
                    <FormGroup :errors="errors.startTime" >
                      <select @input="setStartTime($event.target.value)" :value="classroom.startTime" class="after generic-input select d-block w-100 bg-transparent" placeholder="From">
                        <option disabled :value="null">From</option>
                        <option v-for="option in options" :key="option" :value="option" selected>{{option}}</option>
                      </select>
                    </FormGroup>
                  </div>
                  <div class="col-sm-6">
                    <FormGroup :errors="errors.endTime" >
                      <select @input="setEndTime($event.target.value)" :value="classroom.endTime" class="generic-input select d-block w-100 bg-transparent" placeholder="From">
                        <option style="color: #ccc;" disabled :value="null">To</option>
                        <option v-for="option in options" :key="option" :value="option" selected>{{option}}</option>
                      </select>
                    </FormGroup>
                  </div>
                </div>
              </div>
              <div class="form_group">
                <Label value="Timezone" />
                <div class="row">
                  <div class="col">
                    <select @input="setTimeZone($event.target.value)" :value="classroom.timeZone"
                            class="after generic-input select d-block w-100 bg-transparent" placeholder="Timezone">
                      <option v-for="timezone in availableTimezones" :key="timezone.code" :value="timezone.code"
                              selected>{{ timezone.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="row" v-if="showLocationForm">
            <div class="col-md-12">
              <div class="form_group">
                <Label value="Location" />
                <FormGroup class="mb-4" :errors="errors['location.name']">
                  <input @input="setLocationName($event.target.value)" :value="classroom.location.name" class="d-block w-100 underline" placeholder="Location Name" type="text">
                </FormGroup>
                <FormGroup class="mb-4" :errors="errors['location.room']">
                  <input @input="setLocationRoom($event.target.value)" :value="classroom.location.room" class="d-block w-100 underline" placeholder="Room" type="text">
                </FormGroup>
                <FormGroup class="mb-4" :errors="errors['location.address']">
                  <input @input="setLocationAddress($event.target.value)" :value="classroom.location.address" class="d-block w-100 underline" placeholder="Address" type="text">
                </FormGroup>
              </div>

              <div class="form_group">
                <Label value="Additional info" />
                <textarea maxlength="172" v-model="classroom.additionalInfo" class="d-block w-100 underline textarea" />
                <div class="small">{{charLeft}} characters left</div>
              </div>
            </div>
          </div>
          <FormGroup :errors="errors.calendarInviteDescription">
                <Label value="Invitation Text" />
                <textarea :maxlength="maxLength" rows="10" v-model="classroom.calendarInviteDescription" class="d-block w-100 underline textarea" />
                <div class="small">{{invitationDescriptionCharLeft}} characters left</div>
          </FormGroup>

        </Form>

      </div>
  </div>

  <div class="row p-0">
    <div class="col-md-12">
        <Pill class="pill-notification calendar-notification">
          <Icon style="color: #ffbb4d;" icon="exclamation-circle"/>{{calendarNotificationText}}
        </Pill>
      <div class="d-flex flex-row-reverse shadow-lg w-100">
          <Button
            size='lg'
            type="primary"
            :disabled="buttonDisabled"
            class="panel-buttons font-weight-bold p-4"
            @click="submitEvent"
          >{{editing ? 'Update Classroom' : 'Add new Classroom' }}
          </Button>
          <Button
            type="link"
            size="lg"
            class="panel-buttons font-weight-bold p-4"
            @click="$router.go(-1)"
          >
            Cancel
          </Button>
      </div>
    </div>
  </div>
    <OverlayingSpinner v-if="loading"/>
  </Modal>
</template>

<script>
import hideLocationFormClassroomTypes from '@/components/classroom/models/hideLocationFormClassroomTypes'
import OverlayingSpinner from '@/components/common/OverlayingSpinner.vue'
import moment from 'moment'
import { equals } from 'ramda'
import VueSelect from 'vue-select'
import { mapActions } from 'vuex'
import Button from '../components/Button.vue'
import Checkbox from '../components/Checkbox.vue'
import Datepicker from '../components/Datepicker.vue'
import Form from '../components/Form.vue'
import FormGroup from '../components/FormGroup.vue'
import Icon from '../components/Icon.vue'
import Label from '../components/Label.vue'
import Modal from '../components/Modal.vue'
import NotificationPanel from '../components/NotificationPanel.vue'
import Pill from '../components/Pill.vue'
import Radio from '../components/Radio.vue'
import SearchDropdown from '../components/SearchDropdown.vue'
import classroomTypeOptions from '../components/classroom/models/classroomTypes'
import store from './AddClassroomModal.store'

export default store({
  components: {
    Modal,
    Form,
    Label,
    Button,
    Radio,
    Checkbox,
    Icon,
    Datepicker,
    FormGroup,
    VueSelect,
    Pill,
    NotificationPanel,
    OverlayingSpinner,
    SearchDropdown
  },
  data: () => ({
    options: [ ...Array(24).keys() ].flatMap(hour => [ '00', '15', '30', '45' ].map(quarter => ('0' + hour).slice(-2) + ':' + quarter)),
    disabledDates: {
      to: moment().subtract(1, 'days').toDate()
    },
    availableTimezones: moment.tz.names().map(t => ({
      code: t,
      name: `(GMT${moment.tz(t).format('Z')}) ${t}`
    })),
    classroomTypeOptions,
    loading: false,
    searchDropdownSelection: {},
    maxLength: 2000
  }),
  created () {
    this.availableTimezones.sort((a, b) => a.name >= b.name ? 1 : -1)
  },
  computed: {
    filteredTypeOptions () {
      if (this.classroom.location.classroomType === 'ms_teams' && this.editing) {
        return this.classroomTypeOptions.filter(classroomType => classroomType.code === 'ms_teams' || classroomType.code === 'virtual')
      }
      if (this.classroom.location.classroomType === 'virtual' && this.editing) {
        return this.classroomTypeOptions.filter(classroomType => classroomType.code !== 'zoom')
      }
      return this.classroomTypeOptions
    },
    charLeft () {
      return 172 - this.classroom.additionalInfo.length
    },
    invitationDescriptionCharLeft () {
      if (this.classroom.calendarInviteDescription) return this.maxLength - this.classroom.calendarInviteDescription.length
      return this.maxLength
    },
    buttonDisabled () {
      return equals(this.classroom, this.initialClassroom)
    },
    calendarNotificationText () {
      return this.classroomParams.isCalendarEnabled ? 'Calendar invites will be sent or updated automatically. Disable this function on the wave page' : 'Calendar invites will not be sent automatically. Enable this function on wave page.'
    },
    classroomTypeName () {
      if (this.classroom.location.classroomType) {
        return this.classroomTypeOptions.find(classroomType => classroomType.code === this.classroom.location.classroomType).name
      }
      return null
    },
    notificationPanelErrors () {
      if (this.errors.ZoomApiError) return this.errors.ZoomApiError
      if (this.errors.ZoomMeetingSlotUnavailable) return this.errors.ZoomMeetingSlotUnavailable
      if (this.errors.MsGraphApiError) return this.errors.MsGraphApiError
      if (this.errors.MsServiceError) return this.errors.MsServiceError
      if (this.errors.Auth0Error) return this.errors.Auth0Error
      if (this.errors.MSteamMeetingSlotUnavailable) return this.errors.MSteamMeetingSlotUnavailable
      return null
    },
    showLocationForm () {
      return !hideLocationFormClassroomTypes.includes(this.classroom.location.classroomType)
    },
    initialTrainer () {
      if (this.classroom.trainer) {
        return { id: this.classroom.trainer.id, name: `${this.classroom.trainer.firstName} ${this.classroom.trainer.lastName}` }
      }
      return null
    },
    disableClassroomType () {
      return this.editing && (this.classroom.location.classroomType === 'zoom')
    }
  },
  methods: {
    ...mapActions('bites', {
      getBites: 'getBites'
    }),
    displayAvatar (avatar) {
      return avatar || 'person.png'
    },
    setDate (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD')
      this.setStartDate(formattedDate)
      this.setEndDate(formattedDate)
    },
    equals: (a, b) => equals(a, b),

    submitEvent () {
      const { id, companyCode } = this.$route.params
      this.loading = true
      this.persistClassroom(this.classroom)
        .then(() => {
          this.getBites({ id, companyCode })
          this.$router.push({
            name: 'editJourney',
            params: {
              id
            },
            query: {
              invitesSuccess: true
            }
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    setClassroomType (type) {
      const classroomType = this.classroomTypeOptions.find(classroomType => classroomType.name === type)
      this.setLocationType(classroomType.code)
    },
    setClassroomTrainerValue (trainer) {
      if (trainer) {
        this.changeClassroomTrainer({ trainerId: trainer.id })
      }
    },
    resetClassroomTrainerValue () {
      this.resetClassroomTrainer()
    },
    formatSupportEmployees (supportEmployees) {
      let formattedSupportEmployees = []
      if (supportEmployees) {
        formattedSupportEmployees = supportEmployees.map(employee => ({
          id: employee.id,
          name: `${employee.firstName} ${employee.lastName}`
        }))
      }

      return formattedSupportEmployees
    }
  }
})
</script>

<style scoped>
  select {
    color: #A1A3A6;
    position: relative;
    height: 40px;
  }

  .classroom-title {
    padding: 20px 30px;
  }

  .capitalized {
    text-transform: capitalize;
  }

  ::placeholder {
    color: #a0a3a6;
    opacity: 1;
    text-indent: 2px;
  }

  select::after {
    content: 'hello world';
    position: absolute;
    left: 0;
    top: 10px;
    display: block;
  }

  .form {
    min-height: 50vh;
  }

  .form_group {
    margin-bottom: 30px;
  }

  .underline {
    border: none;
    outline: none;
    border-bottom: 1px solid #c6c6d3;
    padding-bottom: 10px;
  }

  .textarea {
    resize: none;
  }

  .small {
    font-size: 12px;
    color: #a6a6be;
    padding: 5px 0
  }

  .trainer-container {
    background-color: #fafafd;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(198, 198, 211, 0.4);
  }

  .trainer-container > .content {
    padding: 32px 20px 0;
  }

  .role {
    object-fit: contain;
    border-radius: 10px;
    background-color: #EDEFF2;
    color: #ACB9C7;
    font-size: 11px;
    padding: 5px 10px;
    text-transform: lowercase;
  }

  .button {
    outline: none;
    font-size: 16px;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(21, 39, 70, 0.05);
    border: solid 1px #ebedf0;
    color: #8c8ca0;
    padding: 10px 15px;
  }

  .button:hover {
    background-color: #fafafa;
  }

  .button.delete {
    color: #ee0264;
  }

  .rounded-button {
    outline: none;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: #56d5ec;
  }

  .save-trainer, .cancel-trainer {
    outline: none;
    border: none;
    padding: 20px 50px;
    font-size: 13px;
  }

  .save-trainer {
    background-color: #56d5ec;
    color: #fff;
  }

  .save-trainer:disabled {
    background-color: #DEDEE9;
  }

  .cancel-trainer {
    color: #56d5ec;
    background-color: transparent;
  }

  .margin-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .person {
    width: 40px;
    height: 40px;
  }

  .panel-buttons {
    width: 17%;
    font-size: 13px;
  }

  .sm-person {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .menu-option {
    border-bottom: 1px solid #d0d3da;
    padding: 10px 20px;
  }

  .check {
    font-size: 10px;
    font-weight: bold;
    color: #55c968;
  }

  .calendar-notification {
    color: #2c3239; padding:3px
  }
</style>
