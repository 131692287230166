<template>
  <span :class="classes"><strong><slot></slot>{{text}}</strong></span>
</template>

<script>
export default {
  props: {
    text: String,
    type: {
      type: String,
      default: 'success'
    }
  },
  computed: {
    classes () {
      return {
        badge: true,
        [`badge-${this.type}`]: true
      }
    }
  }
}
</script>
