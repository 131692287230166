<template>
  <form v-on:submit="onEnterKey($event)">
    <input v-on:input="onChange(query)" v-model="query" class="form-control form-control-lg bg-transparent border-0" type="search" placeholder="Search">
  </form>
</template>

<script>
import { isEmpty, isNil } from 'ramda'
import debounce from 'lodash.debounce'

export default {
  data: function data () {
    return {
      query: this.$router.currentRoute.query.query
    }
  },

  created () {
    const removeGuard = this.$router.afterEach((to) => {
      this.query = to.query.query

      removeGuard()
    })
  },

  props: {
    onSearch: {
      type: Function,
      default: onSearch
    }
  },

  methods: {
    onChange: debounce(function (query) {
      this.onSearch(query)
      if (!query) {
        // need a little delay to make sure the query is cleared
        setTimeout(() => {
          window.location.reload()
        }, 100)
      }
    }, 200),
    onEnterKey (event) {
      if (event) { event.preventDefault() }
      window.location.reload()
    }
  }

}

function onSearch (string) {
  const query = {
    ...this.$router.currentRoute.query
  }

  delete query.query

  if (!isEmpty(string) && !isNil(string)) {
    query.query = string
  }

  const pushOrReplace = (this.$router.currentRoute.query.query
    ? this.$router.replace
    : this.$router.push).bind(this.$router)

  pushOrReplace({
    ...this.$router.currentRoute,
    query
  })
}
</script>
