<template>
    <div class="loading-overlay" >
            <b-spinner style="width: 150px; height: 150px;opacity:1" variant="primary"/>
    </div>
</template>

<script>
import CommActions from '../../../vuex/modules/comm/actions'

export default {
  name: 'Loading',
  data: () => ({ CommActions })
}
</script>

<style scoped>
    .loading-overlay {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.5;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #eee;
        z-index: 3
    }
</style>
