<template>
  <span :class="classes"><Icon :icon="icon" class="mr-2"/>{{text}}<slot></slot></span>
</template>

<script>
import Icon from './Icon.vue'

export default {
  props: {
    icon: String,
    text: [ String, Number ],
    parentStyle: Boolean,
    type: {
      type: String,
      default: 'primary'
    }
  },

  components: {
    Icon
  },

  computed: {
    classes () {
      return this.parentStyle ? {} : {
        [`text-${this.type}`]: true,
        'text-nowrap': true
      }
    }
  }
}

</script>

<style scoped>
  svg {
    font-size: 2em;
    margin-bottom: -0.2em;
    width: 1em !important;
  }
</style>
