<template>
    <StepContainer>
        <StepForm>
            <FormGroup>
            <Pill class="pill-notification-blue" style="color: #1A6BC6;">
                <Icon style="color: #1A6BC6;" icon="info-circle" />
                Wave language is {{waveLanguage.name}}
            </Pill>
            </FormGroup>

            <FormGroup>
                <Label :required="true">Template</Label>
                <Select :value="template" :options="templateOptions" @input="setTemplateSelection" placeholder="Select Template"/>
                <div class="template-import" v-if="$store.state.comm.data.templateSlug">
                    <Button @click="reimportTemplate" type="link" text="Import template" />
                    <span class="template-import-time">Last imported at {{ moment($store.state.comm.data.templateImportTime).format('DD.MM.YYYY HH:mm:ss')}}</span>
                </div>
              <div class="template-import">
                <Button @click="fetchTemplates" type="link" text="Fetch templates" />
              </div>
            </FormGroup>

            <FormGroup
                    :errors="$v.subjectLine.$dirty && !$v.subjectLine.required ? ['Subject Line cannot be empty'] : []"
            >
                <Label :required="true">Subject Line</Label>
                <Input :value="subjectLine" @input="setSubjectLine" size="lg"/>
                <Pill class="pill-info">Write a subject line that clearly describes your email content. It will be visible in your recipient's inbox and is the first content they will see.</Pill>
            </FormGroup>

            <FormGroup>
                <Label>Preview Text</Label>
                <Input :value="previewText" @input="setPreviewText" size="lg"/>
                <Pill class="pill-info">Write a short text (about 35 characters) that gives an overview of the content of your email. This will significantly increase your opening rate.</Pill>
            </FormGroup>

            <FormGroup
                :errors="($v.senderEmail.$dirty && (!$v.senderEmail.required  || !$v.senderEmail.email)) ? ['Sender Email must be a valid email'] : []"
            >
                <Label :required="true">Sender Email</Label>
                <Input :value="senderEmail" @input="setSenderEmail" size="lg"/>
            </FormGroup>

            <FormGroup>
                <Label :required="true">From Name</Label>
                <Input :value="fromName" @input="setFromName" size="lg"/>
            </FormGroup>
        </StepForm>
        <StepControls :nextAvailable="$store.getters['comm/lastAvailableStep'] > WizardSteps.SETUP" @cancel="$store.dispatch(CommActions.Close)" @next="nextStep"/>
    </StepContainer>
</template>

<script>
import Button from '@/components/Button.vue'
import FormGroup from '@/components/FormGroup.vue'
import Icon from '@/components/Icon.vue'
import Input from '@/components/Input.vue'
import Pill from '@/components/Pill.vue'
import Select from '@/components/Select.vue'
import { WizardSteps } from '@/vuex/modules/comm/state'
import moment from 'moment-timezone'
import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import CommActions from '../../../vuex/modules/comm/actions'
import StepContainer from '../StepContainer'
import StepControls from '../StepControls.vue'
import StepForm from '../StepForm.vue'
import Label from '../forms/Label.vue'

export default {
  name: 'Setup',
  mixins: [ validationMixin ],
  components: { StepForm, StepControls, Select, StepContainer, Label, Pill, FormGroup, Input, Button, Icon },
  props: {
    availableLanguages: { type: Array }
  },
  data: () => ({ CommActions, WizardSteps, moment }),
  computed: {
    ...mapGetters({
      filteredTemplates: 'comm/filteredTemplates'
    }),
    waveLanguage () {
      const waveLang = this.$store.state.comm.data.waveLanguage
      this.$store.dispatch(CommActions.SetNewTemplateFilter, waveLang.code)
      return waveLang
    },
    subjectLine () { return this.$store.state.comm.data.subjectLine },
    previewText () { return this.$store.state.comm.data.previewText },
    senderEmail () { return this.$store.state.comm.data.senderEmail },
    fromName () { return this.$store.state.comm.data.fromName },
    templateOptions () {
      return this.filteredTemplates.map(t => ({ code: t.slug, name: t.name }))
    },
    template () {
      if (this.$store.state.comm.selectedTemplate) {
        return {
          code: this.$store.state.comm.selectedTemplate.slug,
          name: this.$store.state.comm.selectedTemplate.name
        }
      }
      return undefined
    }
  },
  validations: {
    subjectLine: { required },
    senderEmail: { required, email }
  },
  methods: {
    setTemplateSelection (option) {
      this.setSubjectLine(null)
      this.$store.dispatch(CommActions.SelectTemplate, { slug: option.code, name: option.name })
    },
    setSubjectLine (line) {
      this.$store.dispatch(CommActions.SetSubjectLine, line)
      this.$v.subjectLine.$touch()
    },
    setPreviewText (text) {
      this.$store.dispatch(CommActions.SetPreviewText, text)
    },
    setSenderEmail (mail) {
      this.$store.dispatch(CommActions.SetSenderEmail, mail)
      this.$v.senderEmail.$touch()
    },
    setFromName (fromName) {
      this.$store.dispatch(CommActions.SetFromName, fromName)
    },
    nextStep () {
      this.$v.$touch()
      this.$store.dispatch(CommActions.Next)
    },
    reimportTemplate () {
      this.$store.dispatch(CommActions.ReimportTemplate)
    },
    fetchTemplates () {
      this.$store.dispatch(CommActions.FetchEmailTemplates)
    }
  }
}
</script>

<style scoped>
    .template-import {
        padding: 3px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
</style>
