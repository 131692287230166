<template>
  <b-modal title="Details" size="xl" :visible="visible" @hidden="reset" ok-only>
    <template v-if="item">
      <table class='event-table col-12'>
        <tr>
          <td>Id</td>
          <td>{{ item.id }}</td>
        </tr>
        <tr>
          <td>Type</td>
          <td>{{ item.type }}</td>
        </tr>
        <tr>
          <td>Name</td>
          <td>{{ item.name }}</td>
        </tr>
        <tr>
          <td>Source</td>
          <td><Pill large color='primary'>{{ item.source.toUpperCase() }}</Pill></td>
        </tr>
        <tr>
          <td>Agent</td>
          <td>{{ item.agent_id }}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>
            <Pill large color='red' v-if='item.status === "error"'>
              {{ item.status.toUpperCase() }}
            </Pill>
            <Pill large color='green' v-else-if='item.status === "done"'>
              {{ item.status.toUpperCase() }}
            </Pill>
            <Pill large v-else>
              {{ item.status.toUpperCase() }}
            </Pill>
          </td>
        </tr>
        <tr>
          <td>Created At</td>
          <td>{{ formateDate(item.created_at) }}</td>
        </tr>
        <tr>
          <td>Params</td>
          <td>
            <div class='col-12'>
              <pre class='code'>{{ item.params }}</pre>
            </div>
          </td>
        </tr>
        <tr>
          <td>Details</td>
          <td>
            <div class='col-12'>
              <pre class='code'>{{ item.details }}</pre>
            </div>
          </td>
        </tr>
      </table>
    </template>
  </b-modal>
</template>

<script>
import Pill from '@/components/Pill.vue'
import moment from 'moment/moment'
export default {
  name: 'EventItem',
  components: {
    Pill
  },
  model: {
    prop: 'item',
    event: 'input'
  },
  props: {
    item: {
      default: null
    }
  },
  computed: {
    visible () {
      return this.item !== null
    }
  },
  methods: {
    reset () {
      this.$emit('input', null)
    },
    formateDate (date) {
      return moment(date).format('DD/MM/YYYY - hh:mm:ss')
    }
  }
}
</script>

<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
td {
  border: 1px solid #ddd;
  height: 40px;
  padding: 5px 10px;
}
td:first-child {
  min-width: 100px;
  font-weight: bold;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
