<template>
  <Modal :on-escape="onEscape">
    <template v-slot:header>
      <h2 class="p-3">Add Participants</h2>
    </template>
    <div style="padding: 15px" class="input-group search col-md-4">
      <div class="input-group-prepend">
        <div class="input-group-prepend-text d-flex align-items-left" style="padding: 15px">
          <Icon style="color: #c6c6d3;" icon="search"/>
        </div>
      </div>
      <input class="form-control bg-transparent form-control"
             v-on:input="onSearchEmployee(query)"
             v-model="query"
             type="search"
             placeholder="Search"/>
    </div>
    <Table
      :style="{display: employees.length === 0 ? 'table' : 'block'}"
      class="fixed-modal-content is-table"
      :columns="columns"
      :records="employees"
      v-model="currentParticipants">
    </Table>

    <ButtonsBar class="font-size-lg">
      <template slot="left">
        <div class="d-inline-block p-4" slot="left">
          <Pill type="primary" class="mr-2">{{currentParticipants.length}}</Pill>Participants
        </div>
      </template>
      <template v-if="currentParticipants.length" slot="right">
        <span class="p-4">[esc] <i class="text-muted">to deselect all</i></span>
        <Button @click="addJourneyParticipants" class="p-4 font-weight-bold font-size-lg">Add Participants</Button>
      </template>
    </ButtonsBar>
  </Modal>
</template>

<script>
import Button from '@/components/Button.vue'
import ButtonsBar from '@/components/ButtonsBar.vue'
import Icon from '@/components/Icon.vue'
import Modal from '@/components/Modal.vue'
import Pill from '@/components/Pill.vue'
import Table from '@/components/Table.vue'
import { queryResults } from '@/components/helpers'
import lepaya from '@/http/lepaya'
import { clone, values } from 'ramda'

export default {
  name: 'AddJourneyParticipantsModal',
  components: {
    Button,
    ButtonsBar,
    Icon,
    Modal,
    Pill,
    Table
  },

  data: () => ({
    columns: values(columns),
    cancelTo: { name: 'editJourney' },
    query: [],
    currentParticipants: [],
    participants: [],
    selectedEmployees: [],
    employees: []

  }),

  computed: {
    buttonDisabled () {
      return false
    }
  },

  methods: {

    onSearchEmployee (input) {
      query(lepaya.get(`companies/${this.$route.params.companyCode}/employees`), { query: input || undefined })
        .then(employees => {
          this.employees = employees
        })
    },

    onEscape () {
      if (this.currentParticipants.length === 0) { return this.onClose() }
      this.currentParticipants = []
    },

    addJourneyParticipants () {
      const params = this.$route.params
      const { companyCode, id } = params
      const current = this.currentParticipants.map(item => item.id)
      const initial = this.initialParticipants.map(item => item.id)
      const newParticipants = current.filter(newParticipant => !initial.includes(newParticipant))

      if (newParticipants.length <= 0) {
        this.onClose()
      } else {
        lepaya.post(`/companies/${companyCode}/journeys/${id}/participants`, { employeesIds: newParticipants })
          .then(() => {
            lepaya.get(`/companies/${companyCode}/journeys/${id}/participants`)
              .then(({ data: participants }) => {
                this.$router.push({
                  name: 'editJourney',
                  query: {
                    add: true,
                    newParticipants
                  }
                })
                this.selectedEmployees = []
                this.currentParticipants = participants
              })
          })
          .catch(({ response: { data: errors } }) => {
            this.errors = errors
          })
      }
    }
  },

  beforeRouteEnter (to, from, next) {
    const { companyCode, id } = to.params
    return Promise.all([
      lepaya.get(`/companies/${companyCode}/employees`),
      lepaya.get(`/companies/${companyCode}/journeys/${id}/participants`)
    ])
      .then((result) => {
        const [ { data: employees }, { data: participants } ] = result
        next(vm => {
          vm.selectedEmployees = []
          vm.employees = employees
          vm.participants = participants
          vm.currentParticipants = participants.filter(participant =>
            employees.find(employee => {
              if (employee.id === participant.id) employee.journeyRole = participant.journeyRole
              return employee.id === participant.id
            }))
          vm.initialParticipants = clone(vm.currentParticipants)
        })
      })
  }
}

const columns = {
  firstName: {
    name: 'First Name',
    value: ({ firstName }) => firstName
  },
  lastName: {
    name: 'Last Name',
    value: ({ lastName }) => lastName
  },
  email: {
    name: 'Email',
    value: ({ email }) => email
  },
  journeyRole: {
    name: 'Module Role',
    value: ({ journeyRole }) => journeyRole
  },
  id: {
    name: 'Employee Id',
    value: ({ id }) => id
  },
  role: {
    name: 'Role',
    value: ({ role }) => role
  }
}
const query = queryResults({
  searchable: [ 'firstName', 'lastName', 'email' ]
})

</script>

<style scoped>

</style>
