<template>
  <div class="justify-content">
    <a @click="editBite">
      <Icon class="text-primary text-center mx-2 font-size-md" icon="pen" alt="Edit"/>
    </a>
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue'
import { BiteTypeDefinitions } from '@/components/bites/supportedBiteForms'

export default {
  name: 'BiteActions',
  props: {
    bite: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Icon
  },
  methods: {
    editBite () {
      if (BiteTypeDefinitions.find(form => form.biteType === this.bite.type)) {
        this.$store.commit('bites/setBiteCreation', false)
        this.$store.commit('bites/setEditingBite', this.bite)
      } else {
        this.$store.commit('bites/setBiteNoLongerSupportedMessage', 'This bite is no longer supported and can\'t be edited')
        const element = document.getElementById('biteListId')
        if (element) element.scrollIntoView()
        setTimeout(() => { this.$store.commit('bites/setBiteNoLongerSupportedMessage', null) }, 3000)
      }
    }
  }
}
</script>

<style scoped>

</style>
