<template>

  <Modal :shadow="false">
    <div class="container-fluid mb-5">
      <div class="row text-center py-5">
        <Icon class="mx-auto font-size-xl text-primary " icon="rocket"/>
      </div>
      <h1 class="font-weight-bold text-center">You are about to start a new wave</h1>
      <p class="text-secondary text-center pb-5 h4">Pick a program</p>
      <div class="row">
        <Anchor
           v-bind:key="companyProgram.name"
           v-for="companyProgram in companyPrograms"
           class="col-sm-4 mb-4"
           :to="{name: 'startProgram', params: {programCatalogueItemId: companyProgram.id}}">
          <Card class="text-body" style="height: 50vh;">
            <div slot="image" v-bind:style="companyProgramStyle(companyProgram)"></div>
            <strong slot="title">{{companyProgram.programName}}</strong>
          </Card>
        </Anchor>
      </div>
    </div>
  </Modal>

</template>

<script>
import Modal from './Modal.vue'
import Card from './Card.vue'
import Anchor from './Anchor.vue'
import Icon from './Icon.vue'

import lepaya from '../http/lepaya.js'

export default {

  components: {
    Icon,
    Anchor,
    Modal,
    Card
  },

  data: () => ({
    companyPrograms: []
  }),

  methods: {
    companyProgramStyle (companyProgram) {
      return {
        backgroundImage: `url("${companyProgram.imageUrl}")`,
        backgroundSize: 'cover',
        height: '100%'
      }
    }
  },

  beforeRouteEnter (to, from, next) {
    lepaya.get(`/companies/${to.params.companyCode}/programs-catalogue`)
      .then(({ data: companyPrograms }) => {
        next(vm => {
          vm.companyPrograms = companyPrograms
        })
      })
  }
}
</script>
