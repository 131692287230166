<template>
  <Modal
    :on-escape="closeModal"
    :on-close="closeModal"
  >
    <NotificationPanel v-if="successUpdatedMessage" :message=successUpdatedMessage></NotificationPanel>
    <NotificationPanel v-if="failedUpdateMessage" :classes="{ 'bg-danger': true }" icon="exclamation-circle" :message=failedUpdateMessage></NotificationPanel>

    <template v-slot:header>
      <p class="p-3 header">{{ formTitle }}</p>
    </template>
    <component @onClose="closeModal" @onSave="saveBite" :is="formName"/>
    <OverlayingSpinner v-if="loading"/>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import NotificationPanel from '@/components/NotificationPanel.vue'
import ArticleForm from '@/components/bites/forms/ArticleForm.vue'
import DatedGoalForm from '@/components/bites/forms/DatedGoalForm.vue'
import ExternalDocumentForm from '@/components/bites/forms/ExternalDocumentForm.vue'
import SingleChoiceForm from '@/components/bites/forms/SingleChoiceForm.vue'
import SurveyForm from '@/components/bites/forms/SurveyForm.vue'
import TextForm from '@/components/bites/forms/TextForm.vue'
import VCoachForm from '@/components/bites/forms/VCoachForm.vue'
import VideoForm from '@/components/bites/forms/VideoForm.vue'
import { BiteTypeDefinitions } from '@/components/bites/supportedBiteForms'
import OverlayingSpinner from '@/components/common/OverlayingSpinner.vue'
import { camelCase, startCase } from 'lodash'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'EditBiteModal',
  components: {
    OverlayingSpinner,
    Modal,
    NotificationPanel,
    ExternalDocumentForm,
    SurveyForm,
    VideoForm,
    ArticleForm,
    TextForm,
    SingleChoiceForm,
    VCoachForm,
    DatedGoalForm
  },
  methods: {
    ...mapActions('bites', {
      createBite: 'createBite',
      updateBite: 'updateBite'
    }),
    closeModal () {
      this.$store.commit('bites/setEditingBite', null)
    },
    async saveBite (bite) {
      if (this.isNewBite) {
        this.saveBiteCreation(bite)
      } else {
        this.saveBiteEdit(bite)
      }
    },
    async saveBiteEdit (bite) {
      const { id, companyCode } = this.$route.params
      const biteUpdatedSuccessfully = await this.updateBite({ id, companyCode, bite })
      if (biteUpdatedSuccessfully) {
        this.$emit('biteEdited', bite)
        await new Promise(resolve => setTimeout(() => { this.closeModal() }, 1500))
      }
    },
    async saveBiteCreation (bite) {
      const { id, companyCode } = this.$route.params
      const biteUpdatedSuccessfully = await this.createBite({ id, companyCode, bite })
      if (biteUpdatedSuccessfully) {
        this.$emit('biteEdited', bite)
        await new Promise(resolve => setTimeout(() => { this.closeModal() }, 1500))
      }
    }
  },
  computed: {
    ...mapGetters('bites', {
      isNewBite: 'isNewBite',
      editingBite: 'editingBite',
      loading: 'loading',
      successUpdatedMessage: 'successUpdatedMessage',
      failedUpdateMessage: 'failedUpdateMessage'
    }),
    formName () {
      return BiteTypeDefinitions.find(form => form.biteType === this.editingBite.type).formName
    },
    formTitle () {
      const title = startCase(camelCase(this.editingBite.type))
      const subType = this.editingBite.subtype ? `(${startCase(camelCase(this.editingBite.subtype))})` : ''
      return `${title} ${subType}`
    }
  }
}
</script>

<style scoped>
.header {
    align-self: center;
    font-size: 32px;
}

</style>
