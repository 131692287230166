import { CommunicationStatus, ScheduleType, WizardSteps } from '@/vuex/modules/comm/state'
import { objectKeysToCamel } from '@/lib/format'

export const fauxTemplatesResponse = [
  { slug: 'test-1', name: 'Template #1', labels: [ 'learner', 'kg' ] },
  { slug: 'test-2', name: 'Template #2', labels: [ 'manager', 'kg' ] },
  { slug: 'test-3', name: 'Template #3', labels: [ 'manager-package', 'kg' ] }
]

export const fauxTemplateDetailsResponses = {
  'test-1': {
    slug: 'test-1',
    name: 'Template #1',
    code: '<h1> Template #1 header</h1>',
    publishCode: null,
    createdAt: '2021-05-18 14:59:19.38561',
    updatedAt: '2021-05-18 15:00:44.77292',
    draftUpdatedAt: '2021-05-18 15:00:44',
    publishName: 'Template #1',
    labels: [],
    text: 'Template #1 text',
    publishText: null,
    subject: 'Template #1 Subject',
    publishSubject: null,
    fromEmail: 'test-1@lepaya.com',
    publishFromEmail: null,
    fromName: 'From Somebody #1',
    publishFromName: null
  },
  'test-2': {
    slug: 'test-2',
    name: 'Template #2',
    code: '<h1> Template #2 header</h1>',
    publishCode: null,
    createdAt: '2021-05-18 14:59:19.38561',
    updatedAt: '2021-05-18 15:00:44.77292',
    draftUpdatedAt: '2021-05-18 15:00:44',
    publishName: 'Template #2',
    labels: [],
    text: 'Template #2 text',
    publishText: null,
    subject: 'Template #2 Subject',
    publishSubject: null,
    fromEmail: 'test-2@lepaya.com',
    publishFromEmail: null,
    fromName: 'From Somebody #2',
    publishFromName: null
  },
  'test-3': {
    slug: 'test-3',
    name: 'Template #3',
    code: '<h1> Template #3 header</h1>',
    publishCode: null,
    createdAt: '2021-05-18 14:59:19.38561',
    updatedAt: '2021-05-18 15:00:44.77292',
    draftUpdatedAt: '2021-05-18 15:00:44',
    publishName: 'Template #3',
    labels: [],
    text: 'Template #3 text',
    publishText: null,
    subject: 'Template #3 Subject',
    publishSubject: null,
    fromEmail: 'test-3@lepaya.com',
    publishFromEmail: null,
    fromName: 'From Somebody #3',
    publishFromName: null
  }
}

export const fauxEmailListResponse = [
  {
    id: 1,
    name: 'Email #1 name',
    subject: 'Subject #1',
    status: CommunicationStatus.DRAFT,
    step: WizardSteps.SCHEDULING,
    createdAt: '2021-05-18 14:59:19.38561',
    updatedAt: '2021-05-18 15:00:44.77292',
    lastModifiedBy: 'Monty Python',
    communicationDetails: {
      selectedTemplate: { name: 'Template #1', slug: 'test-1' },
      waveLanguage: { code: 'en', name: 'English' },
      templateSlug: '',
      templateData: '',
      templateImportTime: '2021-05-18 15:00:44.77292',
      subjectLine: 'Subject #1',
      previewText: 'Preview Text #1',
      senderEmail: 'someSender#1@lepaya.com',
      fromName: null,
      customVariables: [],
      attachments: null,
      recipients: null,
      scheduleType: ScheduleType.IMMEDIATE,
      sendDateTime: null,
      sendDateTimezone: null
    }
  },
  {
    id: 2,
    name: 'Email #2 name',
    subject: 'Subject #2',
    status: CommunicationStatus.DRAFT,
    step: WizardSteps.SCHEDULING,
    createdAt: '2021-05-18 14:59:19.38561',
    updatedAt: '2021-05-18 15:00:44.77292',
    lastModifiedBy: 'Dexter Morgan',
    communicationDetails: {
      selectedTemplate: { name: 'Template #2', slug: 'test-2' },
      waveLanguage: { code: 'en', name: 'English' },
      templateSlug: '',
      templateImportTime: '2021-05-18 15:00:44.77292',
      subjectLine: 'Subject #2',
      previewText: 'Preview Text #2',
      senderEmail: 'someSender#2@lepaya.com',
      fromName: null,
      customVariables: [],
      attachments: null,
      recipients: null,
      scheduleType: ScheduleType.IMMEDIATE,
      sendDateTime: null,
      sendDateTimezone: null,
      communicationType: 'program_announcement',
      selectedModule: 1
    }
  },
  {
    id: 3,
    name: 'Email #3 name',
    subject: 'Subject #3',
    status: 'SENT', // DRAFT, SCHEDULED, SENT ?
    step: WizardSteps.LAST_STEP,
    createdAt: '2021-05-18 14:59:19.38561',
    updatedAt: '2021-05-18 15:00:44.77292',
    lastModifiedBy: 'Daenerys Targaryen',
    communicationDetails: {
      selectedTemplate: { name: 'Template #3', slug: 'test-3' },
      waveLanguage: { code: 'en', name: 'English' },
      templateSlug: '',
      templateImportTime: '2021-05-18 15:00:44.77292',
      subjectLine: 'Subject #3',
      previewText: 'Preview Text #3',
      senderEmail: 'someSender#3@lepaya.com',
      fromName: null,
      customVariables: [],
      attachments: null,
      recipients: null,
      scheduleType: ScheduleType.IMMEDIATE,
      sendDateTime: null,
      sendDateTimezone: null
    }
  }
]

export const fauxEmailPreviewData = {
  from: 'Pera <pera.peric@kodpere.com>',
  htmlBody: '<p>Just testing</p>',
  subject: 'Hello Pera',
  textBody: null,
  to: 'Steva <steva.stevic@kodsteve.com>'
}

export const fauxRecipientResponse = [
  {
    firstName: 'Paula',
    lastName: 'Leyenda',
    email: 'paula@bites.com',
    companyRole: null,
    id: 75,
    role: 'admin'
  },
  {
    firstName: 'drazen',
    lastName: 'popov',
    email: 'drazen.popov@lepaya.com',
    companyRole: null,
    id: 86,
    role: 'admin'
  },
  {
    firstName: 'Bas',
    lastName: 'Huis',
    email: 'bas@bites.com',
    companyRole: null,
    id: 76,
    role: 'employee'
  },
  {
    firstName: 'Hugo',
    lastName: 'Boss',
    email: 'peter@bites.com',
    companyRole: null,
    id: 74,
    role: 'admin'
  }
]
export const fauxParticipantsResponse = {
  data: [
    {
      firstName: 'Paula',
      lastName: 'Leyenda',
      email: 'paula@bites.com',
      companyRole: null,
      id: 11,
      role: 'admin'
    },
    {
      firstName: 'drazen',
      lastName: 'popov',
      email: 'drazen.popov@lepaya.com',
      companyRole: null,
      id: 22,
      role: 'admin'
    },
    {
      firstName: 'Bas',
      lastName: 'Huis',
      email: 'bas@bites.com',
      companyRole: null,
      id: 33,
      role: 'employee'
    },
    {
      firstName: 'Hugo',
      lastName: 'Boss',
      email: 'peter@bites.com',
      companyRole: null,
      id: 44,
      role: 'admin'
    }
  ]
}

export const fauxManagersResponse = {
  data: [
    {
      firstName: 'Manager',
      lastName: 'one',
      email: 'manager@bites.com',
      companyRole: null,
      id: 77,
      role: 'employee'
    },
    {
      firstName: 'Manager',
      lastName: 'Two',
      email: 'mng.two@lepaya.com',
      companyRole: null,
      id: 78,
      role: 'employee'
    }
  ]
}

export const fauxModuleParticipantsResponse = {
  data: [
    {
      firstName: 'Paula',
      lastName: 'Leyenda',
      email: 'paula@bites.com',
      companyRole: null,
      id: 11,
      role: 'support'
    },
    {
      firstName: 'Juan',
      lastName: 'Juan',
      email: 'juan.jhon@lepaya.com',
      companyRole: null,
      id: 22,
      role: 'support'
    }
  ]
}

export default class CommunicationsApi {
  constructor (lepayaApi) {
    this.lepayaApi = lepayaApi
  }

  async persistCommunication (companyCode, waveId, communicationPayload) {
    const emailId = communicationPayload.id
    let response = null
    if (emailId) {
      response = await this.lepayaApi.put(`/companies/${companyCode}/programs/${waveId}/emails/${emailId}`, communicationPayload)
    } else {
      delete communicationPayload.id
      response = await this.lepayaApi.post(`/companies/${companyCode}/programs/${waveId}/emails`, communicationPayload)
    }

    if (response.data && response.data.id) {
      return response.data.id
    }

    return null
  }

  async getCommunicationsList (companyCode, waveId) {
    const commListResponse = await this.lepayaApi.get(`/companies/${companyCode}/programs/${waveId}/emails`)
    if (commListResponse.data) {
      return commListResponse.data
    }
    return []
  }

  async fetchMailchimpTemplates () {
    await this.lepayaApi.post('/mailchimp/triggers/get-template-list')
  }

  async getCommunicationDetails (companyCode, waveId, communicationId) {
    const commDetailsResponse = await this.lepayaApi.get(`/companies/${companyCode}/programs/${waveId}/emails/${communicationId}`)
    if (commDetailsResponse.data) {
      return commDetailsResponse.data
    }
    return null
  }

  async getSentCommunicationViewDetails (companyCode, waveId, communicationId) {
    const commDetailsResponse = await this.lepayaApi.get(`/companies/${companyCode}/programs/${waveId}/view-email/${communicationId}`)
    if (commDetailsResponse.data) {
      return objectKeysToCamel(commDetailsResponse.data)
    }
    return null
  }

  async getWaveLanguage (companyCode, waveId) {
    const response = await this.lepayaApi.get(`/companies/${companyCode}/programs/${waveId}/language`)
    if (response.data && response.data.languageCode && response.data.languageName) {
      return { code: response.data.languageCode, name: response.data.languageName }
    } else {
      throw new Error('Unable to fetch language for wave')
    }
  }

  async getWaveModules (companyCode, waveId) {
    const response = await this.lepayaApi.get(`/companies/${companyCode}/programs/${waveId}/journeys`)
    if (response.data) {
      return response.data
    } else {
      throw new Error('Unable to fetch modules for wave')
    }
  }

  async getClassroomStartDate (companyCode, waveId) {
    try {
      const response = await this.lepayaApi.get(`/companies/${companyCode}/programs/${waveId}/classroom-start`)
      if (response.data && response.data.classroomStart && !isNaN(new Date(response.data.classroomStart))) {
        return new Date(response.data.classroomStart)
      }
      return null
    } catch (e) {
      return null
    }
  }

  async getAvailableTemplates () {
    const templatesResponse = await this.lepayaApi.get('/mailchimp/templates')
    if (templatesResponse.data) {
      return templatesResponse.data
    }
    return []
  }

  async getTemplateDetails (slug) {
    const templateDetailsResponse = await this.lepayaApi.get(`/mailchimp/templates/${slug}`)
    if (templateDetailsResponse.data) {
      return templateDetailsResponse.data
    }
    return null
  }

  async removeCommunication (companyCode, waveId, emailId) {
    return this.lepayaApi.delete(`/companies/${companyCode}/programs/${waveId}/emails/${emailId}`)
  }

  async getListOfParticipants (companyCode, programId) {
    return this.lepayaApi.get(`/companies/${companyCode}/programs/${programId}/participants`)
  }

  async getListOfManagers (companyCode, programId) {
    return this.lepayaApi.get(`/companies/${companyCode}/programs/${programId}/managers`)
  }

  async sendTestEmail (companyCode, programId, emailId) {
    return this.lepayaApi.post(`/companies/${companyCode}/programs/${programId}/send-email-test/${emailId}`)
  }

  async sendEmailToNewAddedParticipants (companyCode, programId, emailId, recipients) {
    return this.lepayaApi.post(`/companies/${companyCode}/programs/${programId}/send-to-new-participants/${emailId}`, recipients)
  }

  async sendImmediateEmail (companyCode, programId, emailId) {
    return this.lepayaApi.post(`/companies/${companyCode}/programs/${programId}/send-email-now/${emailId}`)
  }

  async generateEmailPreviewData (companyCode, programId, emailId) {
    return this.lepayaApi.get(`/companies/${companyCode}/programs/${programId}/preview-email/${emailId}`)
  }

  async getListOfJourneyParticipants (companyCode, journeyId) {
    return this.lepayaApi.get(`/companies/${companyCode}/journeys/${journeyId}/participants`)
  }

  async getAutoCommunications (companyCode, waveId) {
    const res = await this.lepayaApi.get(`/companies/${companyCode}/programs/${waveId}/communications`)
    if (res.data) {
      return res.data
    }
  }
}
