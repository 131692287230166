<template>
  <div class="module-information">
    <b-form-select v-model="moduleSelected" :options="modulesForManagerPackageOptions" @change="onModuleSelected"></b-form-select>
    <template v-if="!moduleSelected">
      <VariableEntry  :variable="moduleVariables.moduleName"  @updateVariable="updateVariable"/>
      <VariableEntry  :variable="moduleVariables.managerPackageLink"  @updateVariable="updateVariable" />
    </template>
    <template v-else>
      <div class="module-information-line">
        <label>Module Name:</label>
        <span class="bold">{{moduleVariables.moduleName.value}}</span>
      </div>
      <div class="module-information-line">
        <label>Manager Package Link:</label>
        <a target="_blank" :href="moduleVariables.managerPackageLink.value"><b-badge>{{moduleVariables.managerPackageLink.value}}</b-badge></a>
      </div>
    </template>
  </div>
</template>

<script>
import VariableEntry from '@/views/communication/forms/VariableEntry.vue'
import { MANAGER_PACKAGE_LINK, MODULE_NAME } from '@/views/communication/templateVariables'
import CommActions from '@/vuex/modules/comm/actions'

export const languageCodes = {
  en: 'en-us',
  nl: 'nl-nl',
  de: 'de-de',
  fr: 'fr-fr',
  se: 'sv-se',
  it: 'it-it',
  zh: 'zh-cn'
}

export default {
  name: 'ManagerPackageSelector',
  components: { VariableEntry },
  data: () => ({
    moduleSelected: null,
    moduleVariables: {
      moduleName: { path: MODULE_NAME, value: '', imported: true },
      managerPackageLink: { path: MANAGER_PACKAGE_LINK, value: '', imported: true }
    }
  }),
  created () {
    const moduleNameIndex = this.$store.state.comm.data.customVariables.findIndex(i => i.path === MODULE_NAME)
    if (moduleNameIndex > -1) {
      this.moduleVariables.moduleName = this.$store.state.comm.data.customVariables[moduleNameIndex]
    }
    const managerPackageIndex = this.$store.state.comm.data.customVariables.findIndex(i => i.path === MANAGER_PACKAGE_LINK)
    if (managerPackageIndex > -1) {
      this.moduleVariables.managerPackageLink = this.$store.state.comm.data.customVariables[managerPackageIndex]
    }

    const selectedModule = this.$store.state.comm.selectedModuleForCustomization
    if (selectedModule) {
      this.moduleSelected = selectedModule
    }
  },
  methods: {
    updateVariable ({ variable }) {
      const index = this.$store.state.comm.data.customVariables.slice().findIndex(i => i.path === variable.path)
      if (index > -1) {
        this.$store.dispatch(CommActions.UpdateCustomVariable, { index, variable })
      } else {
        this.$store.dispatch(CommActions.AppendCustomVariable, { variable })
      }
    },
    async onModuleSelected (mId) {
      const domain = process.env.VUE_APP_SHOWER_URL
      const module = this.$store.state.comm.waveModules.slice().filter(m => !!m.contentStackUid && m.id === mId).pop()
      const { waveLanguage } = this.$store.state.comm.data
      const local = languageCodes[waveLanguage.code] || 'en-us'
      if (module) {
        this.moduleVariables.moduleName.value = module.title
        this.moduleVariables.managerPackageLink.value = `${domain}/public/module/${encodeURIComponent(module.contentStackUid)}/manager-package/${local}?cc=${this.$store.state.comm.companyCode}`
        this.moduleSelected = mId
      } else {
        this.moduleVariables.moduleName.value = ''
        this.moduleVariables.managerPackageLink.value = ''
        this.moduleSelected = null
      }
      this.updateVariable({ variable: this.moduleVariables.moduleName })
      this.updateVariable({ variable: this.moduleVariables.managerPackageLink })
      this.$store.dispatch(CommActions.SetSelectedModuleForCustomization, this.moduleSelected)
    }
  },
  computed: {
    modulesForManagerPackageOptions () {
      return [
        { text: 'Custom', value: null },
        ...this.$store.state.comm.waveModules.filter(m => !!m.contentStackUid).map(i => ({ text: i.title, value: i.id }))
      ]
    }
  }
}
</script>

<style scoped>
.module-information {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid lightgray;
  border-radius: 2px;
  margin: 0 0 1rem 0;
}
.module-information-line {

  margin: 10px 0 0 0;
  width: 80%;
  display: flex;
  justify-content: flex-start;
  gap: 1em;
}
</style>
