<template>
  <div class="subject-container">
    <div class="col-2">
      <h4 class="p-2"><strong>From: </strong></h4>
      <h4 class="p-2"><strong>Subject Line: </strong></h4>
    </div>
    <div class="col-10">
      <h4 class="p-2">{{ previewEmailData.from }}</h4>
      <h4 class="p-2">{{ previewEmailData.subject }}</h4>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SubjectAndFromSection',
  computed: {
    ...mapGetters({
      previewEmailData: 'comm/previewEmailData'
    })
  }
}
</script>

<style scoped>
    .subject-container {
        display: flex;
        flex-direction: row;
        padding: 15px;
        background-color: #f0f0f5;
    }
</style>
