<template>
  <div class="mr-auto">
    <div class="d-inline-block p-4" slot="left"><Pill type="primary" class="mr-2">{{ currentRecipientsList.length }}</Pill>Recipients</div>
    <div class="d-inline-block p-4 rounded-lg" slot="center">
      <Pill class="pill-notification-blue" style="color: #1A6BC6; padding: 10px;">
        <Icon style="color: #1A6BC6" icon="exclamation-circle" /> Newly added participants with employee role will apear in this list.
      </Pill>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue'
import Pill from '@/components/Pill.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'RecipientsCounter',
  components: { Icon, Pill },
  computed: {
    ...mapGetters({
      currentRecipientsList: 'comm/currentRecipientsList'
    })
  }
}
</script>

<style scoped>
</style>
