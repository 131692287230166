<template>
  <div>
    <div class="row">

      <div class="col-6">
        <FormGroup class="form-group-lg" :errors="titleErrors">
          <Label>Title</Label>
          <Input
            v-model="$v.bite.title.$model"
            class="form-control"
            placeholder="Add title..."
          />
        </FormGroup>
      </div>

      <div class="col-6">
        <FormGroup class="form-group-lg" :errors="durationMinsErrors">
          <Label class="required">Learning Time In Minutes</Label>
          <Input
            v-model="bite.durationMins"
            type="number"
            :value="bite.durationMins"
            class="form-control"
          />
        </FormGroup>
      </div>

    </div>

    <div class="row">

      <div class="col-3">
        <FormGroup>
          <Label>Type</Label>
          <Select
            placeholder="Select bite type"
            :options="biteTypeOptions"
            :value="biteTypeValue"
            @input="setOptionalOrMandatory"
            :isDisabled="disableSwitchType"
          >
          </Select>
        </FormGroup>
      </div>

      <div class="col-3" v-if="!bite.isOptional">
        <FormGroup>
          <Label>Stage</Label>
          <Select
            placeholder="Select bite stage"
            :options="biteStageOptions"
            :value="biteStageValue"
            @input="setBiteStage"
          >
          </Select>
        </FormGroup>
      </div>

      <div class="col-3" v-if="!bite.isOptional">
        <FormGroup>
          <Label>Deadline Option</Label>
          <Select
            placeholder="Select a deadline option"
            :options="biteDeadlineOptions"
            :value="activeDeadlineOption"
            @input="setDeadlineTypeValue"
          >
          </Select>
        </FormGroup>
      </div>

      <div class="col-3"  v-if="!bite.isOptional">
        <FormGroup v-if="showRelativeDays">
          <Label class="required">Days</Label>
          <Input
            type="number"
            v-model="bite.relativeDeadlineTime"
            class="form-control"
            placeholder="Add days number..."
          />
        </FormGroup>

        <FormGroup v-if="showFixedDeadline">
          <Label class="required">Deadline</Label>
            <Datepicker
              @setDate="setDeadlineDate"
              :value="bite.deadlineTime"
              :disabledDates="disabledDates"
            ></Datepicker>
        </FormGroup>
      </div>

      <div class="col-6" v-if="hasContentSubtype">
        <FormGroup :errors="subtypeOfContentErrors">
          <Label>Subtype of Content</Label>
          <Select
            placeholder="Please select bite subtype"
            :options="selectSubtypeOfContentOptions"
            :value="subtypeOfContentValue"
            @input="setBiteSubtypeOfContent"
          />
        </FormGroup>
      </div>

      <div class="col-12">
        <FormGroup :errors="purposeErrors">
          <Label>Purpose</Label>
          <Select
              placeholder="Please select bite purpose"
              :options="selectBitePurposeOptions"
              :value="purposeValue"
              @input="setBitePurpose"
          />
        </FormGroup>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from '@/components/Datepicker.vue'
import FormGroup from '@/components/FormGroup.vue'
import Input from '@/components/Input.vue'
import Select from '@/components/Select.vue'
import {
  articleBitePurposeOptions,
  biteDeadlineOptions,
  bitePurposeOptions,
  biteStageOptions,
  biteTypeOptions,
  externalDocumentBitePurposeOptions,
  externalTypeOfContentOptions,
  intentionSettingBitePurposeOptions,
  surveyBitePurposeOptions,
  surveyTypeOfContentOptions,
  textBitePurposeOptions,
  vcoachBitePurposeOptions,
  videoBitePurposeOptions
} from '@/components/bites/forms/formDropdownOptions'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'BiteFormCommonFields',
  components: {
    FormGroup,
    Select,
    Input,
    Datepicker
  },
  data: () => ({
    biteDeadlineOptions,
    biteStageOptions,
    biteTypeOptions,
    bitePurposeOptions,
    articleBitePurposeOptions,
    externalDocumentBitePurposeOptions,
    surveyBitePurposeOptions,
    videoBitePurposeOptions,
    textBitePurposeOptions,
    externalTypeOfContentOptions,
    surveyTypeOfContentOptions,
    bite: null,
    biteDeadlineType: null,
    disabledDates: {
      to: moment().subtract(1, 'days').endOf('day').toDate()
    }
  }),
  created () {
    this.bite = cloneDeep(this.editingBite)
    this.biteDeadlineType = this.bite.relativeDeadlineTime ? 'relative' : 'fixed'
    this.$v.$touch()
  },

  computed: {
    ...mapGetters('bites', {
      editingBite: 'editingBite',
      lastPostBiteOrderNumber: 'lastPostBiteOrderNumber'
    }),
    activeDeadlineOption () {
      return biteDeadlineOptions.find(option => option.code === this.biteDeadlineType)
    },
    biteStageValue () {
      // use the existing bite.stage if it exists AND is not 'optional
      const biteStage = this.bite.stage && this.bite.stage !== 'optional' ? this.bite.stage : 'post'
      return biteStageOptions.find(option => option.code === biteStage)
    },
    biteTypeValue () {
      const biteType = this.bite.isOptional ? 'optional' : 'mandatory'
      return biteTypeOptions.find(option => option.code === biteType)
    },
    hasContentSubtype () {
      return this.bite.type === 'externalDocument' || this.bite.type === 'survey'
    },
    selectSubtypeOfContentOptions () {
      return this.bite.type === 'externalDocument' ? externalTypeOfContentOptions : this.bite.type === 'survey' ? surveyTypeOfContentOptions : ''
    },
    selectBitePurposeOptions () {
      switch (this.bite.type) {
        case 'article':
          return articleBitePurposeOptions
        case 'externalDocument':
          return externalDocumentBitePurposeOptions
        case 'survey':
          return surveyBitePurposeOptions
        case 'text':
          return textBitePurposeOptions
        case 'video':
          return videoBitePurposeOptions
        case 'vcoach':
          return vcoachBitePurposeOptions
        case 'intention_setting':
          return intentionSettingBitePurposeOptions
        default:
          return bitePurposeOptions
      }
    },
    subtypeOfContentValue () {
      return this.selectSubtypeOfContentOptions.find(option => option.code === this.bite.subtype)
    },
    purposeValue () {
      return this.selectBitePurposeOptions.find(option => option.code === this.bite.purpose)
    },
    showRelativeDays () {
      return this.biteDeadlineType === 'relative'
    },
    showFixedDeadline () {
      return this.biteDeadlineType === 'fixed'
    },
    titleErrors () {
      if (this.$v.bite.title.$dirty && !this.$v.bite.title.required) return [ 'Title cannot be empty' ]
      return []
    },
    durationMinsErrors () {
      if (this.$v.bite.durationMins.$dirty && !this.$v.bite.durationMins.required) return [ 'Learning time cannot be empty' ]
      return []
    },
    purposeErrors () {
      if (this.$v.bite.purpose.$dirty && !this.$v.bite.purpose.required) return [ 'Bite Purpose cannot be empty' ]
      return []
    },
    subtypeOfContentErrors () {
      if (this.hasContentSubtype && this.$v.bite.subtype.$dirty && !this.$v.bite.subtype.required) return [ 'Subtype of content cannot be empty' ]
      return []
    },
    disableSwitchType () {
      // Switch status is only allowed for 'post' and 'optional' bites
      return this.bite.stage === 'pre' || this.bite.stage === 'session'
    }
  },
  methods: {
    setOptionalOrMandatory (event) {
      this.bite.isOptional = event.code === 'optional'
      if (event.code === 'optional') {
        this.bite.stage = 'optional'
        this.bite.orderNumber = null
      } else {
        this.bite.stage = 'post'
        this.bite.orderNumber = this.lastPostBiteOrderNumber + 1
      }
    },
    setDeadlineTypeValue (event) {
      this.biteDeadlineType = event.code
    },
    formValid () {
      return !this.$v.$invalid
    },
    setDeadlineDate (date) {
      this.bite.deadlineTime = date
    },
    setBitePurpose (event) {
      this.bite.purpose = event.code
    },
    setBiteSubtypeOfContent (event) {
      this.bite.subtype = event.code
    },
    setBiteStage (event) {
      this.bite.stage = event.code
    }
  },
  watch: {
    bite: {
      deep: true,
      immediate: false,
      handler (bite, prevBite) {
        if (prevBite) this.$emit('change', bite)
      }
    }
  },
  validations: {
    bite: {
      title: {
        required
      },
      durationMins: {
        required
      },
      purpose: {
        required
      },
      subtype: {
        required: requiredIf(function () {
          return this.hasContentSubtype
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
