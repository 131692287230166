<template>
  <div v-on:click="onClick" class="border border-secondary border-dashed rounded text-center cursor-pointer">
    <input v-on:change="onSelect($event)" :disabled="disabled" ref="fileInputElement" accept="image/png,image/jpeg,image/jpg" type="file" class="d-none"/>
    <img class="w-100" :src="previewSrc" alt="preview image"/>
    <p class="font-weight-bold font-size-lg">Upload image here (Size limit: 6MB)</p>
    <p class="text-secondary">Click to select</p>
    <div v-if="showImageFileSizeWarning" class="py-2">
      <Pill class="pill-notification" style="color: #2c3239">
        <Icon style="color: #ffbb4d;" icon="exclamation-circle" />
        The image file size is too large.
      </Pill>
    </div>
  </div>
</template>

<script>
import Icon from './Icon.vue'
import Pill from './Pill.vue'

export default {
  props: {
    value: null,
    disabled: {
      default: false,
      type: Boolean
    }
  },
  components: {
    Icon,
    Pill
  },
  data () {
    return {
      fileInputElement: null,
      selected: null,
      showImageFileSizeWarning: false
    }
  },

  computed: {
    previewSrc () {
      return this.selected || this.value || 'netherlands-windmill.svg'
    }
  },

  methods: {

    onClick () {
      this.$refs.fileInputElement.click()
    },

    onSelect (event) {
      const fileReader = new FileReader()
      const tempFile = event.srcElement.files[0] || event.target.files[0]
      const blob = tempFile.slice(0, tempFile.size, tempFile.type)
      const file = new File([ blob ], tempFile.name.toLowerCase(), { type: tempFile.type })
      const fileSizeLimit = 6000000
      if (file?.size > fileSizeLimit) {
        this.showImageFileSizeWarning = true
      } else {
        this.showImageFileSizeWarning = false
        fileReader.readAsDataURL(file)
        fileReader.onload = e => {
          this.selected = e.target.result
          this.value = file
          this.$emit('input', file)
        }
      }
    }

  }
}
</script>
