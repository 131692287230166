<template>
  <form @keydown="onKeydown($event)">
    <slot></slot>
  </form>
</template>

<script>
export default {
  methods: {
    onKeydown (event) {
      if (event.ctrlKey && event.keyCode === 13) { this.$emit('submit') }
    }
  }
}
</script>
