<template>
  <textarea
    v-bind:rows="rows"
    v-model="value"
    :placeholder="placeholder"
    :class="classes"
    class="form-control bg-transparent form-control rounded-0"></textarea>
</template>

<script>
import { formGroupInput } from './helpers.js'

export default formGroupInput({
  props: {
    value: String,
    placeholder: String,
    rows: {
      type: Number,
      default: 3
    }
  },

  watch: {
    value (value) {
      this.$emit('input', value)
    }
  },

  computed: {
    classes () {
      return {
        'is-invalid': this.isInvalid
      }
    }
  }

})

</script>
