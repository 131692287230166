<template>
    <select
      :class="`${isInvalid && 'is-invalid'} ${this.value == null && 'select-option-placeholder'} ${customClass || 'rounded-0 form-control bg-transparent'}`"
      v-model="value"
      :disabled="isDisabled"
      >
      <option selected disabled :value="unSelectedValue">{{placeholder}}</option>
      <option v-for="option in options"
              v-bind:key="option.id ? option.id : option.code"
              :disabled="disabled(option)"
              :value="option">{{label(option)}}
      </option>
    </select>
</template>

<script>
import { formGroupInput } from './helpers.js'
import { isNil } from 'ramda'

export default formGroupInput({
  props: {
    placeholder: String,
    value: Object,
    options: Array,
    isDisabled: { type: Boolean, default: false },
    disabled: {
      type: Function,
      default: option => option.disabled
    },
    label: {
      type: Function,
      default: ({ name, title }) => name || title
    },
    customClass: String
  },

  data: () => ({
    unSelectedValue: undefined
  }),

  watch: {
    value (option, prevOption) {
      if (!isNil(option) && (!prevOption || option.code !== prevOption.code || option.id !== prevOption.id)) {
        this.$emit('input', option)
      }
    }
  }
})
</script>

<style scoped>
  select {
    border-width: 0px 0px 1px 0px;
  }
</style>
