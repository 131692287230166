import Fuse from 'fuse.js'
import {
  reverse,
  prop,
  partial,
  path,
  init,
  last,
  isNil,
  identity,
  nthArg,
  sortWith,
  descend
} from 'ramda'
import { Store } from 'vuex'
import Vue from 'vue'
import lepaya from '../http/lepaya.js'
import s3 from '../http/s3.js'

const noop = () => {}

function action (statePath, fn) {
  let actionName

  if (isNil(fn)) { fn = nthArg(1) }

  if (typeof statePath === 'string') { statePath = [ statePath ] }

  function action (context, payload) {
    return Promise.resolve(fn(context, payload))
      .then(payload => {
        context.commit(actionName, payload)

        return payload
      })
  }

  action.install = (store, name) => {
    actionName = name

    mkPath([ 'state', ...statePath ], store, undefined)

    store.mutations[name] = store.mutations[name] || partial(mutatePath, [ statePath ])

    if (statePath.length === 1) { store.getters[statePath[0]] = path(statePath) }

    return store
  }

  return action
}

storeComponent.action = action

/**
 * Removes the need for all the boilerplate when having to define a simple
 * action that assigns a value to the store.
 */
export function storeComponent (store) {
  store.actions = store.actions || {}
  store.getters = store.getters || {}
  store.mutations = store.mutations || {}
  store = Object.keys(store.actions).reduce(
    (acc, name) => (store.actions[name].install || identity)(acc, name),
    store
  )

  return (component) => {
    let storeInstance
    let componentInstance

    const computed = Object.keys(store.getters).reduce((computed, name) => {
      computed[name] = {
        cache: false,
        get: () => storeInstance.state[name]
      }

      return computed
    }, {})

    const methods = Object.keys(store.actions).reduce((methods, name) => {
      methods[name] = payload => storeInstance.dispatch(name, payload)
        .then(result => {
          componentInstance.$forceUpdate()

          return result
        })
        .catch(error => {
          componentInstance.$forceUpdate()

          return Promise.reject(error)
        })
      return methods
    }, {})

    component.computed = {
      ...computed,
      ...component.computed
    }

    component.methods = {
      ...methods,
      ...component.methods
    }

    component.beforeRouteEnter = function beforeRouteEnter (to, from, next) {
      storeInstance = new Store(store)

      storeInstance.dispatch('initialize', to)
        .then(() => next(vm => {
          componentInstance = vm
          return vm
        }), next)
    }

    component.beforeRouteUpdate = function beforeRouteUpdate (to, from, next) {
      storeInstance.dispatch('initialize', to)
        .then(() => next(), next)
    }

    return component
  }
}

export function formGroupInput (component) {
  const originalMounted = component.mounted || noop

  function mounted () {
    this.$nextTick(() => {
      this.$el.dispatchEvent(new CustomEvent('register-input', { detail: this, bubbles: true, composed: true }))
    })

    return originalMounted.call(this)
  }

  const props = Object.assign((component.props || {}), {
    isInvalid: {
      type: Boolean,
      default: false
    }
  })

  return Object.assign(component, {
    props,
    mounted
  })
}

export function windowFocusComponent (component) {
  let onfocus

  component.created = function created () {
    const vm = this

    window.onfocus = () => {
      component.onWindowFocus.call(vm)
    }
  }

  component.destroyed = function destroyed () {
    window.onfocus = onfocus
  }

  return component
}

export function queryResults ({
  searchable,
  orderByColumn = 'id',
  options
}) {
  return (get, queryParams) => {
    const configOptions = {
      shouldSort: options && options.shouldSort ? options.shouldSort : true,
      findAllMatches: options && options.findAllMatches ? options.findAllMatches : true,
      threshold: options && options.threshold ? options.threshold : 0.1,
      location: options && options.location ? options.location : 0,
      distance: options && options.distance ? options.distance : 100,
      minMatchCharLength: options && options.minMatchCharLength ? options.minMatchCharLength : 2,
      includeMatches: options && options.includeMatches ? options.includeMatches : true
    }

    const { order, query } = queryParams
    const orderBy = queryParams['order-by'] ? queryParams['order-by'] : orderByColumn

    return get
      .then(({ data: records }) => {
        const fuse = new Fuse(records, {
          keys: searchable,
          ...configOptions
        })
        let fuseSearch = null
        if (!isNil(query)) { fuseSearch = fuse.search(query).map(entry => entry.item) }

        // Sorting will be in descending order by default
        const result = sortWith([ descend(prop(orderBy)) ], isNil(fuseSearch) ? records : fuseSearch)

        if (order === 'asc') { return reverse(result) }
        return result
      })
  }
}

export function routerComponent (component) {
  component.beforeRouteUpdate = component.beforeRouteUpdate || function beforeRouteUpdate (to, from, next) {
    component.beforeRouteEnter(to, from, fn => fn(this))
    next()
  }

  return component
}

function mkPath (propertyPath, object) {
  return propertyPath.reduce(([ path, acc ], key) => {
    path = [ ...path, key ]

    mutatePath(path, acc, {})

    return [ path, acc ]
  }, [ [], object || {} ])[1]
}

function mutatePath (propertyPath, object, value) {
  const propertyObjectPath = init(propertyPath)
  const propertyName = last(propertyPath)

  Vue.set(
    path(propertyObjectPath, object),
    propertyName,
    value
  )
}

export function uploadImage (file, companyCode, content) {
  const filename = file.name
  const key = `companies/${companyCode}/${content}/images/${filename}`
  const headers = {
    'Content-Type': file.type
  }

  return lepaya.post(`companies/${companyCode}/files`, { key })
    .then(({ data: { url, key } }) => s3({
      url: url,
      method: 'put',
      data: file,
      headers
    }).then(() => key))
    // eslint-disable-next-line prefer-promise-reject-errors
    .catch(() => Promise.reject({
      response: { data: { image: [ 'Failed to upload file. Either select another file or save without a file' ] } }
    }))
}
