export default {
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN PROGRESS',
  MICROSOFT_TENANT_ID_NOT_SET: 'INCOMPLETE SETUP - (missing company AAD tenant ID)',
  MS_TEAMS_PERMISSION_NEEDED: 'INCOMPLETE SETUP - (permissions not granted)',
  MS_TEAMS_TEAM_OWNER_NEEDED: 'INCOMPLETE SETUP - (no valid team-owner found for wave)',
  MS_TEAMS_WAVE_READY_TO_SYNC: 'READY TO SYNC',
  MS_TEAMS_WAVE_READY_TO_RESYNC: 'READY TO RESYNC - (wave has been updated)',
  MS_TEAMS_WAVE_NOT_SYNCED_YET: 'WAVE NOT SYNCED YET'
}
