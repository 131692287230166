import lepaya from '@/http/lepaya'

export default {
  createSkillScan (data) {
    const { companyCode, programId } = data
    return lepaya.post(`/companies/${companyCode}/waves/${programId}/skill-scan`)
  },
  fetchWaveSyncEvents (waveId) {
    return lepaya.get(`/domain-events/wave/${waveId}`)
  }
}
