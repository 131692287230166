<template>
  <Modal
  v-if="showSentEmailModal"
  :on-escape="onCloseSentEmailPreview"
  :on-close="onCloseSentEmailPreview"
  :fullscreen="true"
  >
    <template v-slot:header>
      <p class="p-3 sent-view-modal">Sent Email HTML</p>
    </template>
    <div class="m-4 border">
      <div class="iframe-wrapper">
        <iframe :srcdoc="setSentEmailData.bodyHtml"></iframe>
      </div>
    </div>
    <ButtonsBar class="w-content m-4">
      <template slot="right">
        <Button  @click="onCloseSentEmailPreview" class="mb-4 mt-4 ml-3 p-4 font-weight-bold font-size-lg">Close</Button>
      </template>
    </ButtonsBar>

  </Modal>
</template>

<script>
import Button from '@/components/Button.vue'
import ButtonsBar from '@/components/ButtonsBar.vue'
import Modal from '@/components/Modal.vue'

export default {
  name: 'ViewSentEmailModal',
  components: {
    Modal,
    ButtonsBar,
    Button
  },

  computed: {
    setSentEmailData () { return this.$store.state.comm.sentEmailData },
    showSentEmailModal () { return this.$store.state.comm.showSentEmailModal }
  },
  methods: {
    onCloseSentEmailPreview () {
      this.$store.commit('comm/setShowSentEmailModal', false)
      this.$store.commit('comm/setSentEmailData', null)
    }
  }
}
</script>

<style scoped>
  .sent-view-modal {
    align-self: center;
    font-size: 30px;
  }
  .iframe-wrapper {
    margin: 4px;
    position: relative;
    padding-bottom: 50vh;
    height: 0;
  }
  .iframe-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
