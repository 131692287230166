<template>
  <Button @click="$store.dispatch(CommActions.SendTestEmail)" type="outline-secondary" size="lg" class="font-weight-bold p-4 rounded mr-auto">
    <Icon class="mr-2" icon="location-arrow"/> Send a test email to myself
  </Button>
</template>

<script>
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import CommActions from '@/vuex/modules/comm/actions'

export default {
  name: 'SendNowButton',
  components: { Button, Icon },
  data: () => ({ CommActions })
}
</script>

<style scoped>
</style>
