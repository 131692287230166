<template>
    <div  class="sent-view-container">
        <SentViewSection stepTitle='Setup'>
            <div class="confirmation-info">
                <ConfirmationFields label="Template Name" :value="mailData.selectedTemplate.name"/>
                <ConfirmationFields label="Subject Line" :value="mailData.subjectLine"/>
                <ConfirmationFields label="Preview Text" :value="mailData.previewText"/>
                <ConfirmationFields label="Sender Email" :value="mailData.senderEmail"/>
                <ConfirmationFields label="Sender Name" :value="mailData.fromName"/>
            </div>
        </SentViewSection>
        <SentViewSection stepTitle='Sent Emails'>
            <Table
                    :all-disabled="true"
                    :hide-select-all="true"
                    :records="filterRecipients(this.$store.state.comm.viewData.recipients.slice())"
                    :columns="fields">
            </Table>
        </SentViewSection>
        <SentViewSection stepTitle='Customization'>
            <div class="confirmation-no-variables" v-if="!customVariables.length">
                <p class="confirmation-no-variables_info">There were no custom variables</p>
            </div>
            <div class="confirmation-info" v-else>
                <div v-for="variable in customVariables" :key="variable.path">
                    <ConfirmationFields :label="camelToSentence(variable.path)" :value="variable.value"/>
                    <ConfirmationFields v-if="attachments" label="Attached file" :value="attachments"/>
                </div>
            </div>
        </SentViewSection>
        <SentViewSection  stepTitle='Scheduling'>
            <div class="confirmation-info">
                <ConfirmationFields
                        :class="{confirmation_send_date:immediateScheduleType}"
                        label="Was Scheduled for"
                        :value="sendDateTime"/>
                <ConfirmationFields
                        v-if="mailData.classroomStart"
                        label="First Classroom Date"
                        :value="mailData.classroomStart"/>
            </div>
        </SentViewSection>
    </div>
</template>

<script>

import Button from '@/components/Button.vue'
import Table from '@/components/Table.vue'
import ConfirmationFields from '@/views/communication/ConfirmationFields.vue'
import SentViewSection from '@/views/communication/SentViewSection.vue'
import moment from 'moment'
import { values } from 'ramda'

export default {
  name: 'SentCommunicationOverview',
  props: [],
  components: { SentViewSection, ConfirmationFields, Table },
  computed: {
    mailData () {
      return this.$store.state.comm.viewData.data.communicationDetails
    },
    immediateScheduleType () {
      return this.$store.state.comm.viewData.data.communicationDetails.scheduleType === 'IMMEDIATE'
    },
    sendDateTime () {
      return this.immediateScheduleType ? 'Was sent immediately' : this.$store.state.comm.data.sendDateTime
    },
    customVariables () {
      return this.$store.state.comm.viewData.data.communicationDetails.customVariables
    },
    attachments () {
      return this.$store.state.comm.data.attachments
    },
    fields () {
      return values({
        firstName: {
          name: 'First Name',
          value: ({ firstName }) => firstName
        },
        lastName: {
          name: 'Last Name',
          value: ({ lastName }) => lastName
        },
        email: {
          name: 'Email',
          value: ({ email }) => email
        },
        sentAt: {
          name: 'Sent at',
          value: ({ sentAt }) => moment(sentAt).format('DD/MM/YYYY HH:mm:ss')
        },
        html: {
          name: 'html',
          component: () => Button,
          props: ({ ongoingProgramEmailArchivesId }) => ({
            text: 'View Email',
            class: {
              'text-uppercase': true
            },
            type: 'info',
            onClick: function () {
              const sending = this.$store.state.comm.viewData.sendings.filter(s => s.id === ongoingProgramEmailArchivesId)
              if (sending.length) {
                this.$store.commit('comm/setSentEmailData', sending.pop())
                this.$store.commit('comm/setShowSentEmailModal', true)
              }
            }
          })
        },
        status: {
          name: 'Status',
          value: ({ status }) => status
        }
      })
    }
  },
  methods: {
    camelToSentence (text) {
      return text.replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase())
    },
    filterRecipients (recipients) {
      if (recipients) {
        recipients = recipients.filter(recipient => recipient.status != null)
      }
      return recipients
    }
  }
}
</script>

<style scoped>
    .sent-view-container {
        padding: 2em;
    }
</style>
