<template>
  <div class="justify-content">
    <a @click="editClassroom" v-if="!this.currentModule.sfid">
      <Icon class="text-primary text-center mx-2 font-size-md" icon="pen" alt="Edit"/>
    </a>
    <a @click="showStartUrlModal" v-if="showZoomStartUrlButton">
      <Icon class="text-primary text-center mx-2 font-size-md" icon="link"/>
    </a>
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ClassroomActions',
  components: {
    Icon
  },
  methods: {
    editClassroom () {
      this.$router.push({
        name: 'editClassroom',
        params: {
          classroomId: this.classroom.id,
          isCalendarEnabled: this.currentModule.isCalendarEnabled
        }
      })
    },
    showStartUrlModal () {
      this.$store.commit('classroom/setShowZoomStartLinkModal', true)
    }
  },
  computed: {
    ...mapGetters({
      classroom: 'classroom/classroom',
      currentModule: 'modules/currentModule'
    }),
    showZoomStartUrlButton () {
      return this.classroom.location.classroomType === 'zoom'
    }
  }
}
</script>

<style scoped>
</style>
