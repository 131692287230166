
export const COMM_TYPE_PROGRAM_ANNOUNCEMENT = 1
export const COMM_TYPE_PROGRAM_ANNOUNCEMENT_FOR_MANAGERS = 2
export const COMM_TYPE_CUSTOM = 3
export const COMM_TYPE_MANAGER_PACKAGE = 4
export const COMM_TYPE_TRAINER_PREPARATION = 5

export const COMM_SENDER_EMAIL = process.env.VUE_APP_SENDER_EMAIL

const communicationTypes = [
  {
    id: COMM_TYPE_PROGRAM_ANNOUNCEMENT,
    name: 'Program Announcement',
    description: 'Announce the start of a program to participating employees',
    defaultRoles: [ 'employee', 'content_manager' ],
    templateFilters: [ 'learner', 'PA' ],
    defaultEmailTemplate: 'learner'
  },
  {
    id: COMM_TYPE_PROGRAM_ANNOUNCEMENT_FOR_MANAGERS,
    name: 'Program Announcement for Managers',
    description: 'Announce the start of a program to the manager of participants',
    defaultRoles: [ 'manager' ],
    templateFilters: [ 'manager', 'PA' ],
    defaultEmailTemplate: 'manager'
  },
  {
    id: COMM_TYPE_CUSTOM,
    name: 'Custom',
    description: 'Create an email, starting from scratch',
    defaultRoles: [],
    templateFilters: [],
    defaultEmailTemplate: null
  },
  {
    id: COMM_TYPE_MANAGER_PACKAGE,
    name: 'Manager Package',
    description: 'Information package for the managers of participants',
    defaultRoles: [ 'manager' ],
    templateFilters: [ 'manager', 'MP' ],
    defaultEmailTemplate: 'manager'
  },
  {
    id: COMM_TYPE_TRAINER_PREPARATION,
    name: 'Trainer Preparation',
    description: 'Information for the trainers',
    defaultRoles: [ 'support' ],
    templateFilters: [ 'trainer' ]
  }
]

export const communicationTypeMap = {
  program_announcement: COMM_TYPE_PROGRAM_ANNOUNCEMENT,
  managers_program_announcement: COMM_TYPE_PROGRAM_ANNOUNCEMENT_FOR_MANAGERS,
  custom: COMM_TYPE_CUSTOM,
  manager_package: COMM_TYPE_MANAGER_PACKAGE,
  trainer_preparation: COMM_TYPE_TRAINER_PREPARATION
}

async function processCommunicationTypes () {
  return communicationTypes
}

export default processCommunicationTypes
