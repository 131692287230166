<template>
  <Modal style="color: #2c3239;">
    <template slot="header">
      <div class="duplicate-title">
        <div>Duplicate bites into existing module in <b>{{this.$route.params.duplicateToCompanyName}}</b></div>
      </div>
    </template>
    <div>
      <div style="padding: 0 30px" class="d-flex py-3 align-items-center justify-content-between">
        <div style="font-size: 24px;">Select a module</div>
        <div class="input-group search">
          <div class="input-group-prepend">
            <div class="input-group-prepend-text d-flex align-items-center">
              <Icon style="color: #c6c6d3; margin-right: 5px;" icon="search"/>
            </div>
          </div>
          <input v-on:input="onSearch(query)" v-model="query" class="form-control bg-transparent form-control border-0" type="search"
                 placeholder="Search">
        </div>
      </div>
        <Table
          :maxItems="1"
          :columns="journeyColums"
          v-model="selectedJourney"
          :records="waveJourneys"/>
    </div>

      <ButtonsBar class="font-size-lg">
        <template slot="left">
          <Button
            :on-click="goBack"
            type="link"
            class="font-weight-bold p-4 border-white">
            Go back
          </Button>
        </template>
        <template slot="right">
          <Button
            size='m'
            type="primary"
            class="font-weight-bold p-4 border-white"
            @click="onBiteDuplication"
            :disabled="buttonDisabled">Duplicate Bites
          </Button>
        </template>
      </ButtonsBar>
  </Modal>
</template>

<script>
import Badge from '../components/Badge.vue'
import Button from '../components/Button.vue'
import ButtonsBar from '../components/ButtonsBar.vue'
import Emphasis from '../components/Emphasis'
import Icon from '../components/Icon.vue'
import IconText from '../components/IconText.vue'
import Modal from '../components/Modal.vue'
import Span from '../components/Span.vue'
import Table from '../components/Table.vue'

import { queryResults } from '../components/helpers.js'
import lepaya from '../http/lepaya.js'
import { isNil, isEmpty } from 'ramda'

const journeyColums = {
  title: {
    name: 'Module Name',
    value: ({ title }) => title
  },
  program: {
    name: 'Company Program',
    value: ({ programName }) => programName
  },
  originalProgramName: {
    name: 'Lepaya Program',
    value: ({ originalProgramName }) => originalProgramName
  },
  wave: {
    name: 'Wave',
    value: ({ waveName }) => waveName
  },
  status: {
    name: 'Status',
    component: () => Badge,
    props: ({ status }) => ({
      text: status,
      class: {
        'text-uppercase': true
      },
      type: status === 'draft' ? 'secondary'
        : status === 'archived' ? 'grey'
          : 'success'
    })
  },
  type: {
    name: 'Type',
    component: () => Emphasis,
    props: ({ type: text }) => ({
      text,
      class: {
        'text-success': text === 'mandatory',
        'text-uppercase': true
      }
    })
  },
  bites: {
    name: 'Bites',
    component: () => IconText,
    props: ({ biteCount }) => ({
      text: biteCount,
      icon: 'cubes'
    })
  },
  participants: {
    name: 'Participants',
    component: () => IconText,
    props: ({ employeeCount: text }) => ({
      text,
      icon: 'users'
    })
  },
  startDate: {
    name: 'Start Date',
    component: () => Span,
    props: ({ startDateString, startDate }) => ({
      text: startDateString,
      type: !isNil(startDate) && new Date() > new Date(startDate)
        ? 'grey'
        : 'orange'
    })
  },
  endDate: {
    name: 'End Date',
    component: () => Span,
    props: ({ endDateString, endDate }) => ({
      text: endDateString,
      type: !isNil(endDate) && new Date() > new Date(endDate)
        ? 'grey'
        : 'orange'
    })
  }
}

export default {
  components: {
    Button,
    ButtonsBar,
    Modal,
    Table,
    Icon
  },

  data: () => ({
    journeyColums,
    duplicateToCompanyCode: null,
    duplicateToCompanyName: null,
    waveId: null,
    waveJourneys: [],
    selectedJourney: [],
    programCatalogueItemId: null,
    query: ''
  }),

  computed: {
    buttonDisabled () {
      return Boolean(!this.selectedJourney.id)
    }
  },

  methods: {
    goBack () {
      return this.$router.back()
    },

    onSearch (input) {
      query(lepaya.get(`/companies/${this.duplicateToCompanyCode}/programs/${this.waveId}/journeys`)
        , { query: input || undefined })
        .then(waveJourneys => {
          this.waveJourneys = waveJourneys
          if (!isEmpty(this.selectedJourney) && !waveJourneys.find(waveJourney => this.selectedJourney.id === waveJourney.id)) { this.selectedJourney = [] }
        })
    },

    onBiteDuplication () {
      const body = {
        companyCode: this.duplicateToCompanyCode,
        journeyId: this.selectedJourney.id
      }
      const journeyId = this.$route.params.journeyIdFrom
      lepaya.post(`/companies/${this.$route.params.companyCode}/journeys/${journeyId}/duplicate-bites`, body)
        .then(() => {
          this.$router.push({
            name: 'editProgram',
            query: { biteDuplication: true, id: this.selectedJourney.id },
            params: {
              programCatalogueItemId: this.programCatalogueItemId,
              id: this.waveId,
              companyCode: this.duplicateToCompanyCode
            }
          })
        })
        .catch(({ response: { data: errors } }) => {
          this.errors = errors
        })
    }
  },

  beforeRouteEnter (to, from, next) {
    lepaya.get(`/companies/${to.query.companyCode}/programs/${to.query.id}/journeys`)
      .then(({ data: waveJourneys }) => {
        next(vm => {
          vm.waveJourneys = waveJourneys
          vm.duplicateToCompanyCode = to.query.companyCode
          vm.waveId = to.query.id
          vm.programCatalogueItemId = to.query.programCatalogueItemId
        })
      })
  }
}

const query = queryResults({
  searchable: [ 'title' ]
})
</script>

<style scoped>
  .duplicate-title {
    font-size: 24px;
    padding: 20px 30px;
  }

  .select .vs__dropdown-menu ::placeholder {
    color: #c6c6d3;
  }

</style>
