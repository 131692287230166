<template>
    <div>
        <div class="controls-row">
          <div class="buttons-row">
            <EditorIcon @click="() => this.$refs.varsModal.show()" icon="star" title="Insert template Variable" />
            <EditorIcon @click="undo" icon="arrow-left" title="Undo" />
            <EditorIcon @click="redo" icon="arrow-right" title="Redo" />
            <EditorIcon @click="bold" :class="{'pressed': boldPressed}" icon="bold" title="Bold" />
            <EditorIcon @click="italic" :class="{'pressed': italicPressed}" icon="italic" title="Italic" />
            <EditorIcon @click="underline" :class="{'pressed': underlinePressed}" icon="underline" title="Underline" />
            <EditorIcon @click="eraser" icon="trash" title="Remove selection"/>
          </div>
          <div v-if="errMsg" class="err-msg" v-html="errMsg"/>
        </div>
        <div class="iframe-wrapper">
            <iframe ref="editorFrame" class="editor-frame"  :style="{ border: errMsg ? `1px solid red` : '' }" src="" srcdoc=""/>
        </div>
        <b-modal ref="varsModal" hide-footer title="Select template Var">
            <button class="template-var-button" v-for="(path, index) in templateVarList" :key="index"
                    @click="selectTemplateVar(path)">{{path}}
            </button>
            <br/>
        </b-modal>
    </div>
</template>

<script>
import EditorIcon from '@/views/communication/EditorIcon.vue'
import { backendTemplateVariables, htmlTemplateVariables } from '@/views/communication/templateVariables'
import { parse } from '@handlebars/parser'

export default {
  name: 'Editor',
  props: [ 'content' ],
  components: { EditorIcon },
  data () {
    return {
      boldPressed: false,
      italicPressed: false,
      underlinePressed: false,
      errMsg: ''
    }
  },
  mounted () {
    window.modalRef = this.$refs.varsModal
    this.$refs.editorFrame.onload = () => {
      this.$refs.editorFrame.contentDocument.write(this.$props.content.toString())
      this.$refs.editorFrame.contentDocument.designMode = 'on'
      this.$refs.editorFrame.contentDocument.body.contentEditable = 'true'
      this.$refs.editorFrame.contentDocument.onkeyup = this.onEditorChange.bind(this)
      this.$refs.editorFrame.contentDocument.onfocus = this.configureMenuState.bind(this)
    }
  },
  computed: {
    templateVarList () {
      const list = this.$store.state.comm.data.customVariables.map(i => i.path)
      return list.concat(backendTemplateVariables).sort()
    },
    win () {
      return this.$refs.editorFrame.contentWindow
    },
    doc () {
      return this.$refs.editorFrame.contentDocument
    }
  },
  methods: {
    selectTemplateVar (path) {
      if (htmlTemplateVariables.includes(path)) {
        this.doc.execCommand('insertText', false, `{{{${path}}}}`)
      } else {
        this.doc.execCommand('insertText', false, `{{${path}}}`)
      }
      this.$refs.varsModal.hide()
      this.triggerChangeEvent()
    },
    onEditorChange (e) {
      e.stopPropagation()
      this.triggerChangeEvent()
    },
    triggerChangeEvent () {
      try {
        const editedHtml = this.fixupVariables(this.createFullHtmlFromDoc(this.$refs.editorFrame.contentDocument))
        parse(editedHtml)
        this.$data.errMsg = ''
        this.$emit('change', editedHtml)
        this.$emit('templateStatus', true)
      } catch (e) {
        this.$data.errMsg = 'Template not valid'
        this.$emit('templateStatus', false)
      }
    },
    fixupVariables (text) {
      let html = text
      htmlTemplateVariables.forEach(tempVar => {
        const pattern = '(?<!\\{)[{]{2}(\\s*)((\\&nbsp\\;)*)(\\s*)(' + tempVar + ')(\\s*)((\\&nbsp\\;)*)(\\s*)[\\}]{2}(?!\\})'
        const reggie = new RegExp(pattern, 'g')
        html = html.replace(reggie, `{{{${tempVar}}}}`)
      })

      return html
    },
    createFullHtmlFromDoc (doc) {
      let fhtml = ''
      // recreate doctype tag
      fhtml += `<!DOCTYPE ${doc.doctype ? doc.doctype.name : ''}>`
      const htmlAttributes = Array.from(doc.children[0].attributes).reduce((all, a) => all + ` ${a.name}=${a.textContent}`, '')
      // recreate html tag
      fhtml += '<html ' + htmlAttributes + ' >'
      // fill head and body from the editors document
      fhtml += '<head>' + doc.head.innerHTML + '</head>'
      fhtml += '<body>' + doc.body.innerHTML + '</body>'
      fhtml += '</html>'

      return fhtml
    },
    undo () {
      this.doc.execCommand('undo')
      this.triggerChangeEvent()
    },
    redo () {
      this.doc.execCommand('redo')
      this.triggerChangeEvent()
    },
    eraser () {
      const w = this.win
      if (w.getSelection().anchorNode.parentNode) {
        w.getSelection().anchorNode.parentNode.removeChild(w.getSelection().anchorNode)
        this.triggerChangeEvent()
      }
    },
    bold () {
      this.doc.execCommand('bold')
      this.$data.boldPressed = this.doc.queryCommandState('bold')
    },
    italic () {
      this.doc.execCommand('italic')
      this.$data.italicPressed = this.doc.queryCommandState('italic')
    },
    underline () {
      this.doc.execCommand('underline')
      this.$data.underlinePressed = this.doc.queryCommandState('underline')
    },
    configureMenuState () {
      this.$data.boldPressed = this.doc.queryCommandState('bold')
      this.$data.italicPressed = this.doc.queryCommandState('italic')
      this.$data.underlinePressed = this.doc.queryCommandState('underline')
    }
  }
}
</script>

<style scoped>
    .editor-frame {
        width: 100%;
        height: 100%;
    }

    .iframe-wrapper {
        margin: 4px;
        position: relative;
        padding-bottom: 50vh;
        height: 0;
        border: 1px solid #000;
    }

    .iframe-wrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }

    .buttons-row {
        display: flex;
    }

    .template-var-button {
        cursor: pointer;
        width: 100%;
        margin-top: 5px;
    }

    .controls-row {
        display: flex;
        justify-content: space-between;
    }

    .err-msg {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #c6001c;
        background-color: #c6001c;
        color: white;
        padding-left: 1em;
        padding-right: 1em;
        margin-right: 4px;
        margin-bottom: -5px;
    }
</style>
