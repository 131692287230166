/* eslint-disable no-console */
import { SplitFactory } from '@splitsoftware/splitio'
import { isNil } from 'ramda'

class SingletonClass {
  constructor () {
    const factory = SplitFactory({
      core: {
        authorizationKey: process.env.VUE_APP_SPLIT_AUTHORIZATION_KEY,
        key: process.env.VUE_APP_SPLIT_USER
      },
      startup: {
        readyTimeout: 31
      }
    })
    this.client = new Promise((resolve, reject) => {
      const newClient = factory.client()
      console.info('SPLIT.io SDK called', new Date().toISOString())
      newClient
        .once(newClient.Event.SDK_READY, () => {
          console.info('SPLIT.io SDK_READY at ', new Date().toISOString())
          resolve(newClient)
        })
        .once(newClient.Event.SDK_READY_TIMED_OUT, error => {
          console.error(error)
          reject(error)
        })
    })
  }
}

export const SplitFactorySingleton =
  Object.freeze(() => {
    let instance
    return {
      getClient: async () => {
        if (isNil(instance)) {
          instance = new SingletonClass()
        }
        return await instance.client
      }
    }
  })()
