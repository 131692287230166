import lepaya from '../http/lepaya.js'
import { storeComponent, uploadImage } from '../components/helpers.js'
import { isNil, clone } from 'ramda'
import { format, trimObjectWhitespace } from '../lib/format.js'
import store from '../vuex'

const action = storeComponent.action

export default storeComponent({
  actions: {
    getJourney: action([ 'journey' ], getJourney),
    getJourneyEvents: action([ 'journeyEvents' ], getJourneyEvents),
    getJourneyParticipants: action([ 'journeyParticipants' ], getJourneyParticipants),
    setJourneyImage: action([ 'journey', 'image' ]),
    setJourneyTitle: action([ 'journey', 'title' ]),
    setJourneyCatalogueCode: action([ 'journey', 'catalogueCode' ]),
    setJourneyEndTime: action([ 'journey', 'endTime' ]),
    setJourneyRelativeEndTime: action([ 'journey', 'relativeEndTime' ]),
    setJourneyStatus: action([ 'journey', 'status' ]),
    setJourneyIsOptional: action([ 'journey', 'isOptional' ]),
    setJourneyErrors: action([ 'journeyErrors' ], setJourneyErrors),
    setInvitesSuccess: action([ 'invitesSuccess' ]),
    setOriginalJourney: action([ 'originalJourney' ]),
    setJourneyDeadline: action([ 'journey' ], setJourneyDeadline),
    setJourney: action([ 'journey' ]),
    formatJourney: action([ 'journey' ], (context, journey) => {
      return formatJourney(journey)
    }),

    initialize,
    persistJourney
  }
})

function initialize (context, to) {
  return Promise.all([
    context.dispatch('getJourney', to),
    context.dispatch('getJourneyEvents', to.params),
    context.dispatch('getJourneyParticipants', to.params),
    context.dispatch('setJourneyErrors', [])
  ])
}

function getJourney (context, to) {
  const { id, companyCode } = to.params
  return !isNil(id)
    ? lepaya.get(`/${companyCode}/journeys/${id}`)
      .then(({ data: journey }) => {
        // eslint-disable-next-line no-unused-expressions
        ({
          ...journey,
          endTime: new Date(journey.endTime)
        })
        to.query.invitesSuccess ? context.dispatch('setInvitesSuccess', true) : context.dispatch('setInvitesSuccess', false)
        context.dispatch('setOriginalJourney', clone(journey))
        store.commit('modules/setCurrentModule', journey)
        store.commit('bites/setJourneyIsPublished', journey.isPublished)
        return journey
      })
    : {
        id: null,
        isOptional: false,
        kickoff: false,
        progress: true,
        endTime: new Date()
      }
}

function getJourneyEvents (_, { id, companyCode }) {
  return !isNil(id)
    ? lepaya.get(`/companies/${companyCode}/journeys/${id}/events`)
      .then(({ data: journeyEvents }) => journeyEvents.reduce((acc, event) => {
        if (event.type === 'classroom') {
          acc.push(event.details)
          store.commit('classroom/setClassroom', event.details)
        }
        return acc
      }, []))
    : []
}

function getJourneyParticipants (_, { id, companyCode }) {
  return !isNil(id)
    ? lepaya.get(`companies/${companyCode}/journeys/${id}/participants`)
      .then(({ data: journeyParticipants }) => {
        return journeyParticipants
      })
    : []
}

function persistJourney (context, payload) {
  const journey = { ...payload.journey }

  if (isNil(journey.endTime) && !isNil(journey.relativeEndTime)) { delete journey.endTime } else {
    delete journey.relativeEndTime
    if (journey.endTime instanceof Date) {
      journey.endTime = journey.endTime.getTime()
    }
  }

  payload = { ...payload, journey }

  return (updateJourney(context, payload))
    .catch(({ response: { data: errors } }) => {
      context.dispatch('setJourneyErrors', errors)
      return Promise.reject(errors)
    })
    .finally(() => {
      store.commit('modules/setLoading', false)
      setTimeout(() => { store.commit('modules/setModuleUpdateSuccessMessage', null) }, 2000)
      setTimeout(() => { store.commit('modules/setModuleUpdateErrorMessage', null) }, 5000)
    })
}

function updateJourney (context, payload) {
  const { companyCode, journey } = payload
  store.commit('modules/setLoading', true)
  return (journey.image instanceof File ? uploadImage(journey.image, companyCode, 'journeys') : Promise.resolve(undefined))
    .then(key => {
      lepaya.put(`${companyCode}/journeys/${journey.id}`, { ...journey, imageUrl: key })
        .then(() => {
          store.commit('modules/setModuleUpdateSuccessMessage', 'Module was successfully updated')
          return journey
        })
    })
    .catch(({ response: { data: errors } }) => store.commit('modules/setModuleUpdateErrorMessage', errors?.image ? errors.image[0] : 'Could not update module'))
}

function setJourneyDeadline (context, option) {
  const deadline = option.isFixed
    ? { endTime: (new Date()), relativeEndTime: null }
    : { relativeEndTime: 1, endTime: null }

  return {
    ...context.state.journey,
    ...deadline
  }
}

const formatJourney = format([
  trimObjectWhitespace
])

function setJourneyErrors (context, errors) {
  return ajvErrorsByProperty(errors)
}

function ajvErrorsByProperty (errors) {
  return errors.reduce((acc, error) => {
    if (error.keyword === 'required') {
      return {
        [error.params.missingProperty]: [ ...(acc[error.params.missingProperty] || []), error.message ],
        ...acc
      }
    }

    return acc
  }, {})
}
