import { SplitFactorySingleton } from './singleton.js'

export async function isFeatureEnabled (treatmentName) {
  try {
    const client = await SplitFactorySingleton.getClient()
    const treatment = client.getTreatment(treatmentName)
    if (treatment === 'on') {
      // eslint-disable-next-line no-console
      console.info(`[Feature flags] feature ${treatmentName} is ON`)
      return true
    } else if (treatment === 'off') {
      // eslint-disable-next-line no-console
      console.info(`[Feature flags] feature ${treatmentName} is OFF`)
      return false
    } else {
      // eslint-disable-next-line no-console
      console.error(`[Feature flags] Split status absent for feature ${treatmentName}`)
      return false
    }
  } catch (e) {
    return false
  }
}
