<template>
  <div class="company-programs-list-view" ref="companyProgramsListView">
<!--    <b-input placeholder="Search"></b-input>-->
    <h5 class="company-programs-list-title">Programs</h5>

    <div v-if="loadError" class="error-container">{{ loadError }}</div>
    <div v-else class="company-programs-list">
      <ProgramSection v-for="program in list" :key="program.id" :program="program" :expanded="expandedProgramIds.includes(program.id)" />
    </div>
    <div class="spinner-container" v-if="isLoading">
      <b-spinner variant="primary" label="Loading" />
    </div>
    <div class="company-program-more-btn">
      <b-button v-if="list.length >= perPage && page > 0 && !isLoading" variant="secondary" @click='morePrograms' >More</b-button>
    </div>
  </div>
</template>

<script>
import FormGroup from '@/components/FormGroup.vue'
import Label from '@/components/Label.vue'
import ProgramSection from '@/components/program/ProgramSection.vue'
import { ProgramsActions } from '@/vuex/modules/programs/actions'
import { ProgramsGetters } from '@/vuex/modules/programs/getters'
import { PROGRAMS_NAMESPACE } from '@/vuex/modules/programs/programs.state'
import { nextTick } from 'vue'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CompanyProgramsList',
  mixins: [ validationMixin ],
  components: { Label, FormGroup, ProgramSection },
  data () {
    return {
      newProgramType: null,
      newProgramName: ''
    }
  },
  async mounted () {
    await this.refetchPrograms()
    nextTick(() => {
      // the actual scrolling happens on the parent of the referenced div
      this.$refs.companyProgramsListView.parentElement.scrollTop = this.programsScrollTop
      window.addEventListener('scroll', this.handleScroll, true)
    })
  },
  watch: {
    $route (to, from) {
      this.refetchPrograms()
    }
  },
  created () {
    this.fetchLanguages()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  validations: {
    newProgramName: {
      required
    }
  },
  methods: {
    ...mapActions(PROGRAMS_NAMESPACE, {
      fetchProgramsList: ProgramsActions.fetchProgramsList,
      fetchProgramTypes: ProgramsActions.fetchProgramTypes,
      fetchMsTeamsSyncStatus: ProgramsActions.fetchMsTeamsSyncStatus,
      createNewProgram: ProgramsActions.createNewProgram,
      fetchLanguages: ProgramsActions.fetchLanguages,
      setProgramsScrollTop: ProgramsActions.setProgramsScrollTop,
      setPage: ProgramsActions.setPage
    }),
    morePrograms () {
      const { companyCode } = this.$route.params
      this.fetchProgramsList({ companyCode, page: this.page + 1, perPage: this.perPage })
      this.setPage({ page: this.page + 1 })
    },
    fetchPrograms () {
      const { companyCode } = this.$route.params
      this.fetchProgramsList({ companyCode, page: this.page, perPage: this.perPage })
      this.fetchMsTeamsSyncStatus({ companyCode })
      return this.fetchProgramTypes({ companyCode }).then(() => {
        this.$data.newProgramType = this.programTypeOptions.slice().shift() ? this.programTypeOptions.slice().shift().value : null
      })
    },
    async refetchPrograms () {
      this.setPage({ page: 1 })
      await this.fetchPrograms()
      this.$v.newProgramName.$model = this.programTypes.slice().shift()?.name ?? ''
      this.$v.newProgramName.$touch()
      this.resetNewProgramModal()
    },
    resetNewProgramModal () {
      this.$refs['add-program-modal'].hide()
      this.newProgramType = this.programTypes.slice().shift()?.id
      this.$v.newProgramName.$model = this.programTypes.slice().shift()?.name ?? ''
      this.$v.newProgramName.$touch()
    },
    async submitNewProgram () {
      const { companyCode } = this.$route.params
      try {
        await this.createNewProgram({
          companyCode,
          programTypeId: this.$data.newProgramType,
          newProgramName: this.$v.newProgramName.$model
        })
        this.resetNewProgramModal()
        this.setToast('Program succesfully created!')
        this.refetchPrograms()
      } catch (e) {
        this.setToast(e.toString(), true)
      }
    },
    setToast (txt, isError = false) {
      this.$bvToast.toast(txt, {
        title: isError ? 'Error' : 'Success',
        autoHideDelay: 2000,
        appendToast: true,
        variant: isError ? 'danger' : 'success'
      })
    },
    onProgramTypeSelect (programTypeId) {
      const selectedProgramType = this.programTypes.filter(t => t.id === programTypeId).pop()
      if (selectedProgramType) {
        this.newProgramName = selectedProgramType.name
      }
    },
    handleScroll (event) {
      this.setProgramsScrollTop({ top: event.srcElement.scrollTop })
    }
  },
  computed: {
    ...mapGetters(PROGRAMS_NAMESPACE, {
      isLoading: ProgramsGetters.isLoading,
      list: ProgramsGetters.programsList,
      programTypes: ProgramsGetters.programTypes,
      loadError: ProgramsGetters.error,
      expandedProgramIds: ProgramsGetters.expandedProgramIds,
      programsScrollTop: ProgramsGetters.programsScrollTop,
      page: ProgramsGetters.page,
      perPage: ProgramsGetters.perPage
    }),
    programTypeOptions () {
      return this.programTypes.map(type => ({ value: type.id, text: type.name }))
    }
  }
}
</script>

<style lang="scss" scoped>
.company-programs-list-view {
  display: flex;
  flex-direction: column;
  padding: 2em;
  gap: 15px;
}

.company-programs-list-title {
  font-weight: 800;
}

.controls-bar {
  display: flex;
  justify-content: flex-end;
  margin-right: 1em;
  gap: 1rem;
}

.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3em;
}

.company-program-more-btn {
  display: flex;
  justify-content: center;
  button: {
    width: 30px;
  }
}

</style>
