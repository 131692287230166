<template>
  <div class="section" :class="{'section-expanded': visible}" >
    <div class="section-header" @click.stop >
    <span v-if="selectable && id" class="program-section-header-checkbox">
        <b-form-checkbox
            :id="`collapsible-checkbox-id-${id}`"
            v-model="checkboxSelection"
            :name="`collapsible-checkbox-name-${id}`"
            :value="true"
            :unchecked-value="false"
            @change="onCheckboxAction"
        />
      </span>
      <b-button class="section-expander" size="sm" variant="outline-light" @click="onChevronClick">
        <ChevronDown v-if="visible" />
        <ChevronRight v-else />
      </b-button>
      <slot name="header"></slot>
    </div>
    <b-collapse class="section-content" :visible="visible">
      <slot name="content"></slot>
      <slot></slot>
    </b-collapse>
  </div>
</template>

<script>
import ChevronDown from '@/assets/svg/chevron-down.svg'
import ChevronRight from '@/assets/svg/chevron-right.svg'

export default {
  name: 'CollapsibleSection',
  components: {
    ChevronDown,
    ChevronRight
  },
  data: () => ({
    visible: false,
    checkboxSelection: false
  }),
  created () {
    this.visible = this.$props.expanded
    this.checkboxSelection = !!this.$props.selected
  },
  props: {
    id: { required: false },
    selected: { type: Boolean, required: false, default: false },
    selectable: { type: Boolean, required: false, default: false },
    program: Object,
    expanded: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onCheckboxAction () {
      this.$emit('change', { id: this.id, val: this.checkboxSelection })
    },
    onChevronClick () {
      this.visible = !this.visible
      this.$emit('visible', { id: this.id, visible: this.visible })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/colors.scss";

.section {
  border: 1px solid $lightGrayBorder;
}
.section-expanded {
  margin: 5px 0;
  border: 1px solid darken($lightGrayBorder, 10);
  box-shadow: 0 0 5px 1px rgba(13,194,227,0.2);
}
.section:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.section:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.section-header {
  box-sizing: border-box;
  padding: 16px;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.section-expander {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-content {
  border-top: 2px solid $lightGrayBorder;
}
</style>
