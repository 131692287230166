<template>
  <div class="module-information">
    <b-form-select v-model="moduleSelected" :options="modulesForTrainerPreparationOptions" @change="onModuleSelected"></b-form-select>
  </div>
</template>

<script>
import { MODULE_NAME } from '@/views/communication/templateVariables'
import CommActions from '@/vuex/modules/comm/actions'
export const languageCodes = {
  en: 'en-us',
  nl: 'nl-nl',
  de: 'de-de',
  fr: 'fr-fr'
}

export default {
  name: 'TrainerPreparationSelector',
  components: { },
  data: () => ({
    moduleSelected: null,
    moduleVariables: {
      moduleName: { path: MODULE_NAME, value: '', imported: true }
    }
  }),
  created () {
    const moduleNameIndex = this.$store.state.comm.data.customVariables.findIndex(i => i.path === MODULE_NAME)

    if (moduleNameIndex > -1) {
      this.moduleVariables.moduleName = this.$store.state.comm.data.customVariables[moduleNameIndex]
    }
    const selectedModule = this.$store.state.comm.selectedModuleForCustomization
    if (selectedModule) {
      this.moduleSelected = selectedModule
    }
  },
  methods: {
    updateVariable ({ variable }) {
      const index = this.$store.state.comm.data.customVariables.slice().findIndex(i => i.path === variable.path)
      if (index > -1) {
        this.$store.dispatch(CommActions.UpdateCustomVariable, { index, variable })
      } else {
        this.$store.dispatch(CommActions.AppendCustomVariable, { variable })
      }
    },
    onModuleSelected (mId) {
      const module = this.$store.state.comm.waveModules.slice().filter(m => !!m.contentStackUid && m.id === mId).pop()
      if (module) {
        this.moduleVariables.moduleName.value = module.title
        this.moduleSelected = mId
      } else {
        this.moduleVariables.moduleName.value = null
        this.moduleSelected = null
      }
      this.updateVariable({ variable: this.moduleVariables.moduleName })

      this.$store.dispatch(CommActions.SetSelectedModuleForCustomization, this.moduleSelected)
    }
  },
  computed: {
    modulesForTrainerPreparationOptions () {
      return [
        { value: null, text: 'Please select a module' },
        ...this.$store.state.comm.waveModules.filter(m => !!m.contentStackUid).map(i => ({ text: i.title, value: i.id }))
      ]
    }
  }
}
</script>

<style scoped>
.module-information {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 0;
  margin: 0 0 1rem 0;
}
</style>
