<template>
  <table class="table" @change="onChange(value)">
    <thead>
      <tr>
        <th v-if="showCheckboxes">
          <div v-if="showSelectAll" @change="toggleSelectAll" class="pretty p-svg p-curve">
              <input :checked="anySelected" :disabled="disabled" type="checkbox" />
              <div class="state" :class="{ 'p-secondary': disabled, 'p-primary': !disabled }">
                    <svg style="width: 16px;margin-left: 3px;" class="svg svg-icon" viewBox="0 0 24 24">
                      <path style="fill: #fff;" d="M0 11h35v3h-35z"/>
                    </svg>
                  <label></label>
              </div>
          </div>
        </th>
        <th :style="`text-align: ${column.centered ? 'center' : 'left'}`" v-bind:key="column.id" v-for="column in columns">
          <template v-if="column.sortable">
            <span v-on:click="onSort(column)">
              {{column.name}}<span v-html="sortArrow(column)"></span>
            </span>
          </template>

          <template v-else>
            <span>{{column.name}}</span>
          </template>

        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-bind:key="record.id"
        v-for="record in records"
        :style="bgStyle"
        v-on:click="onRowClick(record, $event)"
        v-bind:class="selectedClass(record)"
        class="cursor-pointer">
        <td @click="stopPropagation" v-if="value && showCheckboxes" style="width: 1%; vertical-align: middle;">
          <div v-if="maxItems === 1" class="pretty p-default p-round">
            <input type="radio" v-model="value" :value="record" :disabled="checkboxDisabled(record)">
            <div class="state" :class="{ 'p-secondary': checkboxDisabled(record), 'p-primary': !checkboxDisabled(record) }">
                <label></label>
            </div>
          </div>
          <div v-else-if='!checkboxDisabled(record)' class="pretty p-svg p-curve">
              <input type="checkbox" v-model="value" :value="record" :disabled="checkboxDisabled(record)">
              <div class="state" :class="{ 'p-secondary': checkboxDisabled(record), 'p-primary': !checkboxDisabled(record) }">
                  <svg class="svg svg-icon" viewBox="0 0 20 20">
                      <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style="stroke: white;fill:white;"></path>
                  </svg>
                  <label></label>
              </div>
          </div>
        </td>
        <td @click="onManagerCellClick(column, record, $event)" :style="`text-align: ${column.centered ? 'center' : 'left'}`" v-bind:key="column.id" v-for="column in columns">
          <component
            v-if="column.component"
            :is="column.component(record)"
            v-bind="componentProps(column, record)">
          </component>

          <span v-if="column.value">
            {{column.value(record)}}
          </span>

        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { includes } from 'ramda'

const upArrow = '&#x25B2;'
const downArrow = '&#x25BC;'

export default {
  props: {
    records: Array,
    columns: Array,
    maxItems: {
      type: Number,
      default: () => Infinity
    },
    value: {
      type: [ Array, Object ],
      default: () => []
    },
    onClick: Function,
    rowType: Function,
    bgStyle: String,
    disabled: Boolean,
    allDisabled: {
      type: Boolean,
      default: () => false
    },
    hideSelectAll: {
      type: Boolean,
      default: () => false
    },
    showCheckboxes: {
      type: Boolean,
      default: () => true
    }
  },

  data () {
    return {
      sortedBy: this.$route.params['order-by'],
      sortDesc: true,
      removeGuard: null
    }
  },

  created () {
    this.removeGuard = this.$router.afterEach(to => {
      this.sortedBy = to.query['order-by']
      this.sortDesc = to.query.order === 'desc'
    })
  },

  destroyed () {
    this.removeGuard()
  },

  computed: {
    showSelectAll () {
      return !this.hideSelectAll && this.records.length !== 0 &&
          this.records.length <= this.maxItems &&
          this.maxItems !== 1
    },
    anySelected () {
      return (this.value.length > 0)
    }
  },

  methods: {
    checkboxDisabled (record) {
      return (this.value.length >= this.maxItems && !includes(record, this.value)) || record.status === 'archived' || this.allDisabled
    },
    stopPropagation (e) {
      e.stopPropagation()
    },
    toggleSelectAll () {
      this.anySelected
        ? this.value = []
        : this.value = this.records.filter(record => !this.checkboxDisabled(record))
    },
    onSort (column) {
      const query = this.$router.currentRoute.query
      const orderBy = query['order-by']

      this.$router.push({
        ...this.$router.currentRoute,
        query: {
          ...query,
          'order-by': (column || {}).prop,
          order: column.prop === orderBy
            ? (query.order === 'desc') ? 'asc' : 'desc'
            : 'desc'
        }
      })
    },

    sortArrow (column) {
      if (this.sortedBy !== column.prop) { return '' }

      return this.sortDesc ? downArrow : upArrow
    },

    componentProps (column, record) {
      return column.props
        ? column.props(record)
        : {}
    },

    selectedClass (record) {
      if (this.value === record || (this.value && includes(record, this.value))) { return this.allDisabled ? '' : 'table-primary' }

      if (this.rowType) {
        const type = this.rowType(record)

        if (type) { return `table-${type}` }
      }

      return ''
    },

    onChange (value) {
      this.$emit('input', value)
    },

    onRowClick (record, event) {
      this.onClick && this.onClick(record, event)
    },
    onManagerCellClick (column, record, event) {
      if (column.prop === 'manager' && record.managerDetails) {
        event.stopPropagation()
        this.onClick({ id: record.managerDetails.id }, event)
      }
    }
  }

}
</script>

<style scoped>

  table th {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.9em;
  }

  table tr > th:first-child, table tr > td:first-child {
    padding-left: 2rem;
  }

  table tr td {
    word-break: break-word;
    padding: 1.3em 1.3em 1.3em 1em;
  }
</style>
