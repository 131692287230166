import getCleanState, { ViewMode, WizardSteps } from '@/vuex/modules/comm/state'
import { clamp } from 'ramda'
import { communicationTypeMap } from '@/views/communication/communicationTypeSelection/communicationConfig'

const CommMutations = Object.freeze({
  openModal: 'openModal',
  closeModal: 'closeModal',
  nextStep: 'nextStep',
  setStep: 'setStep',
  setDataField: 'setDataField',
  setLoading: 'setLoading',
  setAvailableTemplates: 'setAvailableTemplates',
  setSelectedTemplate: 'setSelectedTemplate',
  resetData: 'resetData',
  openRemoveModal: 'openRemoveModal',
  closeRemoveModal: 'closeRemoveModal',
  removeLoading: 'removeLoading',
  removeError: 'removeError',
  removeMessage: 'removeMessage',
  setClassroomStart: 'setClassroomStart',
  setSelectedTimezone: 'setSelectedTimezone',
  setCommunicationsList: 'setCommunicationsList',
  setCompanyCodeAndProgramId: 'setCompanyCodeAndProgramId',
  setParticipants: 'setParticipants',
  setRecipients: 'setRecipients',
  setCurrentRecipientsList: 'setCurrentRecipientsList',
  setMessageSentNotification: 'setMessageSentNotification',
  setShowPreviewEmailModal: 'setShowPreviewEmailModal',
  setPreviewEmailData: 'setPreviewEmailData',
  setShowCommunicationTypeModal: 'setShowCommunicationTypeModal',
  setCommunicationTypeParameters: 'setCommunicationTypeParameters',
  setViewMode: 'setViewMode',
  setViewData: 'setViewData',
  setShowSentEmailModal: 'setShowSentEmailModal',
  setSentEmailData: 'setSentEmailData',
  setWaveModules: 'setWaveModules',
  setSelectedModuleForCustomization: 'setSelectedModuleForCustomization',
  setNewTemplateFilter: 'setNewTemplateFilter',
  resetCommunicationTypeParameters: 'resetCommunicationTypeParameters',
  setCommunicationType: 'setCommunicationType',
  setAutoCommunications: 'setAutoCommunications'
})

export const mutations = {
  [CommMutations.resetData] (state) {
    const initial = getCleanState()
    Object.assign(state.data, initial.data)
    state.selectedTemplate = initial.selectedTemplate
    state.step = initial.step
    state.classroomStart = initial.classroomStart
    state.sendDateTimezone = initial.sendDateTimezone
    state.companyCode = initial.companyCode
    state.programId = initial.programId
    state.viewMode = ViewMode.EDIT
    state.viewData = null
  },
  [CommMutations.setLoading] (state, isLoading) { state.loading = !!isLoading },
  [CommMutations.nextStep] (state) {
    state.step = clamp(1, WizardSteps.LAST_STEP, state.step + 1)
    if (state.step > state.data.lastAccessedStep) {
      state.data.lastAccessedStep = state.step
    }
  },
  [CommMutations.setStep] (state, newStep) {
    state.step = clamp(WizardSteps.LOADING, WizardSteps.LAST_STEP, newStep)
    if (newStep > state.data.lastAccessedStep) {
      state.data.lastAccessedStep = newStep
    }
  },
  [CommMutations.openModal] (state) { state.openModal = true },
  [CommMutations.closeModal] (state) { state.openModal = false },
  [CommMutations.openRemoveModal] (state, removingId) { state.removingId = removingId },
  [CommMutations.closeRemoveModal] (state) { state.removingId = null },
  [CommMutations.removeLoading] (state, loading) { state.removeLoading = !!loading },
  [CommMutations.removeError] (state, error) { state.removeError = error },
  [CommMutations.removeMessage] (state, message) { state.removeMessage = message },
  [CommMutations.setDataField] (state, { field, value }) {
    if (Object.prototype.hasOwnProperty.call(state.data, field)) {
      state.data[field] = value
    }
  },
  [CommMutations.setAvailableTemplates] (state, templates) { state.availableTemplates = templates },
  [CommMutations.setSelectedTemplate] (state, template) { state.selectedTemplate = template },
  [CommMutations.setClassroomStart] (state, classroomStart) { state.classroomStart = classroomStart },
  [CommMutations.setSelectedTimezone] (state, sendDateTimezone) { state.sendDateTimezone = sendDateTimezone },
  [CommMutations.setCommunicationsList] (state, list) { state.communications = list },
  [CommMutations.setCompanyCodeAndProgramId] (state, { companyCode, programId }) {
    state.companyCode = companyCode
    state.programId = programId
  },
  [CommMutations.setParticipants] (state, list) { state.participants = list },
  [CommMutations.setRecipients] (state, { excludedEmails, addedEmails }) {
    state.data.recipients.excludedEmails = excludedEmails
    state.data.recipients.addedEmails = addedEmails
  },
  [CommMutations.setCurrentRecipientsList] (state) {
    // Get list of recipients
    let recipients = state.participants.filter((participant) => {
      return state.data.recipients.roles.some((defaultRole) => participant.role === defaultRole)
    })
    // Add added emails
    state.data.recipients.addedEmails.forEach(addedEmail => {
      recipients.push(state.participants.find(participant => participant.email === addedEmail))
    })
    // Remove excluded emails
    state.data.recipients.excludedEmails.forEach(excludedEmail => {
      recipients = recipients.filter(recipient => recipient.email !== excludedEmail)
    })

    state.currentRecipientsList = recipients
  },
  [CommMutations.setMessageSentNotification] (state, msg) {
    state.messageSentNotification = msg
  },
  [CommMutations.setShowPreviewEmailModal] (state, value) {
    state.showPreviewEmailModal = value
  },
  [CommMutations.setPreviewEmailData] (state, value) {
    state.previewEmailData = value
  },
  [CommMutations.setShowCommunicationTypeModal] (state, value) {
    state.showCommunicationTypeModal = value
  },
  [CommMutations.setCommunicationTypeParameters] (state, data) {
    state.communicationType = data.id
    state.data.recipients.roles = data.defaultRoles
    state.defaultEmailTemplate = data.defaultEmailTemplate
    state.templateFilters = data.templateFilters
  },
  [CommMutations.setNewTemplateFilter] (state, filter) {
    state.templateFilters.push(filter)
  },
  [CommMutations.setShowSentEmailModal] (state, value) {
    state.showSentEmailModal = value
  },
  [CommMutations.setSentEmailData] (state, value) {
    state.sentEmailData = value
  },
  [CommMutations.setViewMode] (state, mode) {
    state.viewMode = mode
  },
  [CommMutations.setViewData] (state, data) {
    state.viewData = data
  },
  [CommMutations.setWaveModules] (state, modules) {
    state.waveModules = modules
  },
  [CommMutations.setSelectedModuleForCustomization] (state, selectedModule) {
    state.selectedModuleForCustomization = selectedModule
  },
  [CommMutations.resetCommunicationTypeParameters] (state) {
    state.communicationType = null
    state.data.recipients.roles = []
    state.defaultEmailTemplate = null
    state.templateFilters = []
    state.selectedModuleForCustomization = null
  },
  [CommMutations.setCommunicationType] (state, communicationType) {
    state.communicationType = communicationType !== null ? communicationTypeMap[communicationType] : null
  },
  [CommMutations.setAutoCommunications] (state, list) { state.autoCommunications = list }

}

export default CommMutations
