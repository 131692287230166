<template>
<CollapsibleSection :expanded="expanded" :id="program.id" @visible="handleVisible">

  <template v-slot:header>
    <div class="program-section-header">
      <span class="program-section-header-name"><ProgramName :enabled="!isSalesforceProgram" :name="program.name" :program="program" /></span>
      <span class="program-section-header-wave-count">{{ program.waves.length }} waves</span>
      <Pill v-if="program.originalProgramName">{{ program.originalProgramName }}</Pill>
      <div :style="{flex:1}"/>
      <div class='program-section-header-right'>
      <SalesforceLogo v-if='isSalesforceProgram' ref='salesforceLogo' />
        <b-tooltip :target="() => $refs.salesforceLogo" >
          Last Sync From SF <br/>
          {{ lastUpdatedText }}
        </b-tooltip>
        <a class='space'/>
        <ContentStackLogo style='margin-right: 8px;'/>
      <SyncSwitch :sync-status="program.contentSync" :onToggle="toggleContentSync"/>
        </div>
    </div>
  </template>

  <template v-slot:content>
    <b-tabs content-class="mt-3">
      <b-tab title="Waves" :key="programKey" active>
        <WavesTable :waves="program.waves" :program="program" />
      </b-tab>
      <b-tab title="Modules" lazy>
        <ModulesTable :program="program" />
      </b-tab>
      <b-tab title="Participants" lazy>
        <ParticipantsTable :program="program" />
      </b-tab>
      <b-tab title="Events" lazy>
        <EventsTable :program="program" />
      </b-tab>
    </b-tabs>
  </template>

</CollapsibleSection>
</template>

<script>
import ContentStackLogo from '@/assets/svg/contentstack.svg'
import SalesforceLogo from '@/assets/svg/salesforce.svg'
import CollapsibleSection from '@/components/CollapsibleSection.vue'
import Pill from '@/components/Pill.vue'
import ProgramName from '@/components/program/ProgramName.vue'
import SyncSwitch from '@/components/program/SyncSwitch.vue'
import EventsTable from '@/components/program/tables/EventsTable.vue'
import ModulesTable from '@/components/program/tables/ModulesTable.vue'
import ParticipantsTable from '@/components/program/tables/ParticipantsTable.vue'
import WavesTable from '@/components/program/tables/WavesTable.vue'
import { ProgramsActions } from '@/vuex/modules/programs/actions'
import { PROGRAMS_NAMESPACE } from '@/vuex/modules/programs/programs.state'
import { mapActions } from 'vuex'

export default {
  name: 'ProgramSection',
  components: {
    SyncSwitch,
    ProgramName,
    ModulesTable,
    ParticipantsTable,
    EventsTable,
    CollapsibleSection,
    WavesTable,
    Pill,
    SalesforceLogo,
    ContentStackLogo
  },
  props: {
    program: Object,
    expanded: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions(PROGRAMS_NAMESPACE, {
      setContentSync: ProgramsActions.setContentSync,
      addExpandedProgramId: ProgramsActions.addExpandedProgramId,
      removeExpandedProgramId: ProgramsActions.removeExpandedProgramId
    }),
    async toggleContentSync (e, contentSync) {
      const { companyCode } = this.$route.params
      await this.setContentSync({ program: this.$props.program, companyCode, contentSync })
    },
    handleVisible (event) {
      if (event.visible) {
        this.addExpandedProgramId({ id: event.id })
      } else {
        this.removeExpandedProgramId({ id: event.id })
      }
    }
  },
  computed: {
    programKey () {
      return `${this.$props.program.name} ${this.$props.program.contentSync}`
    },
    isSalesforceProgram () {
      return !!this.$props.program.sfid
    },
    lastUpdatedText () {
      return this.$props.program.sfUpdatedAt
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/colors.scss";

.program-section-header {
  display: flex;
  gap: 10px;
  align-items: baseline;
  flex:1;
}

.program-section-header-name {
  font-weight: 600;
}

.program-section-header-wave-count {
  color: $secondary
}
.program-section-header-right {
  display: flex;
  align-items: center;
  justify-content:space-between;
}

.space{
  border-right: 1px solid #E4E4E7;
  margin-right: 16px;
  margin-left: 16px;
  height: 32px;
}

</style>
