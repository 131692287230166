<template>
  <Modal
    :on-escape="closeModal"
    :on-close="closeModal"
  >
    <template v-slot:header>
      <p class="p-3 header">Select Bite Type</p>
    </template>

    <div class="selection-area">

      <div class="selection-menu">
        <h5>In-app content</h5>
        <div class="bite-card-grid">
          <IconCard
              v-for="(biteType) in inAppBiteTypes"
              :key="biteType.biteType"
              @click.native="selectBiteTypeForCreation(biteType.biteType)"
              :icon="biteType.icon ?? 'star'"
          >
            <template #icon><component v-bind:is="getIconComponent(biteType.biteType)" /></template>
            <template #subtitle>{{ biteType.description }}</template>
            {{ biteType.label }}
          </IconCard>
        </div>
      </div>

      <div class="selection-menu">
        <h5>Existing content</h5>
        <div class="bite-card-grid">
          <IconCard
              v-for="(biteType) in existingBiteTypes"
              :key="biteType.biteType"
              @click.native="selectBiteTypeForCreation(biteType.biteType)"
              :icon="biteType.icon ?? 'star'"
          >
            <template #icon><component v-bind:is="getIconComponent(biteType.biteType)" /></template>
            <template #subtitle>{{ biteType.description }}</template>
            {{ biteType.label }}
          </IconCard>
        </div>
      </div>
    </div>
    <OverlayingSpinner v-if="loading"/>
  </Modal>
</template>

<script>
import QuestionnaireSVG from '@/assets/svg/Questionnaire.svg'
import ReadingSVG from '@/assets/svg/Reading.svg'
import VideoSVG from '@/assets/svg/Video.svg'
import IconCard from '@/components/IconCard.vue'
import Modal from '@/components/Modal.vue'
import { BiteTypeDefinitions, getExternalCreatableBiteTypes, getInAppCreatableBiteTypes } from '@/components/bites/supportedBiteForms'
import OverlayingSpinner from '@/components/common/OverlayingSpinner.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CreateBiteModal',
  components: {
    IconCard,
    OverlayingSpinner,
    Modal,
    VideoSVG,
    QuestionnaireSVG,
    ReadingSVG
  },
  data: () => ({
    supportedBiteForms: BiteTypeDefinitions
  }),
  methods: {
    ...mapActions('bites', {
      selectBiteTypeForCreation: 'selectBiteTypeForCreation'
    }),
    closeModal () {
      this.$store.commit('bites/setBiteCreation', false)
      this.$store.commit('bites/setEditingBite', null)
      this.$store.commit('bites/showNewBiteTypeSelection', false)
    },
    getIconComponent (biteType) {
      if (biteType === 'video') {
        return VideoSVG
      } else if (biteType === 'survey') {
        return QuestionnaireSVG
      } else {
        return ReadingSVG
      }
    }
  },
  computed: {
    ...mapGetters('bites', {
      editingBite: 'editingBite',
      loading: 'loading',
      successUpdatedMessage: 'successUpdatedMessage',
      failedUpdateMessage: 'failedUpdateMessage'
    }),
    inAppBiteTypes: () => getInAppCreatableBiteTypes(),
    existingBiteTypes: () => getExternalCreatableBiteTypes()
  }
}
</script>

<style scoped lang="scss">

@import './../../assets/colors.scss';

.header {
    align-self: center;
    font-size: 32px;
}

.selection-area {
  display: flex;
  background: $lightGray;
}

.selection-menu {
  flex: 1;
  display: flex;
  flex-direction: column;

  h5{
    padding: 1em 0 0 5%;
    font-weight: 800;
  }
}

.bite-card-grid {
  padding: 1em 5%;
  display: grid;
  align-items: start;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 25px;
}

.bite-type-card {
  border: 1px solid black;
  width: 100%;
  height: 150px;
  cursor: pointer;
}
</style>
