<template>
<div class="wave-status-cell">
  <Pill v-if="wave.status === 'published'" color="green" large >Published</Pill>
  <Pill v-else-if="wave.status === 'draft'" color="blue" large>Draft</Pill>
</div>
</template>

<script>
import Pill from '@/components/Pill.vue'

export default {
  name: 'WaveStatusCell',
  components: { Pill },
  props: {
    wave: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>

</style>
