<template>
  <transition name="fade">
    <div v-if="visible && isOpen" @click="onClick" class="cursor-pointer p-3 text-white" :class="classes">
      <Icon class="mr-2" :icon="icon"></Icon>
      <slot></slot>
      {{message}}
      <strong v-on:click="hide" class="ml-3 float-right" >&#10005;</strong>
    </div>
  </transition>
</template>

<script>
import Icon from './Icon.vue'
import { includes } from 'ramda'

const urgencyTimes = {
  low: 3000,
  normal: 5000,
  critical: Infinity
}

// Inspired by https://ss64.com/bash/notify-send.html

export default {

  props: {
    classes: {
      type: Object,
      default: () => ({ 'bg-success': true })
    },
    icon: {
      type: String,
      default: 'info-circle'
    },
    message: String,
    urgency: {
      default: 'normal',
      validator (value) {
        return includes(value, value)
      }
    },
    expireTime: Number,
    priority: {
      default: 1,
      type: Number
    },
    open: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Icon
  },

  data: () => ({
    visible: true
  }),

  mounted () {
    setTimeout(() => {
      this.hide()
    }, this.autoHideMilliseconds)
  },

  computed: {
    autoHideMilliseconds () {
      return this.expireTime || urgencyTimes[this.urgency] || 3000
    },
    isOpen () {
      if (this.open) {
        // reset the visibility to true every time the "open" attribute is set to true
        this.show()
      }
      return this.open
    }
  },
  methods: {
    hide () {
      this.visible = false
    },
    show () {
      this.visible = true
    },
    onClick () {
      this.$emit('click')
    }
  }

}
</script>
