<template src="./CompanyProgramForm.html"></template>

<script>
import CompanyProgramForm from './CompanyProgramForm.js'
import lepaya from '../http/lepaya.js'

export default CompanyProgramForm({
  data () {
    return {
      create: true,
      binding: {
        persist: 'Create new Wave'
      }
    }
  },

  methods: {
    getData ({ params: { companyCode, programCatalogueItemId } }) {
      return Promise.all([
        lepaya.get(`/companies/${companyCode}/programs-catalogue/${programCatalogueItemId}`),
        lepaya.get(`/companies/${companyCode}/journeys`),
        lepaya.get('/languages')
      ])
        .then(([ programCatalogueItem, journeys, languages ]) => Promise.all([
          programCatalogueItem,
          createProgram(programCatalogueItem.data),
          journeys,
          languages,
          {},
          {}
        ]))
    },
    persist: async ({ companyCode }, { certificate, ...program }) => {
      const data = await lepaya.post(`/companies/${companyCode}/programs`, program)
      await lepaya.put(`/companies/${companyCode}/certificates/${data.data.id}`, certificate)
      return data
    }
  }
})

function createProgram (programCatalogueItem) {
  return {
    data: {
      programCatalogueItemId: programCatalogueItem.id,
      journeys: [],
      imageUrl: programCatalogueItem.imageUrl,
      languages: [],
      endOfProgramEmail: true
    }
  }
}

</script>
