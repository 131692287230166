<template>
    <b-link :class="{'step': true, 'step-active': isActive, 'step-disabled': isDisabled}" @click="toStep($props.step)"><slot/></b-link>
</template>

<script>
import CommActions from '../../vuex/modules/comm/actions'

export default {
  name: 'StepLink',
  props: {
    step: Number
  },
  computed: {
    isActive: function () {
      return this.$props.step === this.$store.state.comm.step
    },
    isDisabled: function () {
      return this.$props.step > this.$store.getters['comm/lastAvailableStep']
    }
  },
  methods: {
    toStep: function (step) { this.$store.dispatch(CommActions.ToStep, step) }
  }
}
</script>

<style lang="scss" scoped>
    .step {
        cursor: pointer;
        color: #637080;
        font-size: 16px;
        font-weight: 900 !important;
        text-decoration: none;

        &:hover {
            color: #5077a4;
        }
    }

    .step-active {
        color: #56d5ec;
        &:hover {
            color: #56d5ec;
        }
    }

    .step-disabled {
        cursor: auto;
        color: #aaaaae !important;
        &:hover {
            cursor: auto;
            color: #aaaaae;
        }
    }
</style>
