<template>
<div class="wave-sync-status-cell">
  <SyncSwitch v-if="!program?.contentSync" :sm="true" :syncStatus="false" :onToggle="null" />
  <SyncSwitch v-else :sm="true" :syncStatus="wave.contentSync" :onToggle="changeContentSyncStatus" />
</div>
</template>

<script>

import SyncSwitch from '@/components/program/SyncSwitch.vue'
import { ProgramsActions } from '@/vuex/modules/programs/actions'
import { ProgramsGetters } from '@/vuex/modules/programs/getters'
import { PROGRAMS_NAMESPACE } from '@/vuex/modules/programs/programs.state'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WaveContentSyncStatusCell',
  components: { SyncSwitch },
  props: {
    wave: {
      type: Object,
      required: true
    }
  },
  created () {
    this.$data.program = this.getProgramById(this.$props.wave.programId)
  },
  data () {
    return {
      program: null
    }
  },
  computed: {
    ...mapGetters(PROGRAMS_NAMESPACE, {
      getProgramById: ProgramsGetters.getProgramById
    })
  },
  methods: {
    ...mapActions(PROGRAMS_NAMESPACE, {
      setWaveContentSync: ProgramsActions.setWaveContentSync
    }),
    async changeContentSyncStatus (e, contentSync) {
      const { companyCode } = this.$route.params
      const program = this.getProgramById(this.$props.wave.programId)
      return this.setWaveContentSync({ companyCode, wave: this.$props.wave, contentSync, program })
    }
  }
}
</script>

<style>

</style>
