import Table from './Table.vue'
import Search from './Search.vue'
import ActionButtons from './ActionButtons.vue'
import Button from './Button.vue'
import IconText from './IconText.vue'
import NotificationPanel from './NotificationPanel.vue'
import Modal from './Modal.vue'
import { mapGetters } from 'vuex'
import { isFeatureEnabled } from '@/lib/splitIo/handler'
import { featureFlags } from '@/lib/splitIo/models'

export default function ListView ({ primaryKey, data, methods }) {
  primaryKey = primaryKey || 'id'

  return {
    components: {
      NotificationPanel,
      ActionButtons,
      Button,
      IconText,
      Table,
      Search,
      Modal
    },

    data () {
      return {
        actions: [],
        records: [],
        selected: [],
        error: null,
        isEmployees: false,
        showSyncModal: false,
        showDuplicationOption: true,
        showManualModuleCreation: true,
        ...data.call(this)
      }
    },

    methods: {
      closeModal (event) {
        this.onClose(event)
      },
      ...methods,
      openOwnerPermissionsModal () {
        this.$store.commit('editors/setShowOwnerPermissionsModal', true)
      }
    },
    async created () {
      this.showManualModuleCreation = await isFeatureEnabled(featureFlags.MODULE_MANUAL_CREATION)
    },
    computed: {
      ...mapGetters('editors', {
        showOwnerPermissionsModal: 'showOwnerPermissionsModal',
        grantRevokeOwnerPermissionsError: 'grantRevokeOwnerPermissionsError',
        grantRevokeOwnerPermissionsSuccess: 'grantRevokeOwnerPermissionsSuccess'
      }),
      notificationShowing () {
        return this.$route.query[primaryKey] || this.$route.query.archive || this.$route.query.delete || this.$route.query.notificationMessage
      },
      notificationText () {
        return this.$route.query.archive
          ? 'Archived'
          : this.$route.query.delete ? 'Deleted'
            : this.$route.query[primaryKey] ? 'Saved'
              : this.$route.query.notificationMessage || null
      },
      tableProps () {
        this.table.rowType = this.table.rowType || function rowType (row) {
          if (
            `${row[primaryKey]}` === `${this.$route.query[primaryKey]}` ||
            `${row[primaryKey]}` === `${this.$route.query.selectedIds}` ||
            (Array.isArray(this.$route.query.selectedIds) && this.$route.query.selectedIds.find(id => `${row[primaryKey]}` === `${id}`))
          ) return 'success'
        }

        return {
          ...this.table,
          records: this.records
        }
      },
      showSyncMSTeamsButton () {
        return this.selected.length && this.$route.name === 'listPrograms'
      },
      showDuplicationOptionButton () {
        return this.showDuplicationOption
      },
      showManualModuleCreationButton () {
        if (this.$route.name === 'listJourneys') {
          return this.showManualModuleCreation
        } else {
          return true
        }
      }
    },

    beforeRouteUpdate (to, from, next) {
      next()
    },

    beforeRouteEnter
  }

  function beforeRouteEnter (to, from, next) {
    methods.getRecords(to)
      .then(records => next(vm => {
        vm.records = records
        vm.selected = records.filter(record => {
          if (vm.$route.query) {
            if (vm.$route.query.selectedIds) {
              if (Array.isArray(vm.$route.query.selectedIds) && (vm.$route.query.selectedIds.includes(`${record.id}`) || vm.$route.query.selectedIds.includes(record.id))) {
                return true
              } else if (vm.$route.query.selectedIds === `${record.id}` || vm.$route.query.selectedIds === record.id) {
                return true
              }
            }
            if (vm.$route.query.selectedEmails) {
              if (Array.isArray(vm.$route.query.selectedEmails) && vm.$route.query.selectedEmails.includes(record.email)) {
                return true
              } else if (vm.$route.query.selectedEmails === record.email) {
                return true
              }
            }
          }

          return false
        })
      }))
  }
}
