<template>
  <div :class="`${flex ? '' : 'container'} row`">
    <div @input="onChange(option)" :class="`form-check ${flex ? 'col-sm-6' : 'col-sm-4' }`" v-bind:key="optionId(option)" v-for="option in options">
      <div :class="`${flex ? 'pl-3' : ''}`">
        <input :name="name" class="form-check-input" :disabled="disabled" type="radio" :value="option" v-model="value" :id="optionId(option)">
        <label class="form-check-label" :for="optionId(option)">
          <component :is="label(option).component" v-bind="label(option).props"></component>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Span from './Span.vue'

export default {
  props: {
    label: {
      type: Function,
      default: option => ({
        component: Span,
        props: {
          text: option.name
        }
      })
    },
    disabled: {
      default: false,
      type: Boolean
    },
    options: Array,
    value: Object,
    name: String,
    flex: Boolean
  },

  methods: {
    optionId: option => {
      return option.name
    },
    onChange (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>
  label {
    padding-top: 2px;
  }
</style>
