<template>
  <label>{{ text }}
    <Icon v-if='isSalesforce' ref='popover' v-b-tooltip.hover class="sfIcon" style='margin-left: 8px' color='#00A1E0' icon="cloud-download-alt"/>
    <b-popover placement="bottomright" :target="() => $refs.popover" custom-class="popover" triggers="hover focus">
      <template>
        <span class="popover__title">This field is synced from Salesforce</span>
        <br>
        <span class="popover__subtitle">In order to edit this field <a :href="url"  target='_blank' class="popover__link">proceed to Salesforce</a>
        </span>
      </template>
    </b-popover>
  </label>
</template>

<script>
import Icon from '../components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    isSalesforce: {
      type: Boolean,
      default: false
    }
  },
  data () {
    let url = 'https://lepaya.my.salesforce.com'
    if (location.href.includes('stage')) {
      url = 'https://lepaya--uat.sandbox.my.salesforce.com/'
    }
    return { url }
  }
}
</script>

<style scoped>

.popover__title {
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.3px;
}

.popover__link{
  color: #00A1E0;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
}
.popover__title {
  color: black;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.popover {
  border-radius: 4px;
  border: 1px solid #00A1E0;
  background:  #FAFAFB;
  /* Shadow/M */
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
}

</style>
