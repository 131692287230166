import VueRouter from 'vue-router'

import NewWaveEditForm from '@/components/NewWaveEditForm.vue'
import CompanyProgramsList from '@/views/CompanyProgramsList.vue'
import DomainEvents from '@/views/DomainEvents.vue'
import { isNil } from 'ramda'
import auth0 from './auth0.js'
import CompaniesPicker from './components/Companies.vue'
import CompanyProgramCreateForm from './components/CompanyProgramCreateForm.vue'
import CompanyPrograms from './components/CompanyPrograms.vue'
import CompanyProgramsCreate from './components/CompanyProgramsCreate.vue'
import Oops from './components/Oops.vue'
import UserApp from './components/UserApp.vue'
import View from './components/View.vue'
import lepaya, { registerRouterInterceptor } from './http/lepaya.js'
import AddClassroomModal from './views/AddClassroomModal.vue'
import AddJourneyParticipantsModal from './views/AddJourneyParticipantsModal.vue'
import BitesDuplicateModal from './views/BitesDuplicateModal.vue'
import JourneyForm from './views/JourneyForm.vue'

let profile = {}
const flowerDomain = process.env.VUE_APP_SHOWER_URL.replace(/shower/, 'flower')

const routes = [
  {
    component: UserApp,
    path: '/',
    name: 'userApp',
    redirect: { name: 'redirect' },
    meta: {
      authenticated: true
    },
    children: [
      {
        name: 'companies',
        path: '/companies',
        component: CompaniesPicker,
        meta: {
          authenticated: true,
          redirectable: true
        }
      },

      // OWNER
      {
        name: 'owner',
        path: '/owner',
        component: View,
        redirect: { name: 'listDomainEvents' },
        meta: {
          navbar: [
            { icon: 'user-cog', title: 'Editors', href: `${flowerDomain}/owner/editors` },
            { icon: 'building', title: 'Companies', href: `${flowerDomain}/owner/companies` },
            { icon: 'star', title: 'Events', name: 'listDomainEvents' }
          ],
          isOwner: true,
          authenticated: true
        },
        children: [
          {
            name: 'listDomainEvents',
            path: 'events',
            component: DomainEvents,
            meta: {
              redirectable: true,
              authenticated: true,
              isOwner: true
            }
          }
        ]
      },
      {
        caseSensitive: false,
        name: 'company',
        path: '/companies/:companyCode',
        component: View,
        redirect: { name: 'listPrograms' },
        meta: {
          navbar: [
            { icon: 'folder-open', title: 'Programs', name: 'listPrograms' },
           {
              icon: 'users',
              title: 'Employees',
              name: 'listEmployees',
              href: `${flowerDomain}/${localStorage.getItem('companyCode')}/employees`
           }
          ]
        },
        children: [
          // PROGRAMS
          {
            name: 'listPrograms',
            path: 'programs',
            component: CompanyProgramsList,
            meta: {
              redirectable: true,
              authenticated: true
            }
          },
          {
            name: 'pickProgramCatalogue',
            path: 'programs/start',
            components: {
              default: CompanyPrograms,
              modal: CompanyProgramsCreate
            },
            meta: {
              redirectable: true,
              authenticated: true
            }
          },
          {
            name: 'startProgram',
            path: 'programs/program-catalogue/:programCatalogueItemId/programs/start',
            component: CompanyProgramCreateForm,
            meta: {
              redirectable: true,
              authenticated: true
            }
          },
          {
            name: 'editProgram',
            path: 'programs/program-catalogue/:programCatalogueItemId/programs/:id',
            component: async () => NewWaveEditForm,
            meta: {
              redirectable: true,
              authenticated: true
            }
          },
          {
            name: 'addCommunication',
            path: 'programs/program-catalogue/:programCatalogueItemId/programs/:id/addCommunication/:step',
            component: NewWaveEditForm,
            meta: {
              redirectable: true,
              authenticated: true,
              addCommunicationDialog: true
            }
          },

          // JOURNEYS
          {
            name: 'createJourney',
            path: 'journeys/new',
            component: JourneyForm,
            meta: {
              authenticated: true
            }
          },
          {
            name: 'editJourney',
            path: 'journeys/:id',
            component: JourneyForm,
            meta: {
              authenticated: true
            },
            children: [
              {
                name: 'addClassroom',
                path: 'classroom',
                component: AddClassroomModal
              },
              {
                name: 'editClassroom',
                path: 'classroom/:classroomId',
                component: AddClassroomModal
              },
              {
                name: 'duplicateBites',
                path: 'duplicateBites',
                component: BitesDuplicateModal
              },
              {
                name: 'addJourneyParticipants',
                path: 'addJourneyParticipants',
                component: AddJourneyParticipantsModal
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'oops',
    path: '/oops',
    component: Oops
  },
  {
    name: 'login',
    path: '/login',
    beforeEnter: (to, from) => {
      if ((from.meta || {}).redirectable) {
        localStorage.setItem('redirect', from.fullPath)
      }

      auth0.authorize()
    }
  },
  {
    name: 'redirect',
    path: '/redirect',
    redirect: () => {
      const redirect = localStorage.getItem('redirect') || { name: 'companies' }
      localStorage.removeItem('redirect')

      return redirect
    }
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({ routes })

router.afterEach(to => {
  if (to.meta.redirectable) {
    localStorage.setItem('redirect', to.fullPath)
  }
})

const unregisterLoginGuard = router.beforeEach((to, from, next) => {
  if (isNil(to.meta.authenticated)) {
    return next()
  }

  unregisterLoginGuard()

  auth0
    .parseHash()
    .then(() => next())
    .catch(next)
})

// change user company
router.beforeEach((to, from, next) => {
  if (isNil(to.meta.authenticated)) {
    return next()
  }

  lepaya
    .get('/profile')
    .then(({ data }) => {
      profile = data

      if (isNil(to.params.companyCode)) {
        return next()
      }

      if (profile.companyCode !== to.params.companyCode) {
        return lepaya
          .put('/profile', {
            companyCode: to.params.companyCode
          })
          .then(() => {
            profile.companyCode = to.params.companyCode
            localStorage.setItem('companyCode', to.params.companyCode)
            return profile.companyCode
          })
      }

      if (isNil(profile.companyCode)) {
        return next({
          name: 'companies'
        })
      }
    })
    .then(() => next())
    .catch(next)
})

router.beforeEach((to, from, next) => {
  if (isNil(to.meta.isOwner)) {
    return next()
  }

  if (profile.isOwner) {
    return next()
  }

  return next({
    name: 'oops',
    params: { errorMessage: 'Unauthorized' }
  })
})

router.onError(error => {
  // eslint-disable-next-line
  console.error(error)

  router.push({
    name: 'oops',
    params: { errorMessage: `Error: ${error.message}` }
  })
})

registerRouterInterceptor(lepaya, router)
registerRouterInterceptor(lepaya.owner, router)

export default router
