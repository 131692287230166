<template>
    <button class="editor-icon" :class="{pressed}" @click="$emit('click')" :title="title" type="button">
        <Icon :icon="icon" />
    </button>
</template>

<script>
import Icon from '@/components/Icon.vue'

export default {
  name: 'EditorIcon',
  props: {
    icon: { type: String, required: true },
    pressed: { type: Boolean, default: false },
    title: { type: String, default: '' },
    click: { type: Function }
  },
  components: { Icon }
}
</script>

<style scoped>
    .editor-icon {
        margin: 0.1em;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        cursor: pointer;
        border: 1px solid black;
        padding: 1em;
        height: 1em;
        width: 1em;
    }

    .editor-icon:hover {
        border: 1px solid #56d5ec;
    }

    .editor-icon:active {
        border: 1px solid #56d5ec;
        background-color: #56d5ec;
        color: white;
    }

    .pressed {
        border: 1px solid #56d5ec;
        background-color: #56d5ec;
        color: white;
    }

</style>
