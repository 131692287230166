<template>
  <Modal
  v-if="showCommunicationTypeModal"
  :on-escape="onClose"
  :on-close="onClose"
  >
    <template v-slot:header>
      <p class="p-3 communication-modal-header">Select a type of communication you want to send to learners</p>
    </template>
    <div class="card-group">
      <b-row>
        <CommunicationTypeItem
          v-for="(communicationType) in communicationTypes"
          :key="communicationType.id"
          :selected="communicationType.id === communicationTypeId"
          :data="communicationType"
          @typeSelected="setSelectedCommunicationType"
        />
      </b-row>
    </div>
    <div class="buttons-container">
      <Button @click="onClose" type="secondary" size="lg" class="font-weight-bold p-4 ml-2">Cancel</Button>
      <Button @click="startCommunicationWizard" :disabled="!selectedCommunicationType" size="lg" type="primary" class="font-weight-bold p-4 ml-2">Start</Button>
    </div>
  </Modal>
</template>

<script>
import Button from '@/components/Button.vue'
import Modal from '@/components/Modal.vue'
import CommunicationTypeItem from '@/views/communication/communicationTypeSelection/CommunicationTypeItem.vue'
import processCommunicationTypes from '@/views/communication/communicationTypeSelection/communicationConfig'
import CommActions from '@/vuex/modules/comm/actions'
import { mapGetters } from 'vuex'

export default {
  name: 'CommunicationTypeModal',
  data () {
    return {
      communicationTypes: [],
      selectedCommunicationType: null
    }
  },
  components: {
    Modal,
    CommunicationTypeItem,
    Button
  },
  async created () {
    this.communicationTypes = await processCommunicationTypes()
  },
  methods: {
    onClose () {
      this.selectedCommunicationType = null
      this.$store.commit('comm/setShowCommunicationTypeModal', false)
      this.$store.commit('comm/resetCommunicationType')
    },
    setSelectedCommunicationType (selectedType) {
      this.selectedCommunicationType = selectedType
      this.$store.commit('comm/setCommunicationTypeParameters', selectedType)
    },
    startCommunicationWizard () {
      const { companyCode, id } = this.$route.params
      this.$store.dispatch(CommActions.Open, { companyCode, id })
      this.$store.commit('comm/setShowCommunicationTypeModal', false)
      this.selectedCommunicationType = null
    }
  },
  computed: {
    ...mapGetters({
      showCommunicationTypeModal: 'comm/showCommunicationTypeModal'
    }),
    communicationTypeId () {
      if (!this.selectedCommunicationType) return false
      return this.selectedCommunicationType.id
    }
  }
}
</script>

<style scoped>
.card-group {
  width: 90%;
  margin: auto;
  padding: 20px;
  display: grid;
  min-height: 60vh;
}
.buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 90px;
  margin: 30px;
}
</style>
