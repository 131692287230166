<template>
    <div class="variable-entry">
        <Label v-if="$props.variable.imported" class="path-entry-label">{{ $props.variable.path  }}</Label>
        <Input v-else class="path-entry"  :value="$props.variable.path" @input="changeLabel"/>
        <TextArea class="value-entry" :value="$props.variable.value" @input="changeValue" />
        <Button v-if="!$props.variable.imported" class="remove-button" type="warning" @click="remove" >Remove</Button>
    </div>
</template>

<script>
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import Label from '@/components/Label.vue'
import TextArea from '@/components/TextArea.vue'

export default {
  name: 'VariableEntry',
  components: { TextArea, Input, Label, Button },
  props: {
    iKey: { type: [ String, Number ] },
    variable: {
      type: Object,
      required: true,
      validator: function (v) {
        return Object.keys(v).includes('path') &&
        Object.keys(v).includes('value')
      }
    }
  },
  methods: {
    changeLabel (label) {
      this.$emit('updateVariable', { index: this.$props.iKey, variable: { path: label, value: this.$props.variable.value, imported: this.$props.variable.imported } })
    },
    changeValue (value) {
      this.$emit('updateVariable', { index: this.$props.iKey, variable: { path: this.$props.variable.path, value, imported: this.$props.variable.imported } })
    },
    remove () {
      this.$emit('removeVariable', { index: this.$props.iKey })
    }
  }
}
</script>

<style scoped>
    .variable-entry {
        margin: 5px;
        display: flex;
        align-items: flex-end;
        padding: 5px;
    }

    .path-entry {
        flex: 1;
        align-items: baseline;
        display: flex;
        font-size: 18px;
        font-weight: 900;
    }

    .path-entry-label {
        flex: 1;
        align-items: baseline;
        display: flex;
    }

    .value-entry {
        flex: 3;
        margin-left: 5px;
        border: 1px solid #aaa;
    }

    .remove-button {
        margin-left: 4px;
    }
</style>
