<template>
<div class="card shadow-sm overflow-hidden">
  <slot style="height: 70%" name="image"></slot>
  <div style="height: 30%" class="card-body">
    <h5 class="card-title">{{title}}<slot name="title"></slot></h5>
    <p class="card-text">{{description}}<slot></slot></p>
  </div>
</div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
    image: String
  }

}
</script>
