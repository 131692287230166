<template>
  <div class="justify-content">
    <a v-show="!isSent && !isEoPScheduled" @click="removeCommunication">
      <Icon class="text-danger text-center mx-2 font-size-md" icon="trash-alt" />
    </a>
    <a v-show="!isSent && !isEoPScheduled" @click="editCommunication">
      <Icon class="text-primary text-center mx-2 font-size-md" icon="pen" alt="Edit"/>
    </a>
    <a v-show="isSent" @click="viewCommunication">
      <Icon class="text-primary text-center mx-2 font-size-md" icon="eye" />
    </a>
  </div>
</template>

<script>
import Icon from './Icon.vue'
import CommActions from '../vuex/modules/comm/actions'
import { CommunicationStatus } from '@/vuex/modules/comm/state'

export default {
  components: {
    Icon
  },
  props: {
    communication: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    isSent () { return this.$props.communication.status === CommunicationStatus.SENT },
    isEoPScheduled () { return this.$props.communication.status === CommunicationStatus.EOP_SCHEDULED }
  },
  methods: {
    removeCommunication () {
      this.$store.dispatch(CommActions.Remove, this.communication.id)
    },
    editCommunication () {
      const { companyCode, id } = this.$route.params
      this.$store.dispatch(CommActions.Edit, { companyCode, id, communicationId: this.communication.id })
    },
    viewCommunication () {
      const { companyCode, id } = this.$route.params
      this.$store.dispatch(CommActions.View, { companyCode, id, communicationId: this.communication.id })
    }
  }
}
</script>

<style>

</style>
