<template>
<Form>
    <div class="m-5 container">
      <BiteFormCommonFields ref="commonFieldsForm" @change="setCommonValues"/>

      <FormGroup>
      <Label>vCoach Configuration</Label>
      <div class="row">
        <div class="col-6">
          <b-form-checkbox
              id="checkbox-speed"
              v-model="bite.details.config.speed"
              name="speed"
              :value="true"
              :unchecked-value="false"
          >
            Speed
          </b-form-checkbox>
          <b-form-checkbox
              id="checkbox-pauses"
              v-model="bite.details.config.pauses"
              name="pauses"
              :value="true"
              :unchecked-value="false"
          >
            Pauses
          </b-form-checkbox>
          <b-form-checkbox
              id="checkbox-filler"
              v-model="bite.details.config.filler_words"
              name="filler_words"
              :value="true"
              :unchecked-value="false"
          >
            Filler Words
          </b-form-checkbox>
          <b-form-checkbox
              id="checkbox-gestures"
              v-model="bite.details.config.gestures"
              name="gestures"
              :value="true"
              :unchecked-value="false"
          >
            Gestures
          </b-form-checkbox>
          <b-form-checkbox
              id="checkbox-stance"
              v-model="bite.details.config.stand_posture"
              name="stand_posture"
              :value="true"
              :unchecked-value="false"
          >
            Posture
          </b-form-checkbox>
        </div>
        <div class="col-6">
          <b-form-checkbox
              id="checkbox-movement"
              v-model="bite.details.config.movement"
              name="movement"
              :value="true"
              :unchecked-value="false"
          >
            Movement
          </b-form-checkbox>
          <b-form-checkbox
              id="checkbox-facial-expression"
              v-model="bite.details.config.facial_expression"
              name="facial_expression"
              :value="true"
              :unchecked-value="false"
          >
            Facial Expression
          </b-form-checkbox>
          <b-form-checkbox
              id="checkbox-eye-contact"
              v-model="bite.details.config.eye_contact"
              name="eye_contact"
              :value="true"
              :unchecked-value="false"
          >
            Eye Contact
          </b-form-checkbox>
        </div>
      </div>

      </FormGroup>
    </div>

    <Button class="float-right btn-modal mx-auto" @click="submit" :disabled="!formValid">{{ isNewBite ? 'Create' : 'Save' }}</Button>
    <Button class="float-right btn-modal mx-auto" @click="closeModal" type="light">Cancel</Button>

  </Form>
</template>

<script>
import Button from '@/components/Button.vue'
import Form from '@/components/Form.vue'
import FormGroup from '@/components/FormGroup.vue'
import BiteFormCommonFields from '@/components/bites/forms/components/BiteFormCommonFields.vue'
import { cloneDeep, isEqual } from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'SurveyForm',
  components: {
    FormGroup,
    Form,
    Button,
    BiteFormCommonFields
  },
  data: () => ({
    bite: null,
    formValid: true
  }),
  created () {
    this.bite = cloneDeep(this.editingBite)
    this.$v.$touch()
  },
  computed: {
    ...mapGetters('bites', {
      editingBite: 'editingBite',
      isNewBite: 'isNewBite'
    })
  },
  methods: {
    setCommonValues (data) {
      this.bite.purpose = data.purpose
      this.bite.title = data.title
      this.bite.durationMins = data.durationMins
      this.bite.isOptional = data.isOptional
      this.bite.relativeDeadlineTime = data.relativeDeadlineTime
      this.bite.deadlineTime = moment(data.deadlineTime).valueOf()
      this.bite.stage = data.stage
      this.bite.orderNumber = data.orderNumber
    },
    checkFormValid () {
      this.formValid = !this.$refs.commonFieldsForm.$v.$invalid && !this.$v.$invalid && !isEqual(this.editingBite, this.bite)
    },
    closeModal () {
      this.$emit('onClose')
    },
    submit () {
      this.$refs.commonFieldsForm.$v.$touch()
      this.checkFormValid()

      if (this.formValid) {
        if (this.$refs.commonFieldsForm.biteDeadlineType === 'fixed') this.bite.relativeDeadlineTime = null
        if (this.$refs.commonFieldsForm.biteDeadlineType === 'relative') this.bite.deadlineTime = null

        this.$emit('onSave', this.bite)
      }
    }
  },
  validations: {
    bite: {
    }
  },
  watch: {
    bite: {
      deep: true,
      immediate: false,
      handler () {
        this.checkFormValid()
      }
    }
  }
}
</script>

<style scoped>
</style>
