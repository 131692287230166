export const bitePurposeOptions = [
  {
    name: 'AI Assistant',
    code: 'AI Assistant'
  },
  {
    name: 'Buddy Bite',
    code: 'Buddy Bite'
  },
  {
    name: 'Collaborate',
    code: 'Collaborate'
  },
  {
    name: 'Goal',
    code: 'Goal'
  },
  {
    name: 'Mini Challenge',
    code: 'Mini Challenge'
  },
  {
    name: 'Questionnaire',
    code: 'Questionnaire'
  },
  {
    name: 'Prepare',
    code: 'Prepare'
  },
  {
    name: 'Read',
    code: 'Read'
  },
  {
    name: 'Survey',
    code: 'Survey'
  },
  {
    name: 'Watch',
    code: 'Watch'
  },
  {
    name: 'Practice Applying',
    code: 'Practice Applying'
  }
]

export const biteStageOptions = [
  {
    name: 'Pre Bite',
    code: 'pre'
  },
  {
    name: 'Session Bite',
    code: 'session'
  },
  {
    name: 'Post Bite',
    code: 'post'
  }
]

export const biteDeadlineOptions = [
  {
    name: 'Fixed Time',
    code: 'fixed'
  },
  {
    name: 'Relative',
    code: 'relative'
  }
]

export const externalTypeOfContentOptions = [
  {
    name: 'Audio',
    code: 'audio'
  },
  {
    name: 'Video',
    code: 'video'
  },
  {
    name: 'Document',
    code: 'document'
  },
  {
    name: 'Other',
    code: 'other'
  }
]

export const surveyTypeOfContentOptions = [
  {
    name: 'Quiz',
    code: 'quiz'
  },
  {
    name: 'Feedback',
    code: 'feedback'
  },
  {
    name: 'Lesson',
    code: 'lesson'
  },
  {
    name: 'Other',
    code: 'other'
  },
  {
    name: 'Goal',
    code: 'goal'
  }
]

export const biteTypeOptions = [
  {
    name: 'Mandatory',
    code: 'mandatory'
  },
  {
    name: 'Optional',
    code: 'optional'
  }
]

export const articleBitePurposeOptions = [
  {
    name: 'Read',
    code: 'Read'
  }
]

export const externalDocumentBitePurposeOptions = [
  {
    name: 'Buddy Bite',
    code: 'Buddy Bite'
  },
  {
    name: 'Collaborate',
    code: 'Collaborate'
  },
  {
    name: 'Mini Challenge',
    code: 'Mini Challenge'
  },
  {
    name: 'Prepare',
    code: 'Prepare'
  },
  {
    name: 'Read',
    code: 'Read'
  },
  {
    name: 'Watch',
    code: 'Watch'
  },
  {
    name: 'Questionnaire',
    code: 'Questionnaire'
  }
]

export const surveyBitePurposeOptions = [
  {
    name: 'Goal',
    code: 'Goal'
  },
  {
    name: 'Questionnaire',
    code: 'Questionnaire'
  },
  {
    name: 'Survey',
    code: 'Survey'
  }
]

export const videoBitePurposeOptions = [
  {
    name: 'Watch',
    code: 'Watch'
  }
]

export const textBitePurposeOptions = [
  {
    name: 'Read',
    code: 'Read'
  }
]

export const vcoachBitePurposeOptions = [
  {
    name: 'AI Assistant',
    code: 'AI Assistant'
  }
]

export const intentionSettingBitePurposeOptions = [
  {
    name: 'Chat Assistant',
    code: 'Chat Assistant'
  }
]
