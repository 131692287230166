import Vue from 'vue'
import Vuex from 'vuex'

import programsModule, { PROGRAMS_NAMESPACE } from '@/vuex/modules/programs/programs.state'
import bitesModule from './modules/bites/bites'
import classroomNodule from './modules/classroom/classroom'
import communicationModule from './modules/comm/communication.store'
import modulesModule from './modules/modules/modules'
import wavesModule from './modules/waves/waves'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  modules: {
    comm: communicationModule,
    [PROGRAMS_NAMESPACE]: programsModule,
    bites: {
      namespaced: true,
      state: bitesModule.state,
      getters: bitesModule.getters,
      actions: bitesModule.actions,
      mutations: bitesModule.mutations
    },
    modules: {
      namespaced: true,
      state: modulesModule.state,
      getters: modulesModule.getters,
      actions: modulesModule.actions,
      mutations: modulesModule.mutations
    },
    classroom: {
      namespaced: true,
      state: classroomNodule.state,
      getters: classroomNodule.getters,
      actions: classroomNodule.actions,
      mutations: classroomNodule.mutations
    },
    waves: {
      namespaced: true,
      state: wavesModule.state,
      getters: wavesModule.getters,
      actions: wavesModule.actions,
      mutations: wavesModule.mutations
    }
  }
})
