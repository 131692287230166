<template>
  <div class="px-5">
    <ConfirmationContent :WizardStep='WizardSteps.SETUP' :stepTitle='"Setup"'>
      <div class="confirmation-info">
        <ConfirmationFields label="Template Name" :value="$store.state.comm.selectedTemplate.name"/>
        <ConfirmationFields label="Subject Line" :value="$store.state.comm.data.subjectLine"/>
        <ConfirmationFields label="Preview Text" :value="$store.state.comm.data.previewText"/>
        <ConfirmationFields label="Sender Email" :value="$store.state.comm.data.senderEmail"/>
        <ConfirmationFields label="Sender Name" :value="$store.state.comm.data.fromName"/>
      </div>
    </ConfirmationContent>
    <ConfirmationContent :WizardStep='WizardSteps.CUSTOMIZATION' :stepTitle='"Customization"'>
      <div class="confirmation-no-variables" v-if="!customVariables.length&&!attachments">
        <p class="confirmation-no-variables_info">There are no custom variables or attachments</p>
      </div>
      <div class="confirmation-info" v-else>
        <div v-for="variable in customVariables" :key="variable.path">
          <ConfirmationFields :label="camelToSentence(variable.path)" :value="variable.value"/>
          <ConfirmationFields v-if="attachments" label="Attached file" :value="attachments"/>
        </div>
      </div>
    </ConfirmationContent>
    <ConfirmationContent :WizardStep='WizardSteps.RECIPIENTS' :stepTitle='"Recipients"'>
      <div class="p-0 overflow-auto">
        <b-table
          class="mb-0"
          thead-class="bg-white text-dark"
          :items="$store.state.comm.currentRecipientsList"
          :fields="fields">
        </b-table>
      </div>
    </ConfirmationContent>
    <ConfirmationContent :WizardStep='WizardSteps.SCHEDULING' :stepTitle='"Scheduling"'>
      <div class="confirmation-info">
        <ConfirmationFields
          :class="{confirmation_send_date:immediateScheduleType}"
          label="Scheduled for"
          :value="sendDateTime"/>
        <ConfirmationFields
          v-if="$store.state.comm.classroomStart"
          label="First Classroom Date"
          :value="$store.state.comm.classroomStart"/>
       </div>
    </ConfirmationContent>
  </div>
</template>

<script>
import ConfirmationContent from './ConfirmationContent'
import ConfirmationFields from './ConfirmationFields.vue'
import { WizardSteps } from '@/vuex/modules/comm/state'
export default {
  name: 'ConfirmationContainer',
  components: { ConfirmationContent, ConfirmationFields },
  data () {
    return {
      WizardSteps,
      fields: [
        {
          key: 'firstName',
          label: 'First Name'
        },
        {
          key: 'lastName',
          label: 'Last Name'
        },
        {
          key: 'email',
          label: 'Email'
        },
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'role',
          label: 'Role'
        }
      ]
    }
  },
  computed: {
    immediateScheduleType () {
      return this.$store.state.comm.data.scheduleType === 'IMMEDIATE'
    },
    sendDateTime () {
      return this.immediateScheduleType ? 'Will be sent immediately' : this.$store.state.comm.data.sendDateTime
    },
    customVariables () {
      return this.$store.state.comm.data.customVariables
    },
    attachments () {
      return this.$store.state.comm.data.attachments
    }
  },
  methods: {
    camelToSentence (text) {
      return text.replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase())
    }
  }
}
</script>

<style scoped>
.confirmation-info {
  padding: 2rem 1.5rem;
}
.confirmation_send_date >>> .confirmation-info_Field-right {
  color: red;
}
.confirmation-no-variables {
  text-align: center;
  padding: 2rem;
}
.confirmation-no-variables_info {
  font-size: 25px;
  margin: 0;
}
.table >>> th {
  color: #637080;
}
.table >>> th, .table >>> td {
  padding: 1rem 1.5rem;
  min-width: 126px;
}
</style>
