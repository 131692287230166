<template>
<div class="mb-5">
    <b-card header-tag="header">
      <template #header>
          <div class="d-flex flex-row align-items-center">
              <h3 class="confirmation_step-header m-0 ml-3">{{stepTitle}}</h3>
          </div>
      </template>
      <slot/>
    </b-card>
</div>
</template>

<script>

export default {
  name: 'SentViewSection',
  props: [ 'stepTitle' ],
  components: { }
}
</script>

<style scoped>
header {
    background: #f0f0f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.confirmation_step-header {
    color: #637080;
    font-size: 1.5rem;
    font-weight: bold;
}
</style>
