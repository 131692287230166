import { parse } from '@handlebars/parser'
import { backendTemplateVariables } from '@/views/communication/templateVariables'

export const parseOutPathsFromTemplate = (template) => {
  try {
    const variables = []
    parse(template).body.forEach(block => {
      if (
        block.type === 'MustacheStatement' &&
        block.path &&
        block.path.parts &&
        block.path.parts.length
      ) {
        variables.push(block.path.parts.join('.'))
      }
    })

    return variables.filter((val, index, self) => self.indexOf(val) === index)
  } catch (e) {
    return []
  }
}

export const extractCustomVariablesFromTemplate = (template) => {
  return parseOutPathsFromTemplate(template)
    .map(path => ({ path, value: '', imported: true }))
    .filter(tempVar => !backendTemplateVariables.includes(tempVar.path))
}

export const mergeCustomVariables = (templateVars, stateVars) => {
  const merged = []
  templateVars.forEach(variable => {
    let varValue = variable.value
    if (stateVars.filter(v => v.path === variable.path).length) {
      const stateVar = stateVars.filter(v => v.path === variable.path)[0]
      if (stateVar.value.trim() !== '') {
        varValue = stateVar.value
      }
    }
    merged.push({ ...variable, value: varValue })
  })

  stateVars.forEach(variable => {
    if (!merged.filter(v => v.path === variable.path).length) {
      merged.push({ ...variable, imported: false })
    }
  })

  return merged
}
