import { assocPath, hasPath, is, map, path, pipe, trim } from 'ramda'

const isFunction = is(Function)
const isObject = is(Object)
const isString = is(String)
const isDate = is(Date)

const replaceDuplicateWithOne = string => {
  const regExp = new RegExp(`${string}{2,}`, 'g')

  return value => value.replace(regExp, string)
}

export const trimWhitespace = pipe(trim, replaceDuplicateWithOne(' '), replaceDuplicateWithOne('\n\n'))

export function trimObjectWhitespace (value) {
  if (isString(value)) {
    return trimWhitespace(value)
  }

  if (Array.isArray(value)) {
    return value.map(trimObjectWhitespace)
  }

  if (isDate(value)) {
    return value
  }

  if (isObject(value)) {
    return map(trimObjectWhitespace, value)
  }

  return value
}

export const format = transformers => object =>
  transformers.reduce((acc, transformer) => {
    if (isFunction(transformer)) {
      return transformer(acc)
    }

    const [ props, transformFn ] = transformer

    if (!hasPath(props, acc)) {
      return acc
    }

    return assocPath(props, transformFn(path(props, acc)), acc)
  }, object)

export const toCamelCase = s => {
  return s.replace(/([-_][a-z])/gi, $1 => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

export const objectKeysToCamel = obj => {
  if (isObject(obj)) {
    Object.keys(obj).forEach(k => {
      obj[toCamelCase(k)] = objectKeysToCamel(obj[k])
    })
  }

  return obj
}
