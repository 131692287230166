import lepayaApi from '@/vuex/modules/classroom/api'

const state = {
  loading: false,
  showZoomStartLinkModal: false,
  classroom: {},
  zoomStartUrl: null,
  zoomStartUrlError: null
}

const getters = {
  classroom: (state) => state.classroom,
  showZoomStartLinkModal: (state) => state.showZoomStartLinkModal,
  loading: (state) => state.loading,
  zoomStartUrl: (state) => state.zoomStartUrl,
  zoomStartUrlError: (state) => state.zoomStartUrlError
}

const actions = {
  getZoomMeetingStartUrl ({ commit, getters }, data) {
    commit('setLoading', true)
    commit('setZoomStartUrlError', null)
    const classroomId = getters.classroom.id
    lepayaApi.fetchZoomMeetingStartUrl(data.journeyId, data.companyCode, classroomId)
      .then(response => {
        commit('setZoomStartUrl', response.data.startUrl)
      })
      .catch(({ response: { data: error } }) => {
        commit('setZoomStartUrlError', error.message)
      })
      .finally(() => {
        commit('setLoading', false)
      })
  }
}

const mutations = {
  setLoading: (state, value) => { state.loading = value },
  setShowZoomStartLinkModal: (state, value) => { state.showZoomStartLinkModal = value },
  setClassroom: (state, data) => { state.classroom = data },
  setZoomStartUrl: (state, value) => { state.zoomStartUrl = value },
  setZoomStartUrlError: (state, value) => { state.zoomStartUrlError = value }
}

export default {
  state,
  getters,
  actions,
  mutations
}
