import { CommunicationStatus, ScheduleType, ViewMode, WizardSteps } from '@/vuex/modules/comm/state'
import M from '@/vuex/modules/comm/mutations'
import { extractCustomVariablesFromTemplate, mergeCustomVariables } from '@/lib/mandrill'
import { isProdEnv } from '@/http/lepaya'
import processCommunicationTypes, {
  COMM_SENDER_EMAIL,
  COMM_TYPE_TRAINER_PREPARATION,
  communicationTypeMap
} from '@/views/communication/communicationTypeSelection/communicationConfig'

const CommActions = Object.freeze({
  Open: 'comm/Open',
  Edit: 'comm/Edit',
  View: 'comm/View',
  Close: 'comm/Close',
  Next: 'comm/Next',
  ToStep: 'comm/ToStep',
  Remove: 'comm/Remove',
  CloseRemove: 'comm/CloseRemove',
  ConfirmRemove: 'comm/ConfirmRemove',
  SetWaveLanguage: 'comm/SetWaveLanguage',
  SetInviteSchedulingDays: 'comm/SetInviteSchedulingDays',
  SelectTemplate: 'comm/SelectTemplate',
  ReimportTemplate: 'comm/ReimportTemplate',
  FetchEmailTemplates: 'comm/FetchEmailTemplates',
  GetAvailableTemplates: 'comm/GetAvailableTemplates',
  SetSubjectLine: 'comm/SetSubjectLine',
  SetPreviewText: 'comm/SetPreviewText',
  SetSenderEmail: 'comm/SetSenderEmail',
  SetFromName: 'comm/SetFromName',
  SetScheduleType: 'comm/SetScheduleType',
  SetSendDateTime: 'comm/SetSendDateTime',
  SetStatus: 'comm/SetStatus',
  SetSendDateTimezone: 'comm/SetSendDateTimezone',
  GetCommunicationsList: 'comm/GetCommunicationsList',
  AddCustomVariable: 'comm/AddCustomVariable',
  UpdateCustomVariable: 'comm/UpdateCustomVariable',
  RemoveCustomVariable: 'comm/RemoveCustomVariable',
  AppendCustomVariable: 'comm/AppendCustomVariable',
  SendTestEmail: 'comm/SendTestEmail',
  SendImmediateEmail: 'comm/SendImmediateEmail',
  GeneratePreviewEmailData: 'comm/GeneratePreviewEmailData',
  SetTemplateData: 'comm/SetTemplateData',
  SendEmailToNewParticipants: 'comm/SendEmailToNewParticipants',
  OpenCommunicationTypeModal: 'comm/OpenCommunicationTypeModal',
  SetSelectedModuleForCustomization: 'comm/SetSelectedModuleForCustomization',
  SetNewTemplateFilter: 'comm/SetNewTemplateFilter',
  GetModuleParticipants: 'comm/GetModuleParticipants',
  SetCommunicationType: 'comm/SetCommunicationType',
  GetAutoCommunications: 'comm/GetAutoCommunications'
})

// Actions stripped of their namespace for use internally within the namespace module
export const privateCommActions = Object.entries(CommActions)
  .reduce((agg, pair) => {
    agg[pair[0]] = pair[1].replace('comm/', '')
    return agg
  }, {})

const A = privateCommActions

export const generateActions = (api) => ({
  async [A.Open] (ctx, { companyCode, id }) {
    ctx.commit(M.setLoading, true)
    ctx.commit(M.openModal)
    let templates = await api.getAvailableTemplates()
    const lang = await api.getWaveLanguage(companyCode, id)
    ctx.commit(M.setDataField, { field: 'waveLanguage', value: lang })
    ctx.dispatch(A.SetNewTemplateFilter, lang.code)
    templates = templates.filter(t => t.labels.includes(lang.code) && !t.labels.includes('EoP'))
    const defaultTemplate = templates.find((template) => {
      if (!template.labels) return false
      return template.labels.some(label => label === ctx.state.defaultEmailTemplate)
    })
    const modules = await api.getWaveModules(companyCode, id)
    ctx.commit(M.setWaveModules, modules)
    const classroomStart = await api.getClassroomStartDate(companyCode, id)
    const participants = await api.getListOfParticipants(companyCode, id)
    let managers = await api.getListOfManagers(companyCode, id)
    managers = managers.data.map(item => ({ ...item, role: 'manager' }))
    const participantsAndManagers = [ ...participants.data, ...managers ]
    ctx.commit(M.setCompanyCodeAndProgramId, { companyCode, programId: id })
    if (defaultTemplate) ctx.dispatch(A.SelectTemplate, defaultTemplate)
    ctx.commit(M.setParticipants, participantsAndManagers)
    ctx.commit(M.setClassroomStart, classroomStart)
    ctx.commit(M.setAvailableTemplates, templates)
    ctx.commit(M.setCurrentRecipientsList)
    ctx.commit(M.setLoading, false)
  },
  [A.Close] (ctx) {
    ctx.commit(M.resetData)
    ctx.commit(M.closeModal)
    ctx.commit(M.resetCommunicationTypeParameters)
  },
  [A.Remove] (ctx, id) { ctx.commit(M.openRemoveModal, id) },
  [A.CloseRemove] (ctx) { ctx.commit(M.closeRemoveModal) },
  async [A.ConfirmRemove] (ctx, { companyCode, waveId, emailId }) {
    try {
      ctx.commit(M.removeMessage, null)
      ctx.commit(M.removeError, null)
      ctx.commit(M.removeLoading, true)

      await api.removeCommunication(companyCode, waveId, emailId)

      ctx.dispatch(A.GetCommunicationsList, { companyCode, id: waveId })
      ctx.commit(M.closeRemoveModal)
      ctx.commit(M.removeMessage, 'Email deleted successfuly')
    } catch (err) {
      ctx.commit(M.removeError, 'Could not remove communication')
    } finally {
      ctx.commit(M.removeLoading, false)
    }
  },
  async [A.Next] (ctx) {
    const id = await api.persistCommunication(ctx.state.companyCode, ctx.state.programId, ctx.getters.getCommunicationPersistanceRecord)
    if (ctx.state.data.id === null) {
      ctx.commit(M.setDataField, { field: 'id', value: id })
    }
    ctx.dispatch(A.GetCommunicationsList, { companyCode: ctx.state.companyCode, id: ctx.state.programId })
    ctx.commit(M.nextStep)
  },
  async [A.ToStep] (ctx, step) {
    if (step <= ctx.getters.lastAvailableStep) {
      const id = await api.persistCommunication(ctx.state.companyCode, ctx.state.programId, ctx.getters.getCommunicationPersistanceRecord)
      if (ctx.state.data.id === null) {
        ctx.commit(M.setDataField, { field: 'id', value: id })
      }
      ctx.dispatch(A.GetCommunicationsList, { companyCode: ctx.state.companyCode, id: ctx.state.programId })
      ctx.commit(M.setStep, step)
    }
  },
  [A.SetWaveLanguage] (ctx, lang) { ctx.commit(M.setDataField, { field: 'waveLanguage', value: lang }) },
  [A.SetInviteSchedulingDays] (ctx, days) { ctx.commit(M.setDataField, { field: 'inviteSchedulingDays', value: days }) },
  [A.SetSubjectLine] (ctx, line) { ctx.commit(M.setDataField, { field: 'subjectLine', value: line }) },
  [A.SetPreviewText] (ctx, text) { ctx.commit(M.setDataField, { field: 'previewText', value: text }) },
  [A.SetSenderEmail] (ctx, mail) { ctx.commit(M.setDataField, { field: 'senderEmail', value: mail }) },
  [A.SetFromName] (ctx, fromName) { ctx.commit(M.setDataField, { field: 'fromName', value: fromName }) },
  [A.SetScheduleType] (ctx, scheduleType) {
    ctx.commit(M.setDataField, { field: 'scheduleType', value: scheduleType })
    if (scheduleType === ScheduleType.IMMEDIATE) {
      ctx.commit(M.setDataField, { field: 'sendDateTime', value: null })
      ctx.dispatch(A.SetStatus, CommunicationStatus.DRAFT)
    }
  },
  [A.SetStatus] (ctx, status) {
    ctx.commit(M.setDataField, { field: 'status', value: status })
  },
  [A.SetSendDateTime] (ctx, dateTime) {
    ctx.commit(M.setDataField, { field: 'sendDateTime', value: dateTime })
  },
  [A.SetSendDateTimezone] (ctx, timezone) {
    ctx.commit(M.setSelectedTimezone, timezone)
    ctx.commit(M.setDataField, { field: 'sendDateTimezone', value: timezone })
  },

  async [A.GetAvailableTemplates] (ctx, { id, companyCode }) {
    let templates = await api.getAvailableTemplates()
    const lang = await api.getWaveLanguage(companyCode, id)
    templates = templates.filter(t => t.labels.includes(lang.code) && !t.labels.includes('EoP'))
    const defaultTemplate = templates.find((template) => {
      if (!template.labels) return false
      return template.labels.some(label => label === ctx.state.defaultEmailTemplate)
    })
    ctx.commit(M.setAvailableTemplates, templates)
    if (defaultTemplate) ctx.dispatch(A.SelectTemplate, defaultTemplate)
  },

  async [A.SelectTemplate] (ctx, template) {
    ctx.commit(M.setLoading, true)
    ctx.commit(M.setSelectedTemplate, template)
    ctx.commit(M.setDataField, { field: 'templateSlug', value: template.slug })
    try {
      const templateDetails = await api.getTemplateDetails(template.slug)
      const templateVars = extractCustomVariablesFromTemplate(templateDetails.code)
      let customVariables = mergeCustomVariables(templateVars, ctx.state.data.customVariables)
      // Do not display 'webAppUrlWithUserEmail' as it gets always replaced on the BE
      customVariables = customVariables.filter(v => ![ 'webAppUrlWithUserEmail' ].includes(v.path))
      ctx.commit(M.setDataField, { field: 'customVariables', value: customVariables })
      if (!ctx.state.data.subjectLine || ctx.state.data.subjectLine !== templateDetails.subject) {
        ctx.commit(M.setDataField, { field: 'subjectLine', value: templateDetails.subject })
      }
      if (!ctx.state.data.fromName || ctx.state.data.fromName !== templateDetails.fromName) {
        ctx.commit(M.setDataField, { field: 'fromName', value: templateDetails.fromName })
      }
      if (!ctx.state.data.senderEmail || ctx.state.data.senderEmail !== templateDetails.senderEmail) {
        ctx.commit(M.setDataField, { field: 'senderEmail', value: isProdEnv ? templateDetails.fromEmail : COMM_SENDER_EMAIL })
      }
      ctx.commit(M.setDataField, { field: 'templateData', value: templateDetails.code })
      ctx.commit(M.setDataField, { field: 'templateImportTime', value: new Date() })
    } finally {
      ctx.commit(M.setLoading, false)
    }
  },
  async [A.ReimportTemplate] (ctx) {
    if (ctx.state.selectedTemplate) {
      ctx.dispatch(A.SelectTemplate, ctx.state.selectedTemplate)
    }
  },

  async [A.FetchEmailTemplates] (ctx) {
    ctx.commit(M.setLoading, true)
    try {
      await api.fetchMailchimpTemplates()
      ctx.dispatch(A.GetAvailableTemplates, { companyCode: ctx.state.companyCode, id: ctx.state.programId })
    } finally {
      ctx.commit(M.setLoading, false)
    }
  },

  async [A.GetCommunicationsList] (ctx, { companyCode, id }) {
    try {
      const commList = await api.getCommunicationsList(companyCode, id)
      ctx.commit(M.setCommunicationsList, commList)
    } catch (e) {
      ctx.commit(M.setCommunicationsList, [])
    }
  },
  async [A.GetAutoCommunications] (ctx, { companyCode, id }) {
    try {
      const autoComm = await api.getAutoCommunications(companyCode, id)
      ctx.commit(M.setAutoCommunications, autoComm)
    } catch (e) {
      ctx.commit(M.setAutoCommunications, [])
    }
  },

  async [A.Edit] (ctx, { companyCode, id, communicationId }) {
    try {
      ctx.commit(M.resetData)
      ctx.commit(M.setStep, WizardSteps.LOADING)
      ctx.commit(M.openModal)
      ctx.commit(M.setCompanyCodeAndProgramId, { companyCode, programId: id })

      const templates = await api.getAvailableTemplates()
      const lang = await api.getWaveLanguage(companyCode, id)
      ctx.commit(M.setDataField, { field: 'waveLanguage', value: lang })
      ctx.dispatch(A.SetNewTemplateFilter, lang.code)
      const communication = await api.getCommunicationDetails(companyCode, id, communicationId)
      const modules = await api.getWaveModules(companyCode, id)
      ctx.commit(M.setWaveModules, modules)
      const details = communication.communicationDetails
      const classroomStart = await api.getClassroomStartDate(companyCode, id)
      const participants = await api.getListOfParticipants(companyCode, id)
      let managers = await api.getListOfManagers(companyCode, id)
      managers = managers.data.map(item => ({ ...item, role: 'manager' }))
      const participantsAndManagers = [ ...participants.data, ...managers ]

      ctx.commit(M.setClassroomStart, classroomStart)
      ctx.commit(M.setAvailableTemplates, templates)
      ctx.commit(M.setSelectedTemplate, details.selectedTemplate)
      ctx.commit(M.setParticipants, participantsAndManagers)
      ctx.commit(M.setDataField, { field: 'id', value: communication.id })
      ctx.commit(M.setDataField, { field: 'status', value: details.status || CommunicationStatus.DRAFT })
      ctx.commit(M.setDataField, { field: 'waveLanguage', value: lang })
      ctx.commit(M.setDataField, { field: 'templateSlug', value: details.templateSlug })
      ctx.commit(M.setDataField, { field: 'templateData', value: details.templateData })
      ctx.commit(M.setDataField, { field: 'templateImportTime', value: details.templateImportTime })
      ctx.commit(M.setDataField, { field: 'subjectLine', value: details.subjectLine })
      ctx.commit(M.setDataField, { field: 'previewText', value: details.previewText })
      ctx.commit(M.setDataField, { field: 'senderEmail', value: details.senderEmail })
      ctx.commit(M.setDataField, { field: 'fromName', value: details.fromName })
      ctx.commit(M.setDataField, { field: 'customVariables', value: details.customVariables })
      ctx.commit(M.setDataField, { field: 'recipients', value: details.recipients })
      ctx.commit(M.setDataField, { field: 'scheduleType', value: details.scheduleType })
      ctx.commit(M.setDataField, { field: 'sendDateTime', value: details.sendDateTime })
      ctx.commit(M.setDataField, { field: 'sendDateTimezone', value: details.sendDateTimezone })
      ctx.commit(M.setDataField, { field: 'lastStepCompleted', value: details.lastStepCompleted })
      ctx.commit(M.setCommunicationType, details.communicationType)
      const availableCommTypes = await processCommunicationTypes()
      const communicationTypeParameters = availableCommTypes.find(types => { return types.id === communicationTypeMap[details.communicationType] })
      ctx.commit(M.setCommunicationTypeParameters, communicationTypeParameters)
      ctx.commit(M.setSelectedModuleForCustomization, details.selectedModule)
      ctx.commit(M.setCurrentRecipientsList)
      ctx.commit(M.setStep, communication.step || WizardSteps.SETUP)
      ctx.commit(M.setLoading, false)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  },

  async [A.View] (ctx, { companyCode, id, communicationId }) {
    try {
      ctx.commit(M.resetData)
      ctx.commit(M.setViewMode, ViewMode.VIEW)
      ctx.commit(M.openModal)
      ctx.commit(M.setCompanyCodeAndProgramId, { companyCode, programId: id })
      const viewDetails = await api.getSentCommunicationViewDetails(companyCode, id, communicationId)
      ctx.commit(M.setViewData, viewDetails)
      ctx.commit(M.setLoading, false)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  },
  [A.AddCustomVariable] (ctx) {
    const updatedCustomVariables = [ ...ctx.state.data.customVariables, { path: `NewVariableNo${ctx.state.data.customVariables.filter(v => !v.imported).length + 1}`, value: '' } ]
    ctx.commit(M.setDataField, { field: 'customVariables', value: updatedCustomVariables })
  },
  [A.AppendCustomVariable] (ctx, { variable }) {
    const updatedCustomVariables = [ ...ctx.state.data.customVariables, variable ]
    ctx.commit(M.setDataField, { field: 'customVariables', value: updatedCustomVariables })
  },

  [A.UpdateCustomVariable] (ctx, { index, variable }) {
    const updatedVariables = [ ...ctx.state.data.customVariables ]
    updatedVariables[index] = variable
    // If we change the path of the variable to something already existing, modify path by appending $2, $3, $4...
    // @todo: Check if we'll have these types of situation as it introduces complexity that might not be needed
    const matchExisting = (v, ind) => v.path.split('$')[0] === variable.path && ind !== index
    if (ctx.state.data.customVariables.filter(matchExisting).length > 0) {
      const namesakeCount = ctx.state.data.customVariables.filter(matchExisting).length
      variable.path = variable.path + `$${namesakeCount + 1}`
    }

    ctx.commit(M.setDataField, { field: 'customVariables', value: updatedVariables })
  },

  [A.RemoveCustomVariable] (ctx, { index, variable }) {
    const updatedVariables = ctx.state.data.customVariables.slice()
    updatedVariables.splice(index, 1)
    ctx.commit(M.setDataField, { field: 'customVariables', value: updatedVariables })
  },

  async [A.SendTestEmail] (ctx) {
    ctx.commit(M.setLoading, true)
    const companyCode = ctx.state.companyCode
    const programId = ctx.state.programId
    const communicationId = ctx.state.data.id

    const apiResponse = await api.sendTestEmail(companyCode, programId, communicationId)
    ctx.commit(M.setMessageSentNotification, 'Test email sent successfully!')
    setTimeout(() => ctx.commit(M.setMessageSentNotification, null), 3000)
    ctx.commit(M.setLoading, false)

    return apiResponse
  },
  async [A.SendImmediateEmail] (ctx) {
    ctx.commit(M.setLoading, true)
    const companyCode = ctx.state.companyCode
    const programId = ctx.state.programId
    const communicationId = ctx.state.data.id

    const apiResponse = await api.sendImmediateEmail(companyCode, programId, communicationId)
    ctx.dispatch(A.GetCommunicationsList, { companyCode: ctx.state.companyCode, id: ctx.state.programId })
    ctx.commit(M.setMessageSentNotification, 'Emails sent successfully!')
    setTimeout(() => ctx.commit(M.setMessageSentNotification, null), 5000)
    ctx.commit(M.setLoading, false)

    return apiResponse
  },
  [A.SetTemplateData] (ctx, newTemplateData) {
    ctx.commit(M.setDataField, { field: 'templateData', value: newTemplateData })
  },
  async [A.GeneratePreviewEmailData] (ctx) {
    const companyCode = ctx.state.companyCode
    const programId = ctx.state.programId
    const communicationId = ctx.state.data.id
    ctx.commit(M.setLoading, true)
    const apiResponse = await api.generateEmailPreviewData(companyCode, programId, communicationId)
    ctx.commit(M.setPreviewEmailData, apiResponse)
    ctx.commit(M.setLoading, false)
  },
  async [A.SendEmailToNewParticipants] (ctx, { companyCode, id, communicationId, newRecipientsIds }) {
    ctx.commit(M.setLoading, true)
    await api.sendEmailToNewAddedParticipants(companyCode, id, communicationId, { newRecipientsIds })
    ctx.commit(M.setMessageSentNotification, 'Emails sent successfully!')
    setTimeout(() => ctx.commit(M.setMessageSentNotification, null), 5000)
    ctx.commit(M.setLoading, false)
  },
  [A.OpenCommunicationTypeModal] (ctx) {
    ctx.commit(M.setShowCommunicationTypeModal, true)
  },
  [A.SetSelectedModuleForCustomization] (ctx, selectedModule) {
    ctx.commit(M.setSelectedModuleForCustomization, selectedModule)
  },
  [A.SetNewTemplateFilter] (ctx, filter) {
    ctx.commit(M.setNewTemplateFilter, filter)
  },
  async [A.GetModuleParticipants] (ctx, { companyCode, communicationType, moduleId }) {
    if (moduleId && communicationType === COMM_TYPE_TRAINER_PREPARATION) {
      ctx.commit(M.setLoading, true)
      const moduleParticipants = await api.getListOfJourneyParticipants(companyCode, moduleId)
      ctx.commit(M.setParticipants, moduleParticipants.data)
      ctx.commit(M.setCurrentRecipientsList)
      ctx.commit(M.setLoading, false)
    }
  },
  [A.SetCommunicationType] (ctx, type) {
    ctx.commit(M.setCommunicationType, type)
  }
})

export default CommActions
