<template>
  <div class="la-modal-wrapper">
    <div v-on:click="onCancel($event)" class="la-modal-blackdrop"></div>
    <div class="la-modal bg-white" :class="classes">
      <h2 v-if="withHeader" :class="shadow ? 'title' : null" class="d-flex justify-content-between m-0">
        <slot name="header"></slot>
        <button :class="shadow ? 'border border-right-0' : null" type="button" class="py-2 ml-auto align-self-stretch px-5 close btn btn-link text-secondary" aria-label="Close" v-on:click="onCancel($event)">
          <span style="font-size: 2em" aria-hidden="true"><Icon icon="times"/></span>
        </button>
      </h2>
      <div class="la-modal-content">
        <slot></slot>
      </div>
      <div v-if="withFooter" class="shadow-lg p-0 bg-white rounded la-modal-footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from './Icon.vue'

export default {

  props: {
    modalClasses: {
      type: Array,
      default: () => []
    },
    onClose: {
      type: Function,
      default: function () {
        this.$router.back()
      }
    },
    onEscape: {
      type: Function,
      default: (originalFn, event) => originalFn(event)
    },
    shadow: {
      type: Boolean,
      default: true
    },
    title: String,
    fullscreen: {
      type: Boolean,
      default: () => false
    },
    withHeader: {
      type: Boolean,
      default: () => true
    },
    withFooter: {
      type: Boolean,
      default: () => false
    }
  },

  components: {
    Icon
  },

  computed: {
    classes () {
      return this.modalClasses.reduce((acc, curr) => ({ ...acc, [`la-modal-${curr}`]: true }), { 'la-modal-fullscreen': this.fullscreen })
    }
  },

  created () {
    this.onKeyDown = event => {
      const { keyCode } = event

      if (keyCode !== 27) return

      this.onEscape(this.onClose.bind(this, event), event)
    }

    window.addEventListener('keydown', this.onKeyDown)
  },

  destroyed () {
    window.removeEventListener('keydown', this.onKeyDown)
  },

  methods: {
    onCancel (event) {
      this.onClose(event)
    }
  }
}

</script>

<style>

  .title {
    box-shadow: 0 2px 5px 0 rgba(198, 198, 211, 0.4);
    height: 7rem;
  }

  .la-modal-blackdrop {
    z-index: 1040;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0px;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.4;
  }

  .la-modal.la-modal-small {
    width: 40%;
    left: 30%;
  }

  .la-modal.la-modal-delete {
    height: 32rem;
  }

  .la-modal.la-modal-medium {
    width: 50%;
    height: 80%;
    left: 25%;
  }

  .la-modal {
    z-index: 1050;
    position: fixed;
    top: 2.5%;
    left: 2.5%;
    padding: 0;
    background-color: white;
    width: 95%;
    max-height: 95%;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 20rem;
  }

  .la-modal-content {
    height: calc(100% - 13rem);
    overflow: auto;
  }

  .la-modal-fullscreen {
    height: 95%
  }

  .la-modal-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 6rem;
  }

</style>
