<template>
  <div class="text-center">
    <b-alert class="alert" :variant="variant" :fade="true" :show="show"> {{ message }} </b-alert>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    variant: {
      type: String,
      required: false,
      default: 'warning'
    },
    show: {
      type: Boolean,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.alert {
  width: 50%;
}
</style>
