import lepayaApi from './api.js'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { createEmptyBite } from '@/components/bites/supportedBiteForms'

const state = {
  bites: [],
  loading: false,
  isBiteCreation: false,
  showBiteTypeSelection: false,
  biteDraftType: null,
  editingBite: null,
  successUpdatedMessage: null,
  failedUpdateMessage: null,
  selectedBites: [],
  publishBitesResponse: [],
  successScheduleMessage: null,
  failedScheduleMessage: null,
  bucketizeError: null,
  bucketizeSuccessMessage: null,
  journeyIsPublished: false,
  lastPostBiteOrderNumber: null,
  biteNoLongerSupportedMessage: null
}

const getters = {
  loading: (state) => state.loading,
  bites: (state) => state.bites,
  isNewBite: (state) => !!state.isBiteCreation,
  showEditBiteModal: (state) => !!state.editingBite,
  showCreateBiteModal: (state) => !!state.showBiteTypeSelection,
  editingBite: (state) => state.editingBite,
  successUpdatedMessage: (state) => state.successUpdatedMessage,
  failedUpdateMessage: (state) => state.failedUpdateMessage,
  successScheduleMessage: (state) => state.successScheduleMessage,
  failedScheduleMessage: (state) => state.failedScheduleMessage,
  selectedBites: (state) => state.selectedBites,
  lastPostBiteOrderNumber: (state) => state.lastPostBiteOrderNumber,
  biteNoLongerSupportedMessage: (state) => state.biteNoLongerSupportedMessage,
  publishBitesResponse: (state) => {
    if (!state.publishBitesResponse.length) return { message: [], status: '' }

    const formattedResponse = []
    let responseStatus = null

    for (const response of state.publishBitesResponse) {
      formattedResponse.push(`Bite id: ${response.id} - ${response.message}`)
    }

    if (state.publishBitesResponse.every(response => response.success === false)) responseStatus = 'fail'
    if (state.publishBitesResponse.every(response => response.success === true)) responseStatus = 'success'
    if (!responseStatus) responseStatus = 'partialSuccess'

    return {
      message: formattedResponse,
      status: responseStatus
    }
  },
  showAutoScheduleButton: (state) => {
    let showButton = state.bites.length > 0
    for (const bite of state.bites) {
      const moduleAlreadyStarted = moment(bite.deadlineTime).isSameOrBefore(moment())
      // Check if those bites come from ContentStack
      if ((!bite.isOptional && bite.orderNumber !== 0 && !bite.orderNumber) ||
        // Check if the module is published and already started or the date is relative
        (moduleAlreadyStarted && state.journeyIsPublished) || bite.relativeDeadlineTime) {
        showButton = false
        break
      }
    }
    return showButton
  },
  bucketizeError: (state) => state.bucketizeError,
  showBucketizeButton: (state) => {
    if (!state.selectedBites.length) return false
    const selectedBites = cloneDeep(state.selectedBites)

    for (const selectedBite of selectedBites) {
      if (!selectedBite) return false
      const orderNumber = parseInt(selectedBite.title)
      if (isNaN(orderNumber)) return false
      if (selectedBite.isOptional) return false
      selectedBite.orderNumber = orderNumber
    }
    selectedBites.sort((a, b) => a.orderNumber - b.orderNumber)

    return Boolean(selectedBites.reduce((output, latest) => (output
      ? (Number(output.orderNumber) + 1 === Number(latest.orderNumber) ? latest : false) : false)))
  },
  bucketizeSuccessMessage: (state) => state.bucketizeSuccessMessage
}

const actions = {
  getBites ({ commit }, data) {
    commit('setLoading', true)

    return new Promise((resolve) => {
      lepayaApi.fetchBites(data.id, data.companyCode)
        .then(response => {
          commit('setBites', response.data)
          resolve(response.data)
        })
        .catch(error => {
        // eslint-disable-next-line no-console
          console.log('error', error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },

  autoScheduleBites ({ commit, dispatch }, data) {
    lepayaApi.autoSchedulingBites(data.companyCode, data.id)
      .then(async response => {
        await dispatch('getBites', data)
        commit('setSuccessScheduleMessage', 'Bites were successfully auto scheduled.')
      })
      .catch(({ response: { data: error } }) => {
        commit('setFailedScheduleMessage', error)
      })
      .finally(() => {
        setTimeout(() => { commit('setSuccessScheduleMessage', null) }, 2000)
        setTimeout(() => { commit('setFailedScheduleMessage', null) }, 5000)
      })
  },

  async createBite ({ commit, dispatch }, data) {
    commit('setLoading', true)
    return new Promise((resolve) => {
      lepayaApi.createBite(data)
        .then(async response => {
          await dispatch('getBites', data)
          commit('setSuccessUpdatedMessage', 'Bite successfully created.')
          resolve(true)
        })
        .catch(({ response: { data: error } }) => {
          commit('setFailedUpdateMessage', error)
        })
        .finally(() => {
          commit('setLoading', false)
          setTimeout(() => { commit('setSuccessUpdatedMessage', null) }, 2000)
          setTimeout(() => { commit('setFailedUpdateMessage', null) }, 5000)
        })
    })
  },

  async updateBite ({ commit, dispatch }, data) {
    commit('setLoading', true)

    return new Promise((resolve) => {
      lepayaApi.updateBite(data)
        .then(async response => {
          await dispatch('getBites', data)
          commit('setSuccessUpdatedMessage', 'Bite successfully updated.')
          resolve(true)
        })
        .catch(({ response: { data: error } }) => {
          commit('setFailedUpdateMessage', error)
        })
        .finally(() => {
          commit('setLoading', false)
          setTimeout(() => { commit('setSuccessUpdatedMessage', null) }, 2000)
          setTimeout(() => { commit('setFailedUpdateMessage', null) }, 5000)
        })
    })
  },

  publishSelectedBites ({ commit, dispatch, state }, data) {
    commit('setLoading', true)
    lepayaApi.publishBites(data.id, data.companyCode, state.selectedBites)
      .then(async response => {
        commit('setPublishArchiveBitesMessage', response.data)
        await dispatch('getBites', data)
      })
      .catch(error => {
        if (error.response.status === 400) commit('setPublishArchiveBitesMessage', error.response.data)
        // eslint-disable-next-line no-console
        console.log(error.message)
      })
      .finally(() => {
        setTimeout(() => { commit('setPublishArchiveBitesMessage', []) }, 2500)
        commit('setLoading', false)
      })
  },

  archiveBites ({ commit, dispatch, state }, data) {
    commit('setLoading', true)
    lepayaApi.archiveBites({ id: data.id, companyCode: data.companyCode, bites: state.selectedBites })
      .then(async response => {
        commit('setPublishArchiveBitesMessage', response.data)
        await dispatch('getBites', data)
      })
      .catch(error => {
        if (error.response.status === 400) commit('setPublishArchiveBitesMessage', error.response.data)
      })
      .finally(() => {
        setTimeout(() => { commit('setPublishArchiveBitesMessage', []) }, 2500)
        commit('setLoading', false)
      })
  },

  bucketizeModule ({ commit, state, dispatch }, data) {
    commit('setLoading', true)
    data.sessionBites = state.selectedBites
    lepayaApi.bucketizeModule(data)
      .then(async response => {
        commit('setBucketizeSuccessMessage', 'Module bites were successfully bucketized.')
        commit('setSelectedBites', [])
        await dispatch('getBites', data)
      })
      .catch(({ response: { data: error } }) => {
        commit('setBucketizeError', error)
      })
      .finally(() => {
        setTimeout(() => { commit('setBucketizeError', null) }, 2500)
        setTimeout(() => { commit('setBucketizeSuccessMessage', null) }, 2500)
        commit('setLoading', false)
      })
  },

  setJourneyIsPublished ({ commit }, data) {
    commit('setJourneyIsPublished', data)
  },

  selectBiteTypeForCreation ({ commit }, biteType) {
    commit('setBiteCreation', true)
    commit('showNewBiteTypeSelection', false)
    commit('setEditingBite', createEmptyBite(biteType))
  }
}

const mutations = {
  setBites: (state, data) => {
    state.bites = data
    const postBites = state.bites.filter(obj => obj.stage === 'post')
    const lastElementFromPostBites = postBites.sort((a, b) => a.orderNumber - b.orderNumber).pop()
    if (lastElementFromPostBites) mutations.setLastPostBiteOrderNumber(state, lastElementFromPostBites.orderNumber)
  },
  setLoading: (state, value) => { state.loading = value },
  setEditingBite: (state, data) => { state.editingBite = data },
  showNewBiteTypeSelection: (state, value) => { state.showBiteTypeSelection = !!value },
  setBiteCreation: (state, value) => { state.isBiteCreation = !!value },
  setSuccessUpdatedMessage: (state, value) => { state.successUpdatedMessage = value },
  setFailedUpdateMessage: (state, value) => { state.failedUpdateMessage = value },
  setSuccessScheduleMessage: (state, value) => { state.successScheduleMessage = value },
  setFailedScheduleMessage: (state, value) => { state.failedScheduleMessage = value },
  setSelectedBites: (state, data) => { state.selectedBites = data },
  setPublishArchiveBitesMessage: (state, data) => { state.publishBitesResponse = data },
  setBucketizeError: (state, value) => { state.bucketizeError = value },
  setBucketizeSuccessMessage: (state, value) => { state.bucketizeSuccessMessage = value },
  setJourneyIsPublished: (state, value) => { state.journeyIsPublished = value },
  setLastPostBiteOrderNumber: (state, value) => { state.lastPostBiteOrderNumber = value },
  setBiteNoLongerSupportedMessage: (state, value) => { state.biteNoLongerSupportedMessage = value }
}

export default {
  state,
  getters,
  actions,
  mutations
}
