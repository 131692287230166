import { toPairs } from 'ramda'
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArchive,
  faArrowLeft,
  faArrowRight,
  faBuilding,
  faChalkboardTeacher,
  faClock,
  faCoffee,
  faCog,
  faCopy,
  faCubes,
  faExclamationCircle,
  faEye,
  faFileAlt,
  faHourglassHalf,
  faInfoCircle,
  faPlus,
  faPlusCircle,
  faRocket,
  faThLarge,
  faTimes,
  faUserCog,
  faUserEdit,
  faUsers,
  faBriefcase,
  faAlignLeft,
  faCalendarAlt,
  faPen,
  faTrash,
  faTrashAlt,
  faUpload,
  faMapMarkerAlt,
  faCheck,
  faSort,
  faSearch,
  faMinus,
  faEllipsisH,
  faFileCsv,
  faPaperclip,
  faTimesCircle,
  faPaperPlane,
  faChevronRight,
  faEnvelope,
  faLocationArrow,
  faCheckCircle,
  faBold,
  faItalic,
  faUnderline,
  faStar,
  faLink,
  faSync,
  faVideo,
  faQuestionCircle,
  faFolderOpen,
  faMedkit,
  faCloudDownloadAlt
} from '@fortawesome/free-solid-svg-icons'

export const icons = toPairs({
  faQuestionCircle,
  faVideo,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArchive,
  faArrowLeft,
  faArrowRight,
  faBuilding,
  faChalkboardTeacher,
  faClock,
  faCoffee,
  faCog,
  faCopy,
  faCubes,
  faExclamationCircle,
  faEye,
  faFileAlt,
  faHourglassHalf,
  faInfoCircle,
  faPlus,
  faPlusCircle,
  faRocket,
  faThLarge,
  faTimes,
  faUserCog,
  faUserEdit,
  faUsers,
  faBriefcase,
  faAlignLeft,
  faCalendarAlt,
  faPen,
  faTrash,
  faTrashAlt,
  faUpload,
  faMapMarkerAlt,
  faCheck,
  faSort,
  faSearch,
  faMinus,
  faEllipsisH,
  faFileCsv,
  faPaperclip,
  faTimesCircle,
  faPaperPlane,
  faChevronRight,
  faEnvelope,
  faLocationArrow,
  faCheckCircle,
  faBold,
  faItalic,
  faUnderline,
  faStar,
  faLink,
  faSync,
  faFolderOpen,
  faMedkit,
  faCloudDownloadAlt
})
