<template>
  <b>
    {{text}}
    <slot>
    </slot>
  </b>
</template>

<script>
export default {
  props: {
    text: String
  }
}
</script>
