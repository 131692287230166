<template>
  <div class="participants-table">
    <div class="controls-bar"></div>
    <template v-if="lastFetched">
    <div v-if="tableData.length === 0" class="empty-table">No participants to show</div>
      <ve-table
          v-else
          :columns="columns"
          :table-data="tableData"
          row-key-field-name="id"
          :sort-option="sortOption"
          :cellSelectionOption="{ enable: false }"
      />
    </template>
    <template v-else>
      <div class="spinner"><b-spinner variant="primary"/></div>
    </template>
  </div>
</template>

<script>
import { ProgramsActions } from '@/vuex/modules/programs/actions'
import { ProgramsGetters } from '@/vuex/modules/programs/getters'
import { PROGRAMS_NAMESPACE } from '@/vuex/modules/programs/programs.state'
import { mapActions, mapGetters } from 'vuex'
import sortChangeMixin from './sortChangeMixin'

export default {
  name: 'ParticipantsTable',
  mixins: [ sortChangeMixin ],
  data () {
    return {
      lastFetched: null,
      tableData: [],
      sortOption: {
        sortChange: (params) => this.sortChange(params)
      },
      columns: [
        {
          field: 'waveName',
          key: 'a',
          title: 'Wave',
          align: 'left',
          sortBy: '',
          width: '10%'
        },
        {
          field: 'firstName',
          key: 'b',
          title: 'Name',
          align: 'left',
          sortBy: ''
        },
        { field: 'lastName', key: 'c', title: 'Surname', align: 'left', sortBy: '' },
        {
          field: 'email',
          key: 'd',
          title: 'Email',
          align: 'center',
          sortBy: ''
        },
        {
          field: 'id',
          key: 'e',
          title: 'Employee ID',
          align: 'center'
        },
        { field: 'role', key: 'f', title: 'Role', align: 'center', sortBy: '' }
      ]
    }
  },
  created () {
    const { companyCode } = this.$route.params
    const program = this.$props?.program

    if (this.lastFetched === null) {
      this.fetchProgramParticipants({ companyCode, program }).then(r => {
        this.tableData = [ ...this.getProgramParticipants(program?.id) ]
      }).catch(e => {
        this.tableData = null
      }).finally(() => {
        this.lastFetched = new Date()
      })
    } else {
      this.tableData = [ ...this.getProgramParticipants(program?.id) ]
    }
  },
  props: {
    participants: Array,
    program: Object
  },
  methods: {
    ...mapActions(PROGRAMS_NAMESPACE, {
      fetchProgramParticipants: ProgramsActions.fetchProgramParticipants
    })
  },
  computed: {
    ...mapGetters(PROGRAMS_NAMESPACE, {
      getProgramParticipants: ProgramsGetters.getProgramParticipants
    })
  }
}
</script>

<style lang="scss">

.participants-table > div > div >div >table > thead > tr > th {
    font-weight: 800 !important;
}
.participants-table > div > div >div >table > thead > tr > th > span.ve-table-sort{
    translate: 0 -25%;
}
.participants-table > div > div >div >table > thead > tr > th > label > span.ve-checkbox-content{
    translate: 0 25%;
}

.participants-table > div > div > div > table > tbody > tr.ve-table-body-tr > td {
  transition: background 1s;
}

.participants-table > div > div > div > table > tbody > tr.ve-table-body-tr > td.hot {
  background: lightseagreen !important;
  transition: background 1s;
}

</style>

<style scoped>

.participants-table {
  margin: 0 0 60px 0;
}

.controls-bar {
  display: flex;
  justify-content: flex-end;
  margin-right: 1em;
  padding: 5px;
  gap: 1rem;
}

.empty-table {
  display: flex;
  justify-content: center;
}

.dropdown {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
}

.dropdown-placer {
  position: relative;
  height: 1px;
}

.dropdown-menu {
  background: white;
  position: absolute;
  min-width: 100%;
  z-index: 9999;
  top: 0;
  right:0;
  margin-top: -1px;
}

.spinner {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 2rem;
}
</style>
