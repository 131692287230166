<template>
  <div class="form-check">
    <input
      v-bind:id="id"
      :disabled="disabled"
      v-model="value"
      class="form-check-input control"
      type="checkbox"
      @input="input"
    />
    <label v-bind:for="id" class="form-check-label">
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    disabled: {
      default: false,
      type: Boolean
    }
  },

  data: () => ({
    id: Math.random()
  }),

  methods: {
    input (event) {
      this.$emit('change', !this.value)
    }
  },

  watch: {
    value (value) {
      this.$emit('input', value)
    }
  }
}
</script>
