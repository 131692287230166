<template>
  <b-col class="col-md-4 col-lg-4 communication-item">
    <b-card
      align="center"
      :class="{ 'selected-item' : selected }"
      @click="typeSelected(data)"
    >
      <b-card-text><strong>{{ data.name }}</strong></b-card-text>
      <Icon class="mail-icon" icon="envelope" />
      <b-card-text>{{ data.description }}</b-card-text>
    </b-card>
  </b-col>
</template>

<script>
import Icon from '@/components/Icon.vue'

export default {
  name: 'CommunicationTypeItem',
  components: { Icon },
  props: {
    data: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    typeSelected (communicationType) {
      this.$emit('typeSelected', communicationType)
    }
  }
}
</script>

<style scoped>
.mail-icon {
  font-size: 150px;
  color: #d0d4d9;
}
.communication-item {
  padding-bottom: 20px;
}
.selected-item {
  border-style: solid;
  border-width: medium;
  border-color: #56d5ed;
}
</style>
